import React, { useRef, useEffect } from "react"
import { connect } from "react-redux"
import { useForm, useFormState } from "react-hook-form"
import { chakra, Input, Flex, Box, Tooltip, Text } from "@chakra-ui/react"
import { LabelTop } from "../../componentsWithChakra"
import SelectPerson from "../SelectPerson"
import SelectPersons from "../SelectPersons"

import { formSetupUpdate } from "../../redux/actions/project"
import objDeepTrim from "../../functions/utils/objDeepTrim"

const FormSetup = ({ project, isResetForm = false, dispatch }) => {
  //=================================================================
  const inputRef = useRef(null)

  const { register, control, reset, handleSubmit } = useForm({
    delayError: 500,
  })
  const { dirtyFields } = useFormState({ control })

  const { statusDateCurrent, prId } = project
  const { isStatusLocked } = project.statuses[statusDateCurrent].setup

  //-----------------------------------------------------------------
  const {
    title,
    objective,
    description,
    customer1,
    customer2,
    otherVIP,
    manager,
    controller,
    productOwner,
    agileMaster,
    delegateChange,
    delegateQuality,
    delegateRisk,
    delegateKnowledge,
    membersTeam,
    sponsor,
    membersStC,
    communicationTool,
    dataRepository,
  } = project.statuses[statusDateCurrent].setup

  //-----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset])

  //-----------------------------------------------------------------

  const onSubmit = (data) => {
    // moves to hidden button. Submit is because this causes an onBlur.
    inputRef.current.focus()
  }

  const onBlur = (data) => {
    objDeepTrim(data)
    const payload = { data }
    dispatch(formSetupUpdate(payload))
  }

  const onKeyDown = (e) => {
    const keyCode = e.keyCode
    // 13 represents the Enter key
    if (keyCode === 13 && !e.shiftKey) {
      // Don't generate a new line
      e.preventDefault()
      handleSubmit(onSubmit)()
    }
  }
  //=================================================================
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Flex wrap="wrap" mb={4}>
        <Flex w={["100%"]} pl={[0]} pr={[0]}>
          <Box w={["75%"]} pl={[0]} pr={[1]}>
            <Tooltip
              label="The title of the project should be such that people easily understand the purpose. You may want to add references to dates or areas."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Project Title</LabelTop>
            </Tooltip>
            <Input
              {...register("title")}
              defaultValue={title}
              onBlur={dirtyFields?.title ? handleSubmit(onBlur) : null}
              isDisabled={isStatusLocked}
            />
          </Box>
          <Box w={["25%"]} pl={[1]} pr={[0]}>
            <Tooltip
              label="The project ID is a unique identifier for the project."
              placement="top-end"
              hasArrow
            >
              <LabelTop>Project ID</LabelTop>
            </Tooltip>
            <Text color="grey.600">#{prId}</Text>
          </Box>
        </Flex>

        <Box w={["100%"]} pl={[0]} pr={[0]}>
          <Tooltip
            label="The Project Objective is the WHAT of the project. Every project should have a clear objective. Ideally the object is SMART: Specific, Measurable, Achievable, Relevant, Time-bound."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Project Objective</LabelTop>
          </Tooltip>
          <Input
            {...register("objective")}
            defaultValue={objective}
            onBlur={dirtyFields?.objective ? handleSubmit(onBlur) : null}
            isDisabled={isStatusLocked}
          />
        </Box>

        <Flex direction="column" w={["100%"]} pl={[0]} pr={[0]}>
          <Box>
            <Tooltip
              label="The Project Objective describes the WHAT of the project. The Project Description should explain the WHY of the project and give a little context to the project and its necessity."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Project Description</LabelTop>
            </Tooltip>
          </Box>

          <chakra.textarea
            {...register("description")}
            defaultValue={description}
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderColor="grey.400"
            borderRadius="0"
            fontSize="inherit"
            rows="3"
            onKeyDown={onKeyDown}
            bg="white"
            onBlur={dirtyFields?.description ? handleSubmit(onBlur) : null}
            disabled={isStatusLocked}
          />
        </Flex>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[0, 0, 0, 0]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="The Customer is the external or internal customer of the project who in the end will one way or another pay for the result of the project. If the customer is a set of to be targeted customers who are not yet known to the project this role can be given to a person who represents this set of customers."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Customer 1</LabelTop>
          </Tooltip>
          <SelectPerson
            wId={"_setup"}
            name="customer1"
            value={customer1}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="Customer 2 is accompanying Customer 1 or a colleague of Customer 1."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Customer 2</LabelTop>
          </Tooltip>
          <SelectPerson
            wId={"_setup"}
            name="customer2"
            value={customer2}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="An Other VIP is a person that is very important to the project but where there is either not a special role or when the role is already assigned to somebody else."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Other VIP</LabelTop>
          </Tooltip>
          <SelectPerson
            wId={"_setup"}
            name="otherVIP"
            value={otherVIP}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[0, 0, 0, 0]}
        >
          <Tooltip
            label="The Project Sponsor is usually the person in whose area the project responsibilities sits. The Project Owner has either initiated the project or it was given to this person. The budget and resources often mainly stem from this area and are »owned« by the Project Sponsor. Typically the Project Manager or Product Owner report to the Project Sponsor."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Project Sponsor</LabelTop>
          </Tooltip>
          <SelectPerson
            wId={"_setup"}
            name="sponsor"
            value={sponsor}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box direction="column" w={["100%"]} pl={[0]} pr={[0]}>
          <Tooltip
            label="The Project Steering Committee is typically comprised of more senior line managers that have a stake in the project either by profiting from the result of the project or by being impacted by the change incurred. The task of the Steering Committee is to remove hurdles that the project (manager) is facing, resolve (prioritization) conflicts, overcome constraint, supervise the general progress and initiate necessary actions."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Project Steering Committee Members</LabelTop>
          </Tooltip>
          <SelectPersons
            name="membersStC"
            value={membersStC}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[0, 0, 0, 0]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="The Project Manager/Owner is the responsible for delivering the project. (S)he does so by organizing the resources in the project and taking the necessary decisions. The project manager is usually a superior to a project team. In agile setups the role of a project manager does not exist. Nevertheless in agile context a person is needed who installs the Product Owner, Agile Master and the Core Team. This task is then performed by the Project Owner."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Project Manager/Owner</LabelTop>
          </Tooltip>
          <SelectPerson
            wId={"_setup"}
            name="manager"
            value={manager}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="The Product Owner is a role in agile setups. The product owner is responsible for the product. (S)he represents the product to the (internal) customer and within the development team prioritizes versions and features. The product owner is not a superior to a team member."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Product Owner</LabelTop>
          </Tooltip>
          <SelectPerson
            wId={"_setup"}
            name="productOwner"
            value={productOwner}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="The Project Controller is responsible for keeping track of the project status as independently and objectively as possible. (S)he should be the single source of truth for all status information. In agile projects this role does not exist even if it is equally important. If no extra roles is implemented this role should be assumed by the Agile (Scrum, Kanban) Master."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Project Controller</LabelTop>
          </Tooltip>
          <SelectPerson
            wId={"_setup"}
            name="controller"
            value={controller}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[0, 0, 0, 0]}
        >
          <Tooltip
            label="The Agile (Scrum, Kanban) Master is responsible for supporting the team in all aspects that make the team effort most efficient. This can be coaching of the team or individual members, conflict resolution, the organization of infrastructure, or support in any necessary area. If the role of the Project Controller has not been assigned the Agile Master carries out the project evaluation and status reporting on behalf of the team."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Agile Master</LabelTop>
          </Tooltip>

          <SelectPerson
            wId={"_setup"}
            name="agileMaster"
            value={agileMaster}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[0, 0, 0, 0]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label={
              <span>
                Larger projects usually have a significant impact in an
                organization and therefore bring several stakeholders. Also the
                project result may lead to significant changes in the
                organization itself. The <em>Delegate for Change Management</em>{" "}
                acts more on the context of the project by making sure that
                stakeholders are aligned with the project and that the
                organizational change that may be the consequence of the project
                is running as smoothly as possible. This is a role which can be
                assumed next to other roles.
              </span>
            }
            placement="top-start"
            hasArrow
            maxWidth="500px"
          >
            <LabelTop>Delegate Change Mgt</LabelTop>
          </Tooltip>
          <SelectPerson
            wId={"_setup"}
            name="delegateChange"
            value={delegateChange}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label={
              <span>
                Scope and Quality Management are special challenges in a
                project. The <em>Delegate for Quality Management</em> has to
                ensure that the scope of the project is not changing such that
                it puts the whole project in jeopardy. This is a role which can
                be assumed next to other roles. Scope creep is that more and
                more scope is added, whereas scope bleed is that scope is
                continuously reduced. Furthermore this role also need to ensure
                that the defined quality is delivered in every deliverable,
                quality assured and signed off. The quality assurance must not
                be done by the person him-/herself but this person makes sure
                that a working process is in place.
              </span>
            }
            placement="top-start"
            hasArrow
            maxWidth="500px"
          >
            <LabelTop>Delegate Quality Mgt</LabelTop>
          </Tooltip>
          <SelectPerson
            wId={"_setup"}
            name="delegateQuality"
            value={delegateQuality}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label={
              <span>
                The <em>Delegate for Risk Management</em> has to make sure that
                significant, tangible, and material issues that are
                realistically threatening the project success are attended to.
                Risk Management is not writing risks up but is trying to find
                solutions for before mentioned issues in order to reduce their
                likelihood or severity. This is a role which can be assumed next
                to other roles. The <em>Delegate for Risk Management</em> is
                typically more concerned with issues arising from the context of
                the project rather than from within the project which rather is
                the task of the project manager or the agile team.
              </span>
            }
            placement="top-start"
            hasArrow
            maxWidth="500px"
          >
            <LabelTop>Delegate Risk Mgt</LabelTop>
          </Tooltip>
          <SelectPerson
            wId={"_setup"}
            name="delegateRisk"
            value={delegateRisk}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[0, 0, 0, 0]}
        >
          <Tooltip
            label={
              <span>
                The
                <em>Delegate for Knowledge Management</em>
                has to make sure that the necessary knowledge is available to
                all persons involved in the project. (S)he has to find a data
                repository, structure the information, provide for the necessary
                information, design the push and pull processes on documentation
                and finally has to ensure that the acquired knowledge and is
                handed over from the project to the successor (e.g. customer or
                line function). This is a role which can be assumed next to
                other roles.
              </span>
            }
            placement="top-start"
            hasArrow
            maxWidth="500px"
          >
            <LabelTop>Delegate Knowledge Mgt</LabelTop>
          </Tooltip>

          <SelectPerson
            wId={"_setup"}
            name="delegateKnowledge"
            value={delegateKnowledge}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box w={["100%"]} pl={[0]} pr={[0]}>
          <Tooltip
            label="The members of the Project Team make the project happen in its core and do the operational work."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Project Team Members</LabelTop>
          </Tooltip>
          <SelectPersons
            name="membersTeam"
            value={membersTeam}
            isLocked={isStatusLocked}
          />
        </Box>

        <Box w={["100%", "50%", "50%"]} pl={[0, 0, 0, 0]} pr={[1, 1, 1, 1]}>
          <Tooltip
            label="Communication is a key driver for project success. If the project uses a certain communication tool you can enter it here."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Communication Tool</LabelTop>
          </Tooltip>
          <Input
            {...register("communicationTool")}
            defaultValue={communicationTool}
            onBlur={
              dirtyFields?.communicationTool ? handleSubmit(onBlur) : null
            }
            isDisabled={isStatusLocked}
          />
        </Box>

        <Box w={["100%", "50%", "50%"]} pl={[1, 1, 1, 1]} pr={[0, 0, 0, 0]}>
          <Tooltip
            label="Knowledge management is a very important aspect of any project. All project descriptions, requirements, tests, project plans, status reports, etc. should be available to persons who need such information. If the project uses a central data repository you can enter it here."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Data repository</LabelTop>
          </Tooltip>
          <Input
            {...register("dataRepository")}
            defaultValue={dataRepository}
            onBlur={dirtyFields?.dataRepository ? handleSubmit(onBlur) : null}
            isDisabled={isStatusLocked}
          />
        </Box>

        {isStatusLocked ? (
          <Box h="24px" w="100%"></Box>
        ) : (
          <Box w={["100%"]}>
            <chakra.button w="0" ref={inputRef}></chakra.button>
          </Box>
        )}
      </Flex>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    project: state.project,
    isResetForm: state.project.isResetForm,
    params: state.project.params,
  }
}

export default connect(mapStateToProps)(FormSetup)
