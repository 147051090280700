import { createSelector } from "reselect"

const getRolesStandard = (state) => state.project.rolesStandard

const getRolesAdded = (state) =>
  state.project.statuses[state.project.statusDateCurrent].rolesAdded
    ? state.project.statuses[state.project.statusDateCurrent].rolesAdded
    : []

const getRolesToPersons = (state) =>
  state.project.statuses[state.project.statusDateCurrent].rolesToPersons
    ? state.project.statuses[state.project.statusDateCurrent].rolesToPersons
    : []

const getOptionsRoles = createSelector(
  [getRolesStandard, getRolesAdded, getRolesToPersons],
  (rolesStandard, rolesAdded, rolesToPersons) => {
    let optionsRoles = [...rolesStandard, ...rolesAdded]
    let rolesAssigned = rolesToPersons.map((el) => el.roId)

    // filter out all roles that have already been assigned
    optionsRoles = optionsRoles.filter(
      (optionRole) =>
        !(rolesAssigned.indexOf(optionRole.value) > -1) ||
        optionRole.value === "memberTeam" ||
        optionRole.value === "memberStC"
    )

    return optionsRoles
  }
)

export default getOptionsRoles
