import React from "react"
import COLORS from "../../theme/colors"

const CustomizedTick = ({ x, y, stroke, payload }) => {
  //=================================================================

  // console.log({ payload })
  let splits = payload.value.split(" ")

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={12}
        fill={COLORS.grey[700]}
        style={{ fontSize: "14px" }}
      >
        <tspan textAnchor="middle" x="0">
          {splits[0]}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="14">
          {splits[1]}
        </tspan>
      </text>
    </g>
  )
}

export default CustomizedTick
