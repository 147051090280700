import React, { useState } from "react"
import { connect } from "react-redux"
import CreatableSelect from "react-select/creatable"
import getOptionsPersons from "../../redux/reselect/getOptionsPersons"
import customStyles from "./style/customStyles"

import CustomClearIndicator from "./CustomClearIndicator"

import { selectPersonUpdate } from "../../redux/actions/project"
import objDeepTrim from "../../functions/utils/objDeepTrim"

const SelectPerson = ({
  wId, // whereId
  name, // name refers to who, toWhom or role
  value, // name refers to
  optionsPersons,
  isDisabled,
  isIgnored = false,
  isLocked = false,
  dispatch,
}) => {
  // ================================================================
  const [inputLetters, setInputLetters] = useState("")

  // ----------------------------------------------------------------
  const onChange = (data, actionMeta) => {
    // data.label has either the id or — if new — the new name
    // wId = whereId = id has nId here

    let payload

    if (actionMeta.action === "clear") {
      payload = { wId, name, value: "", isNew: false }
    } else if (data.__isNew__) {
      payload = { wId, name, personName: data.label, isNew: true }
    } else {
      payload = { wId, name, value: data.value, isNew: false }
    }

    objDeepTrim(payload)

    dispatch(selectPersonUpdate(payload))

    // resets inputLetters to "", since it was already dispatched
    // prevents dispatch of onBlur
    setInputLetters("")
  }

  // autosave input string on blur
  const onInputChange = (inputChanged, actionMeta) => {
    // saves changes of individual letters
    if (actionMeta.action === "input-change") {
      setInputLetters(inputChanged)
    }
    // dispatches in case of onBlur
    else if (actionMeta.action === "input-blur" && inputLetters !== "") {
      let isNew = true
      let ix
      if (optionsPersons.length > 0) {
        // search for input
        ix = optionsPersons.findIndex(
          (o) => o.label.toLowerCase() === inputLetters.toLowerCase()
        )
        // isNew means it is not found
        isNew = ix === -1
      }
      let payload
      // same logic as in onChange
      if (isNew) {
        payload = { wId, name, personName: inputLetters, isNew: true }
      } else {
        payload = { wId, name, value: optionsPersons[ix].value, isNew: false }
      }
      setInputLetters("")

      objDeepTrim(payload)

      dispatch(selectPersonUpdate(payload))
    }
  }

  // ---------------------------------------------------------------
  let label = ""
  let index = optionsPersons.findIndex((obj, index) => obj.value === value)
  if (index > -1) {
    label = optionsPersons[index].label
  }

  // ================================================================
  return (
    <CreatableSelect
      backspaceRemovesValue={true}
      isClearable
      escapeClearsValue={true}
      // fires if onChange is triggered by return or mouse click
      onChange={onChange}
      // fires if a letter changes in an input word
      onInputChange={onInputChange}
      // requires an object
      value={{ value, label }}
      options={optionsPersons}
      styles={customStyles}
      isDisabled={isLocked}
      isLocked={isLocked}
      isIgnored={isIgnored}
      components={{
        ClearIndicator: CustomClearIndicator,
        DropdownIndicator: null,
        IndicatorSeparator: null,
        Placeholder: () => null,
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    optionsPersons: getOptionsPersons(state),
  }
}

export default connect(mapStateToProps)(SelectPerson)
