import COLORS from "../../theme/colors"

/**
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Calculates the color code based on a value and parameters
 * @param {number} value
 * @param {array} params
 * @returns {number} colorCode
 */
export function colorCodeCalc(value, params, isInverse = false) {
  if (!isInverse) {
    if (value === 0) {
      return 0
    }
    if (value >= params[0] && value < params[1]) {
      return 1 // → red
    } else if (value >= params[1] && value < params[2]) {
      return 2 // → orange
    } else if (value >= params[2] && value < params[3]) {
      return 3 // → yellow
    } else if (value >= params[3]) {
      return 4 // → green
    }
  }
  // if inverse
  else {
    if (value >= params[3]) {
      return 1 // → red
    } else if (value >= params[2]) {
      return 2 // → orange
    } else if (value >= params[1]) {
      return 3 // → yellow
    } else if (value >= params[0]) {
      return 4 // → green
    }
    return 0
  }
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Calculates the Hex color code based on a value and parameters
 * @param {number} value
 * @param {array} params
 * @returns {number} colorHex
 */
export const colorHex = (value, params, isInverse = false) => {
  const colorCode = colorCodeCalc(value, params, isInverse)
  let colorHex
  switch (colorCode) {
    case 1:
      colorHex = COLORS.red[500]
      break
    case 2:
      colorHex = COLORS.orange[500]
      break
    case 3:
      colorHex = COLORS.yellow[500]
      break
    case 4:
      colorHex = COLORS.green[500]
      break
    default:
      colorHex = COLORS.grey[500]
      break
  }
  return colorHex
}

export default colorCodeCalc
