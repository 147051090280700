import { scoreAggregate } from "../../functions/score"

export const scoreInitialStateSet = () => {
  let score = {
    scores: Array(10).fill(0),
    questions: {
      "00": {
        quId: "00",
        paId: "",
        question: "",
        answer: 1,
        answerMax: 0,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 0,
        children: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
      },
      "01": {
        quId: "01",
        paId: "00",
        children: ["01.01", "01.02", "01.03", "01.04", "01.05", "01.06"],
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 1,
      },
      "01.01": {
        quId: "01.01",
        paId: "01",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "01.02": {
        quId: "01.02",
        paId: "01",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "01.03": {
        quId: "01.03",
        paId: "01",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "01.04": {
        quId: "01.04",
        paId: "01",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "01.05": {
        quId: "01.05",
        paId: "01",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "01.06": {
        quId: "01.06",
        paId: "01",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "02": {
        quId: "02",
        paId: "00",
        children: ["02.01", "02.02", "02.03", "02.04", "02.05"],
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 1,
      },
      "02.01": {
        quId: "02.01",
        paId: "02",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "02.02": {
        quId: "02.02",
        paId: "02",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "02.03": {
        quId: "02.03",
        paId: "02",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "02.04": {
        quId: "02.04",
        paId: "02",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "02.05": {
        quId: "02.05",
        paId: "02",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "03": {
        quId: "03",
        paId: "00",
        children: ["03.01", "03.02", "03.03", "03.04", "03.05"],
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 1,
      },
      "03.01": {
        quId: "03.01",
        paId: "03",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "03.02": {
        quId: "03.02",
        paId: "03",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "03.03": {
        quId: "03.03",
        paId: "03",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "03.04": {
        quId: "03.04",
        paId: "03",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "03.05": {
        quId: "03.05",
        paId: "03",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "04": {
        quId: "04",
        paId: "00",
        children: ["04.01", "04.02", "04.03", "04.04", "04.05"],
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 1,
      },
      "04.01": {
        quId: "04.01",
        paId: "04",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "04.02": {
        quId: "04.02",
        paId: "04",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "04.03": {
        quId: "04.03",
        paId: "04",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "04.04": {
        quId: "04.04",
        paId: "04",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "04.05": {
        quId: "04.05",
        paId: "04",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "05": {
        quId: "05",
        paId: "00",
        children: ["05.01", "05.02", "05.03", "05.04", "05.05", "05.06"],
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 1,
      },
      "05.01": {
        quId: "05.01",
        paId: "05",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "05.02": {
        quId: "05.02",
        paId: "05",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "05.03": {
        quId: "05.03",
        paId: "05",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "05.04": {
        quId: "05.04",
        paId: "05",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "05.05": {
        quId: "05.05",
        paId: "05",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "05.06": {
        quId: "05.06",
        paId: "05",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "06": {
        quId: "06",
        children: ["06.01", "06.02", "06.03", "06.04", "06.05"],
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 1,
        paId: "00",
      },
      "06.01": {
        quId: "06.01",
        paId: "06",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "06.02": {
        quId: "06.02",
        paId: "06",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "06.03": {
        quId: "06.03",
        paId: "06",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "06.04": {
        quId: "06.04",
        paId: "06",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "06.05": {
        quId: "06.05",
        paId: "06",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "07": {
        quId: "07",
        paId: "00",
        children: ["07.01", "07.02", "07.03", "07.04", "07.05", "07.06"],
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 1,
      },
      "07.01": {
        quId: "07.01",
        paId: "07",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "07.02": {
        quId: "07.02",
        paId: "07",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "07.03": {
        quId: "07.03",
        paId: "07",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "07.04": {
        quId: "07.04",
        paId: "07",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "07.05": {
        quId: "07.05",
        paId: "07",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "07.06": {
        quId: "07.06",
        paId: "07",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "08": {
        quId: "08",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 1,
        paId: "00",
        children: [
          "08.01",
          "08.02",
          "08.03",
          "08.04",
          "08.05",
          "08.06",
          "08.07",
          "08.08",
        ],
      },
      "08.01": {
        quId: "08.01",
        paId: "08",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "08.02": {
        quId: "08.02",
        paId: "08",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "08.03": {
        quId: "08.03",
        paId: "08",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "08.04": {
        quId: "08.04",
        paId: "08",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "08.05": {
        quId: "08.05",
        paId: "08",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "08.06": {
        quId: "08.06",
        paId: "08",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "08.07": {
        quId: "08.07",
        paId: "08",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "08.08": {
        quId: "08.08",
        paId: "08",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "09": {
        quId: "09",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 1,
        paId: "00",
        children: [
          "09.01",
          "09.02",
          "09.03",
          "09.04",
          "09.05",
          "09.06",
          "09.07",
          "09.08",
        ],
      },
      "09.01": {
        quId: "09.01",
        paId: "09",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "09.02": {
        quId: "09.02",
        paId: "09",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "09.03": {
        quId: "09.03",
        paId: "09",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "09.04": {
        quId: "09.04",
        paId: "09",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "09.05": {
        quId: "09.05",
        paId: "09",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "09.06": {
        quId: "09.06",
        paId: "09",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "09.07": {
        quId: "09.07",
        paId: "09",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      "09.08": {
        quId: "09.08",
        paId: "09",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      10: {
        quId: "10",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 1,
        paId: "00",
        children: ["10.01", "10.02", "10.03", "10.04", "10.05"],
      },
      10.01: {
        quId: "10.01",
        paId: "10",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      10.02: {
        quId: "10.02",
        paId: "10",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      10.03: {
        quId: "10.03",
        paId: "10",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      10.04: {
        quId: "10.04",
        paId: "10",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
      10.05: {
        quId: "10.05",
        paId: "10",
        answer: 1,
        answerMax: 4,
        weight: 2,
        scorePercent: 0,
        score: 0,
        scoreMax: 3,
        level: 2,
      },
    },
  }
  score = scoreAggregate(score)
  return score
}

const scoreInitialState = { score: scoreInitialStateSet() }

export default scoreInitialState
