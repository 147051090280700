import React from "react"
import SEO from "../../components/SEO"
import { Container, Heading, Text } from "@chakra-ui/react"
import StatusTravel from "../../components/StatusTravel"
import ProgressCharts from "../../components/ProgressCharts"

const PageProgress = ({ chartsData, dispatch }) => {
  // ==========================================================================

  return (
    <Container minHeight={"640px"} maxW="1200px" mb={4}>
      <SEO
        title="Progress Analysis | THE PROJECT STATUS"
        urlPage="/progress"
        description="Analyse the progress."
      ></SEO>
      <Heading mb={2}>Progress Analysis</Heading>
      <Text mb={6}>
        The charts show important KPIs (Key Performance Indicators) of the
        project over time at status days. The first date in the charts is the
        project start date, the last date is the project end date. Lines between
        dates are interpolated. Circles on line graphs represent status days. If
        nothing is shown on a certain date, there is no information available.
      </Text>
      <StatusTravel mb={8} />
      <ProgressCharts />
    </Container>
  )
}

export default PageProgress
