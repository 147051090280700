/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * @param {object} score
 * @param {string} quId
 * @returns {object} score.question[quId]
 */
export function scoreAggregate(score, quId = "00") {
  if (
    score.questions[quId].children &&
    score.questions[quId].children.length > 0
  ) {
    // GO DOWN
    score.questions[quId].children.forEach((chId) => {
      score = scoreAggregate(score, chId)
    })
    // COME BACK UP AND AGGREGATE
    score.questions[quId].children.forEach((chId, index) => {
      if (index === 0) {
        score.questions[quId].scoreMax = score.questions[chId].scoreMax
      } else {
        score.questions[quId].scoreMax += score.questions[chId].scoreMax
      }
    })

    score.questions[quId].children.forEach((chId, index) => {
      if (index === 0) {
        score.questions[quId].score = score.questions[chId].score
      } else {
        score.questions[quId].score += score.questions[chId].score
      }
    })

    score = questionScorePercentCalc(score, quId)
  } else {
    // CALC LEAVES
    // calc score
    score = questionScoreCalc(score, quId)
    score = questionScoreMaxCalc(score, quId)
    score = questionScorePercentCalc(score, quId)
  }

  return score
}

function questionScoreCalc(score, quId) {
  score.questions[quId].score =
    score.questions[quId].answer === 0
      ? 0
      : score.questions[quId].weight * (score.questions[quId].answer - 1)
  return score
}

function questionScoreMaxCalc(score, quId) {
  score.questions[quId].scoreMax =
    score.questions[quId].answer === 0
      ? 0
      : score.questions[quId].weight * (score.questions[quId].answerMax - 1)
  return score
}

function questionScorePercentCalc(score, quId) {
  score.questions[quId].scorePercent =
    score.questions[quId].scoreMax === 0
      ? 0
      : Math.round(
          (score.questions[quId].score / score.questions[quId].scoreMax) * 100,
          0
        )
  return score
}
