import { customAlphabet } from "nanoid"

const CUSTOM_ALPHABET = "abcdefghijkmpqrstuvwxyz"

// no: '0', l', 'o'
// always start with a letter
const nanoId1 = () => customAlphabet(CUSTOM_ALPHABET, 1)()
const nanoId2 = (idLength) =>
  customAlphabet(CUSTOM_ALPHABET + "123456789", idLength - 1)()

const nanoId = (idLength = 6, withPoints = false) => {
  let id = nanoId1() + nanoId2(idLength)

  // adds a dot for better readability after each third char
  if (withPoints) {
    id = [...id]
      .map((char, index) => {
        if (index + 1 < idLength && (index + 1) % 3 === 0) {
          return char + "."
        }
        return char
      })
      .join("")
  }

  return id
}

export default nanoId
