/**
 * v1.0.0 (c) Prof. Dr. Ulrich Anders
 *
 * Like parseInt, but returns 0 when s === "" or s === "undefined"
 * @param {string} s
 * @returns {number}
 */
const parseInt0 = (s) => (s === undefined || s === "" ? 0 : parseInt(s))

export default parseInt0
