import cloneDeep from "lodash.clonedeep"
import nodeCreate from "./nodeCreate"
import { ROOT } from "../../const/globals"

/**
 * v1.0.0: (c) Jasper Anders
 * v2.2.0: (c) Prof. Dr. Ulrich Anders
 *
 * Creates nodesNew after nId has been moved in in the nodes tree
 * @param {object} nodes
 * @param {object} node
 * @param {array} rows - list of nodes
 */
export function moveIn(nodes, nId, rows) {
  let node = nodes[nId]
  const paId = nodes[nId].paId
  const parent = nodes[paId]
  const indexRows = rows.indexOf(nId)
  const neighborTop = indexRows - 1 > 0 ? nodes[rows[indexRows - 1]] : undefined
  let nodesNew = cloneDeep(nodes)

  // if neighborTop is on deeper level
  // the brother to the top of the nId becomes the new parent
  if (
    !node.isIgnored &&
    indexRows > 1 &&
    node.position.length < neighborTop.position.length
  ) {
    // find index nId in children of parent
    const indexNIdChildOfPId = parent.children.indexOf(nId)
    // the brother to the the of the nId becomes the new parent
    const parentNew = nodes[parent.children[indexNIdChildOfPId - 1]]
    // set new parent of node
    nodesNew[nId].paId = parentNew.nId
    // set node as new child of new parent
    nodesNew[parentNew.nId].children.push(nId)
    // remove node from children of old parent
    nodesNew[paId].children.splice(indexNIdChildOfPId, 1)
  }
  // else if neighborTop is on same level but is an aggregate node
  // then neighborTop becomes the new parent
  else if (
    node.position.length === neighborTop.position.length &&
    neighborTop.isAggregate
  ) {
    // find index nId in children of parent
    const indexNIdChildOfPId = parent.children.indexOf(nId)
    // remove node from children of old parent
    nodesNew[paId].children.splice(indexNIdChildOfPId, 1)
    // neighborTop becomes the new parent of node nId
    nodesNew[nId].paId = neighborTop.nId
    // set node nId as new child of new parent
    nodesNew[neighborTop.nId].children.push(nId)
  } else {
    // create new parent node
    const parentNew = nodeCreate({
      nId: null,
      paId,
      children: [nId],
      fromWhen: nodesNew[nId].fromWhen,
      byWhen: nodesNew[nId].byWhen,
    })
    parentNew.isAggregate = true
    nodesNew[nId].paId = parentNew.nId
    parentNew.deliversWhat = nodesNew[nId].deliversWhat + " ++"
    parentNew.who = nodesNew[nId].who
    parentNew.toWhom = nodesNew[nId].toWhom

    // add new parent to nodesNew
    nodesNew = {
      ...nodesNew,
      [parentNew.nId]: { ...parentNew },
    }

    // remove node from old parent
    const indexNIdChildOfPId = parent.children.indexOf(nId)
    nodesNew[paId].children.splice(indexNIdChildOfPId, 1, parentNew.nId)
  }
  return nodesNew
}

/**
 * v1.0.0: (c) Jasper Anders
 * v2.0.0: (c) Prof. Dr. Ulrich Anders Anders
 *
 * Creates nodesNew after nId has been moved out in the nodes tree
 * @param {object} nodes - nodes tree
 * @param {string} nId - nId of node
 * @return {object} nodesNew - resulting tree after move out
 */
export function moveOut(nodes, nId) {
  let node = nodes[nId]
  let paId = node.paId
  let nodesNew = cloneDeep(nodes)
  // only move out if at least on level 2
  if (
    (node.isAggregate && node.position.length > 1) ||
    (!node.isAggregate && node.position.length > 2)
  ) {
    // find grand parent gpId, move up two parents
    const gpId = nodes[paId].paId
    // previous grand parent becomes the new parent
    nodesNew[nId].paId = gpId
    // set node as new child of grandparent after index of parent
    const indexPaIdChildOfGpId = nodes[gpId].children.indexOf(paId)
    nodesNew[gpId].children.splice(indexPaIdChildOfGpId + 1, 0, nId)

    if (node.isIgnored && nodesNew[paId].children.length === 1) {
      // remove paId from children of gpId
      const indexPaIdChildOfGpId = nodes[gpId].children.indexOf(paId)
      nodesNew[gpId].children.splice(indexPaIdChildOfGpId, 1)

      // remove paID from all dependents
      nodesNew[paId].precedents.forEach((precedent) => {
        nodesNew[precedent].dependents = nodesNew[precedent].dependents.filter(
          (dependent) => dependent !== paId
        )
      })
      // remove paId node
      delete nodesNew[paId]
    }
    // keep parent node
    else {
      // remove nId from parent node and keep the rest
      const indexNIdChildOfPId = nodes[paId].children.indexOf(nId)
      nodesNew[paId].children.splice(indexNIdChildOfPId, 1)
    }
  }
  return nodesNew
}

/**
 * v1.0.0: (c) Jasper Anders
 * v3.0.0: (c) Prof. Dr. Ulrich Anders Anders
 *
 * Moves a node up or down in its current level.
 * @param {object} nodes
 * @param {string} nId
 * @param {array} rows
 * @param {boolean} isMoveUp
 * @return {object} nodesNew
 */
export function moveUpDown(nodes, nId, rows, isMoveUp) {
  let nodesNew = cloneDeep(nodes)
  const paId = nodesNew[nId].paId
  const moveValue = isMoveUp ? -1 : 1
  const parent = nodesNew[paId]
  const indexRows = rows.indexOf(nId)
  const neighborTop = indexRows > 0 ? nodesNew[rows[indexRows - 1]] : undefined
  const neighborTopTop =
    indexRows > 1 ? nodesNew[rows[indexRows - 2]] : undefined
  const neighborBottom =
    indexRows < rows.length - 1 ? nodesNew[rows[indexRows + 1]] : undefined
  const indexNIdChildOfPId = parent.children.indexOf(nId)

  // situation 1: do nothing, if...
  // already at position [1] and want to move up or
  // already at last position and want to move down
  if (
    (indexRows === 1 && isMoveUp) ||
    (indexRows + 1 === rows.length && !isMoveUp) ||
    nId === ROOT
  ) {
    // do nothing
    return nodesNew
  }
  // situation 2
  // move node within the same parent up or down
  else if (
    parent.children.length > 1 &&
    ((indexNIdChildOfPId === 0 && !isMoveUp) ||
      (indexNIdChildOfPId > 0 &&
        indexNIdChildOfPId < parent.children.length - 1) ||
      (indexNIdChildOfPId === parent.children.length - 1 && isMoveUp))
  ) {
    nodesNew[paId].children.splice(indexNIdChildOfPId, 1)
    // place it inside the new position either + or - 1 of previous position
    nodesNew[paId].children.splice(indexNIdChildOfPId + moveValue, 0, nId)
    // parentId stays the same. There is no need to change it.
    return nodesNew
  }
  // situation 3
  // move down node outside the same parent
  else if (!isMoveUp && neighborBottom?.isAggregate === true) {
    nodesNew[paId].children.splice(indexNIdChildOfPId, 1)
    const nbId = neighborBottom.nId
    nodesNew[nId].paId = nbId
    nodesNew[nbId].children.unshift(nId)
  }
  // situation 4a-4c
  // move up node outside the same parent
  else if (isMoveUp && neighborTopTop) {
    // is neighborTopTop is aggregate, neighborTopTop becomes parent to nId
    // node moves from brother to brother
    if (
      neighborTopTop.isAggregate &&
      neighborTopTop.paId === neighborTop.paId
    ) {
      nodesNew[paId].children.splice(indexNIdChildOfPId, 1)
      const nttId = neighborTopTop.nId
      nodesNew[nId].paId = nttId
      nodesNew[nttId].children.unshift(nId)
    }
    // is neighborTopTop is aggregate, node does not move from brother to brother
    // neighborTop is empty aggregate
    // neighborTopTop does not become parent to nId
    else if (neighborTopTop.isAggregate && neighborTopTop.children.length > 0) {
      nodesNew[paId].children.splice(indexNIdChildOfPId, 1)
      const nttId = neighborTopTop.nId
      nodesNew[nId].paId = nttId
      nodesNew[nttId].children.unshift(nId)
    }
    // otherwise, neighborTopTop becomes brother to nId
    // parent of neighborTopTop becomes new parent of nId
    else {
      nodesNew[paId].children.splice(indexNIdChildOfPId, 1)
      const nttNId = neighborTopTop.nId
      const nttPaId = neighborTopTop.paId
      const indexNttNidChildOfNttPaId =
        nodesNew[nttPaId].children.indexOf(nttNId)
      nodesNew[nId].paId = nttPaId
      nodesNew[nttPaId].children.splice(indexNttNidChildOfNttPaId + 1, 0, nId) //
    }
  }

  return nodesNew
}
