import { ROOT } from "../../const/globals.js"
import cloneDeep from "lodash.clonedeep"
import parseISO from "date-fns/parseISO"
import { endOfBusinessDayISO } from "../timeHandler/bordersOfBusinessDay"
import nodeCreate from "./nodeCreate"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Adds a nodeNew after an existing node
 * @param {object} nodes - nodes tree
 * @param {string} nIdBefore - Id of the node before
 * @return {object} nodes - new nodes tree
 */
export const nodeNewAddAfter = (nodes, nIdBefore) => {
  let nodesNew = cloneDeep(nodes)
  let nodeNew = nodeCreate({ nId: null })
  const paId = nodes[nIdBefore].paId
  const nId = nodeNew.nId
  nodesNew[nId] = nodeNew

  if (nIdBefore !== ROOT) {
    // take over paId
    nodesNew[nId].paId = paId
    // enter nId into paId.children array after nIdBefore
    const index = nodesNew[paId].children.indexOf(nIdBefore)
    nodesNew[paId].children.splice(index + 1, 0, nId)
    nodesNew[nId].fromWhen = nodesNew[paId].fromWhen
    nodesNew[nId].byWhen = endOfBusinessDayISO(parseISO(nodesNew[nId].fromWhen))
    // all children of ROOT are aggregates
    if (paId === ROOT) {
      nodesNew[nId].isAggregate = true
    }
  }
  // nIdBefore is ROOT
  else {
    nodesNew[nId].paId = ROOT
    nodesNew[ROOT].children.splice(0, 0, nId)
    nodesNew[nId].isAggregate = true
    nodesNew[nId].fromWhen = nodesNew[ROOT].fromWhen
    nodesNew[nId].byWhen = endOfBusinessDayISO(parseISO(nodesNew[nId].fromWhen))
  }

  return { nodesNew, nIdNew: nId }
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Adds a nodeNew after an existing node
 * @param {object} nodes - nodes tree
 * @param {string} nIdBefore - Id of the node before
 * @return {object} nodes - new nodes tree
 */
export const nodeNewAddAsChild = (nodes, paId) => {
  let nodesNew = cloneDeep(nodes)
  let nodeNew = nodeCreate({ nId: null, paId })
  const nId = nodeNew.nId
  nodesNew[nId] = nodeNew
  nodesNew[nId].fromWhen = nodesNew[paId].fromWhen
  nodesNew[nId].byWhen = endOfBusinessDayISO(parseISO(nodesNew[nId].fromWhen))
  nodesNew[paId].children.unshift(nId)
  if (paId === ROOT) {
    nodesNew[nId].isAggregate = true
  }

  return { nodesNew, nIdNew: nId }
}
