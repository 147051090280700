import { chakra } from "@chakra-ui/react"

const LabelTop = chakra("label", {
  // attach style rest
  baseStyle: {
    fontSize: "sm",
    color: "blue.500",
    fontWeight: "normal",
    display: "inline-block",
  },
})

export default LabelTop
