import React from "react"
import {
  Flex,
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Link,
  Image,
} from "@chakra-ui/react"
import BestPracticesFramework from "../../img/1200/Prof-Dr-Ulrich-Anders_Project-Work-Best-Practices-Framework_v1.0.0.png"

const ScoreLegend = () => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <>
      <Flex justify="space-around" wrap="wrap">
        <Box w={["50%", "50%", "50%", "50%", "33%"]} mb={4} px={1}>
          <Text fontWeight="medium">LEGEND</Text>
          <Text>
            A more detailed description of the five maturity levels. The bullet
            points explain what companies typically have achieved on each level.
            A higher level encompasses all achievements of the lower levels.
          </Text>
        </Box>
        <Box w={["50%", "50%", "50%", "50%", "33%"]} px={1} mb={4}>
          <Text fontWeight="medium">0 − 20%: LOW MATURITY</Text>
          <UnorderedList>
            <ListItem>Ad hoc approach without synergies</ListItem>
            <ListItem>Best effort basis of involved persons</ListItem>
            <ListItem>No common language</ListItem>
            <ListItem>Little structural project support</ListItem>
            <ListItem>No investment in PM</ListItem>
          </UnorderedList>
        </Box>
        <Box w={["50%", "50%", "50%", "50%", "33%"]} mb={4} px={1}>
          <Text fontWeight="medium">21 − 40%: BASIC MATURITY</Text>

          <UnorderedList>
            <ListItem>
              Recognition that good project management increases success and
              reduces waste of investments
            </ListItem>
            <ListItem>
              Tangible benefits and possible synergies made apparent
            </ListItem>
            <ListItem>
              Recognition of importance of efficient project control
            </ListItem>
            <ListItem>
              Desire to become more professional in project management
            </ListItem>
            <ListItem>Common language</ListItem>
            <ListItem>
              Sporadic use of a structured project management approach
            </ListItem>
          </UnorderedList>
        </Box>

        <Box w={["50%", "50%", "50%", "50%", "33%"]} mb={4} px={1}>
          <Text fontWeight="medium">41 − 60%: MEDIUM MATURITY</Text>
          <UnorderedList>
            <ListItem>Some general practices and standards defined</ListItem>
            <ListItem>Training programs initiated </ListItem>
            <ListItem>Some PM support throughout the firm</ListItem>
            <ListItem>
              Dedicated resources for PM within the organization
            </ListItem>
            <ListItem>Regular and standardized project status reports</ListItem>
          </UnorderedList>
        </Box>

        <Box w={["50%", "50%", "50%", "50%", "33%"]} mb={4} px={1}>
          <Text fontWeight="medium">61 − 80%: ADVANCED MATURITY</Text>
          <UnorderedList>
            <ListItem>Institutionalized, structured process</ListItem>
            <ListItem>Support by tools and in context</ListItem>
            <ListItem>Project offices established</ListItem>
            <ListItem>Analysis and evaluation of practices</ListItem>
            <ListItem>Knowledge transfer between projects</ListItem>
            <ListItem>
              Widely consistent and synergetic approaches to project management
              and control
            </ListItem>
          </UnorderedList>
        </Box>
        <Box w={["50%", "50%", "50%", "50%", "33%"]} mb={4} px={1}>
          <Text fontWeight="medium">81 − 100%: BEST PRACTICE MATURITY</Text>
          <UnorderedList>
            <ListItem>Proven standards that are continuously improved</ListItem>
            <ListItem>Significant support for projects</ListItem>
            <ListItem>Fast moving project organization</ListItem>
            <ListItem>Systematic debriefing</ListItem>
            <ListItem>
              Critical review for double-learning from projects
            </ListItem>
            <ListItem>Mentorship programs</ListItem>
          </UnorderedList>
        </Box>
      </Flex>

      <Heading>Best Practices Framework</Heading>

      <Text mb={4}>
        The Best Practices Score for project work is based on the following{" "}
        <Link
          href="https://project-status.org/concepts/best-practices"
          isExternal
          color="blue.800"
        >
          Project Work Best Practices Framework (PWBPF)
        </Link>
        . The elements of the Best Practices Framework result from the typical
        project phases and the necessary supporting elements for efficient
        project work. The score is then based on the evaluation of each of the
        elements by help of a questionnaire. Best practices are defined by (a)
        what prevents ad-hoc processes, re-inventions, always starting from
        scratch, individual status template designs, by (b) what makes a project
        synergetic, efficient, skillful, knowledgeable, transparent, and more
        successful and by (c) what makes the company more experienced with
        respect to project work as a whole.
      </Text>

      <Image src={BestPracticesFramework} alt="Best Practice Framework" />
    </>
  )
}

export default ScoreLegend
