import { ROOT } from "../../const/globals"

/**
 * v1.1.0 (c) Prof. Dr. Ulrich Anders
 *
 * Checks if a node can be toggled between
 * isAggregate true or false.
 * The condition is that all characteristics
 * of a node that are aggregated are empty.
 * Nodes on level 1 are always isAggregate by default.
 * @param {object} node
 * @param {boolean} ignoreLevelOneCannotToggle
 * @returns {boolean} nodeIsToggleable
 */
export const nodeIsAggToggleableCalc = (
  node,
  ignoreLevelOneCannotToggle = false
) => {
  const nodeIsAggToggleable =
    (ignoreLevelOneCannotToggle ? true : node.position.length >= 2) &&
    node.children.length === 0 &&
    node.isIgnored === false &&
    node.isUnresolved === false &&
    node.spent.days === 0 &&
    node.spent.hours === 0 &&
    node.degree === 0 &&
    node.deadline === 0 &&
    node.forecast === 0 &&
    node.quality === 0
  return nodeIsAggToggleable
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * checks if a node has not received user information
 * @param {object} node
 * @returns {object} node
 */
export const nodeIsEmptyCalc = (node) => {
  return (
    node.children.length === 0 &&
    node.precedents.length === 0 &&
    node.isIgnored === false &&
    node.isUnresolved === false &&
    node.who === "" &&
    node.deliversWhat === "" &&
    node.toWhom === "" &&
    node.spent.days === 0 &&
    node.spent.hours === 0 &&
    node.degree === 0 &&
    node.deadline === 0 &&
    node.forecast === 0 &&
    node.quality === 0 &&
    node.comment === ""
  )
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Calculates, if a node nId is top of the children of its parent
 * @param {object} nodes
 * @param {string} nId
 * @return {boolean}
 */
export const nodeIsTopCalc = (nodes, nId) => {
  if (nId === ROOT) {
    return true
  } else {
    const paId = nodes[nId].paId
    if (nodes[paId].children.indexOf(nId) === 0) {
      return true
    }
    return false
  }
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Calculates, if a node nId is the bottom of the children of its parent
 * @param {object} nodes
 * @param {string} nId
 * @return {boolean}
 */
export const nodeIsBottomCalc = (nodes, nId) => {
  if (nId === ROOT) {
    return true
  } else {
    const paId = nodes[nId].paId
    if (nodes[paId].children.indexOf(nId) === nodes[paId].children.length - 1) {
      return true
    }
    return false
  }
}

/**
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Calculates, if a node nId can moveUp
 * @param {object} nodes
 * @param {string} nId
 * @return {boolean}
 */
export const nodeCanMoveUpCalc = (nodes, rows, nId) => {
  const indexRows = rows.indexOf(nId)
  let nodeCanMoveUp = true

  if (indexRows < 2) {
    nodeCanMoveUp = false
  }
  if (indexRows === 2 && !nodes[nId].isAggregate) {
    nodeCanMoveUp = false
  }

  return nodeCanMoveUp
}

/**
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Calculates, if a node nId can moveUp
 * @param {object} nodes
 * @param {string} nId
 * @return {boolean}
 */
export const nodeCanMoveDownCalc = (nodes, rows, nId) => {
  let lastNId = rows[rows.length - 1]
  let lastPath = [lastNId]
  nodesAncestorsPathGen(nodes, lastNId, lastPath)
  let nodeCanMoveDown = true
  if (lastPath.includes(nId)) {
    nodeCanMoveDown = false
  }

  return nodeCanMoveDown
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Generates the path of ancestors of nId recursively
 * @param {object} nodes
 * @param {string} nId
 * @param {array} ancestorsPath
 * @return {array} ancestorsPath
 */
const nodesAncestorsPathGen = (nodes, nId, ancestorsPath) => {
  if (nId !== ROOT) {
    const paId = nodes[nId].paId
    ancestorsPath.push(paId)
    if (paId !== ROOT) {
      nodesAncestorsPathGen(nodes, paId, ancestorsPath)
    }
  }
  return ancestorsPath
}
