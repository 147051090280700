import React from "react"
import { connect } from "react-redux"
import { Flex } from "@chakra-ui/react"
import { ButtonIcon, ButtonText } from "../../componentsWithChakra"
import { FiPlus, FiMinus } from "react-icons/fi"
import { ganttSizeUpdate } from "../../redux/actions/settings"

import { ganttDurationUpdate } from "../../redux/actions/settings"

const GanttChartResize = ({ settings, dispatch }) => {
  //=================================================================

  const { ganttDurationOptions, ganttDurationIndex } = settings

  const onClickDuration = (e) => {
    e.preventDefault()
    dispatch(ganttDurationUpdate())
  }

  const onClickPlus = (e) => {
    e.preventDefault()
    let payload = { deltaBoxW: +1 }
    dispatch(ganttSizeUpdate(payload))
  }

  const onClickMinus = (e) => {
    e.preventDefault()
    let payload = { deltaBoxW: -1 }
    dispatch(ganttSizeUpdate(payload))
  }

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Flex justify="flex-end" align="flex-start">
      <ButtonText onClick={onClickDuration} pr={4}>
        {ganttDurationOptions[ganttDurationIndex]}
      </ButtonText>
      <ButtonIcon onClick={onClickMinus}>
        <FiMinus />
      </ButtonIcon>
      <ButtonIcon onClick={onClickPlus} ml={2} pr={4}>
        <FiPlus />
      </ButtonIcon>
    </Flex>
  )
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(GanttChartResize)
