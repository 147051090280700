import startOfDay from "date-fns/startOfDay"
import endOfDay from "date-fns/startOfDay"

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Transforms any time point of a given date to its
 * start of the day time point
 * @param {data} date
 * @param {string} dayStart
 * @returns {date} date
 */
export const startOfBusinessDay = (date, dayStart = "9") => {
  if (typeof dayStart === "string") {
    dayStart = parseInt(dayStart)
  }
  let dateNew = startOfDay(date)
  dateNew.setHours(dayStart)
  return dateNew
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Transforms any time point of a given date to its
 * start of the day time point in ISO format
 * @param {data} date
 * @param {string} dayStart
 * @returns {string} ISO date
 */
export const startOfBusinessDayISO = (date, dayStart = "9") => {
  const dateNew = startOfBusinessDay(date, dayStart)
  return dateNew.toISOString()
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Transforms any time point of a given date to its
 * end of the day time point
 * @param {date} date
 * @param {string} dayStart
 * @returns {date} date
 */
export const endOfBusinessDay = (date, dayEnd = "17") => {
  if (typeof dayEnd === "string") {
    dayEnd = parseInt(dayEnd)
  }
  let dateNew = endOfDay(date)
  dateNew.setHours(dayEnd)

  return dateNew
}

/**
 * v:1.1.0 (c) Prof. Dr. Ulrich Anders
 *
 * Transforms any time point of a given date to its
 * end of the day time point in ISO format
 * @param {data} date
 * @param {string} dayStart
 * @returns {string} ISO date
 */
export const endOfBusinessDayISO = (date, dayEnd = "17") => {
  const dateNew = endOfBusinessDay(date, dayEnd)
  // TODO: why was this done?
  // return dateNew.toISOString().replace(/59/g, "00").replace(/999/g, "000")
  return dateNew.toISOString()
}
