import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"

const ButtonOK = forwardRef(({ ...rest }, ref) => (
  <chakra.button
    ref={ref}
    type="submit"
    textAlign="center"
    outline="none"
    {...rest}
  >
    OK
  </chakra.button>
))

ButtonOK.propTypes = {
  rest: PropTypes.object,
}

export default ButtonOK
