import { Flex, Box, Spacer, Tooltip } from "@chakra-ui/react"
import { ButtonIcon } from "../../componentsWithChakra"
import * as WIDTH from "../../const/widths"

import { BiBlock } from "react-icons/bi"
import { FaSortNumericDown, FaSortAlphaDown } from "react-icons/fa"

import {
  isWhoSortedByNameUpdate,
  isToWhomSortedByNameUpdate,
} from "../../redux/actions/project"

const SheetHeader = ({ params, dispatch }) => {
  //=================================================================
  const { isSlimOn, isWhoSortedByName, isToWhomSortedByName, isKanbanOn } =
    params
  const isSlimBin = isSlimOn ? 1 : 0

  const dispatchIsWhoSortedByNameUpdate = () => {
    dispatch(isWhoSortedByNameUpdate())
  }

  const dispatchIsToWhomSortedByNameUpdate = () => {
    dispatch(isToWhomSortedByNameUpdate())
  }

  //=================================================================
  return (
    <Flex mb={4} mr={2}>
      <Tooltip
        label="DEFAULT → UNRESOLVED → IGNORED → DEFAULT. UNRESOLVED: Mark unresolved issues. The number of unresolved issues will be shown under the Important Ratios. IGNORED: Click to ignore issues. The time spent will still be counted in the accumulation, but traffic light colors will not be considered in the aggregation."
        placement="top-start"
        hasArrow
      >
        <Flex
          w={WIDTH.MARK[isSlimBin]}
          px={0}
          color="white"
          bg="blue.400"
          justify="space-around"
        >
          <Box position="relative" top="-2px">
            <BiBlock />
          </Box>
        </Flex>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="POSITION: Shows the hierarchy of the deliverable."
        placement="top-start"
        hasArrow
      >
        <Box w={WIDTH.POSITION[isSlimBin]} color="white" bg="blue.400" px={1}>
          POSITION
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="WIP/L: Work In Progress / LIMIT. WIP: A deliverable is time has been spent but the degree is <100%. Unresolved or ignored items do not count as work in progress. LIMIT: the maximum number of items that are allowed to be work in progress. WIPs and LIMITs only shown on aggregated nodes. WIPs are aggregated LIMITs are not."
        placement="top-start"
        hasArrow
      >
        <Box
          w={WIDTH.WIP[isSlimBin]}
          color="white"
          bg="blue.400"
          px={1}
          textAlign="right"
        >
          WIP/L
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="WHO: The responsible person (owner) for the deliverable or the set of deliverables. Enter a new person or select an existing one. You can remove a person with the little ×. You can sort the WHO column. Default is by position depicted with a ↓19. You can alternatively sort the column by the name of the owner depicted with a ↓AZ. In this sorting you cannot move, add, or delete rows."
        placement="top"
        hasArrow
      >
        <Flex
          w={WIDTH.WHO[isSlimBin]}
          bg="blue.400"
          color="white"
          px={1}
          justify="space-between"
          align="baseline"
        >
          <Flex align="baseline" wrap="wrap">
            <Box mr={1}>WHO</Box>{" "}
            <Box
              fontSize="xs"
              display={["none", "none", "none", "none", "block", "block"]}
            >
              (OWNER)
            </Box>
          </Flex>
          <ButtonIcon
            onClick={dispatchIsWhoSortedByNameUpdate}
            ml={1}
            display={["none", "none", "none", "block", "block", "block"]}
          >
            {isWhoSortedByName ? <FaSortAlphaDown /> : <FaSortNumericDown />}
          </ButtonIcon>
        </Flex>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label={
          isKanbanOn
            ? "LISTS & CARDS: KANBAN is comprised of lists and cards. Cards contain the deliverables. Lists reflect a status of the deliverable. Thus a card is sitting on the list that reflects its status. The card travels to a different list if its status changes, e.g. from WIP to DONE."
            : "DELIVERS WHAT: The deliverable that is the resulting output. On an aggregated node this can also contain the name of a sub-project, stream or element in the breakdown structure of the project. Ideally, these names still reflect an output focus."
        }
        placement="top"
        hasArrow
      >
        <Flex
          w={WIDTH.WHAT[isSlimBin]}
          bg="blue.400"
          color="white"
          px={1}
          align="baseline"
        >
          <Box>{isKanbanOn ? "LISTS & CARDS" : "DELIVERS WHAT"}</Box>

          <Box
            fontSize="xs"
            ml={1}
            display={["none", "none", "none", "none", "block", "block"]}
          >
            {isKanbanOn ? "(FOR DELIVERABLE)" : "(ITEM OR SET)"}
          </Box>
        </Flex>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="BY WHEN: The promised delivery date. A down pointing arrow will fixate the due date, a right pointing arrow will allow for a recalculation based on duration. FORMAT: '2021-07-31 12' or short forms of this, e.g. '07-31' or '31'. HINT: Speed up your input by entering a '+' followed by a duration string, e.g. '+1w3d5'."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.WHEN[isSlimBin]}
          bg="blue.400"
          color="white"
          px={1}
          textAlign="right"
        >
          BY WHEN
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="TO WHOM: Person who receives the deliverable and then has to evaluate its quality. Enter a new person or select an existing one. You can remove a person with the little ×. You can sort the TO WHOM column. Default is by position depicted with a ↓19. You can alternatively sort the column by the name of the owner depicted with a ↓AZ. In this sorting you cannot move, add, or delete rows."
        placement="top"
        hasArrow
      >
        <Flex
          w={WIDTH.WHO[isSlimBin]}
          bg="blue.400"
          color="white"
          px={1}
          justify="space-between"
          align="baseline"
        >
          <Box>TO WHOM</Box>{" "}
          <ButtonIcon
            onClick={dispatchIsToWhomSortedByNameUpdate}
            ml={1}
            display={["none", "none", "none", "block", "block", "block"]}
          >
            {isToWhomSortedByName ? <FaSortAlphaDown /> : <FaSortNumericDown />}
          </ButtonIcon>
        </Flex>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="SPENT: The time that has already been spent working on the deliverable. In order to not count 'resultless' amounts of time, SPENT is only aggregated up if the DOC % > 0. FORMAT: '1y2m3w4d5' or excerpts."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.SPENT[isSlimBin]}
          bg="blue.400"
          color="white"
          px={1}
          textAlign="right"
        >
          SPENT
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="DOC — DEGREE OF COMPLETION %: For individual deliverables — enter a percentage number that reflects the degree of completion. For aggregated deliverables — the computation is DOC = Σ SPENTs|DOCs>0 / Σ PROJECTIONs. This means only those SPENT values are aggregated where the DOC>0."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.DEGREE[isSlimBin]}
          bg="blue.400"
          color="white"
          px={1}
          textAlign="right"
        >
          %
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="DL — DEADLINE: A traffic light color evaluating if the deliverable has been delivered on time before the deadline to 100%. The traffic light color is set automatically. If the work on a Deliverable has already started and achieved a degree >0% a hourglass is shown indication a WIP (Work In Progress)."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.DEADLINE[isSlimBin]}
          color="white"
          bg="blue.400"
          textAlign="center"
        >
          DL
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="FC — FORECAST: For individual deliverables — enter a forecast if the promised delivery date will be met. For aggregated deliverables — the color of the worst traffic light forecast is automatically aggregated up."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.FORECAST[isSlimBin]}
          color="white"
          bg="blue.400"
          textAlign="center"
        >
          FC
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="Q — QUALITY: For individual deliverables — enter a traffic light color with which the receiver (TO WHOM) evaluates the quality of the deliverable. A red quality requires the setup of a new entry for improving the quality to green. For aggregated deliverables — the color of the worst traffic light forecast is automatically aggregated up."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.QUALITY[isSlimBin]}
          bg="blue.400"
          color="white"
          px={0}
          textAlign="center"
        >
          Q
        </Box>
      </Tooltip>

      {!isSlimOn && <Spacer bg="transparent" />}

      {!isSlimOn && (
        <Tooltip
          label="COMMENT: Every traffic light color other than green requires a comment."
          placement="top"
          hasArrow
        >
          <Box w={WIDTH.COMMENT[isSlimBin]} bg="blue.400" color="white" px={1}>
            COMMENT (ISSUE or POTENTIAL FIX)
          </Box>
        </Tooltip>
      )}

      {!isSlimOn && <Spacer bg="transparent" />}

      {!isSlimOn && (
        <Tooltip
          label={
            isKanbanOn
              ? "BLOCKS: Enter the positions that are blocking this card and that need to be completed first."
              : "PRECEDENTS: Enter positions that need to be finished before the work on this deliverable can start. Separate with commas."
          }
          placement="top-end"
          hasArrow
        >
          <Box w={WIDTH.PRECS[isSlimBin]} color="white" bg="blue.400" px={1}>
            {isKanbanOn ? "BLOCKS" : "PRECS"}
          </Box>
        </Tooltip>
      )}
    </Flex>
  )
}

export default SheetHeader
