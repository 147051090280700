export const SPACING = "4px"

// 0: isSlimOn = false
// 1: isSlimOn = true
export const MARK = [
  ["2%", "2%", "2%", "2%", "2%", "2%"],
  ["3%", "3%", "3%", "2%", "2%", "2%"],
]

export const POSITION = [
  ["9%", "9%", "9%", "9%", "9%", "10%"],
  ["12%", "12%", "12%", "9%", "9%", "10%"],
]

export const WIP = [
  ["4%", "4%", "4%", "5%", "4%", "3%"],
  ["3%", "3%", "3%", "3%", "2%", "2%"],
]

export const WHO = [
  ["8%", "8%", "8%", "7%", "7%", "8%"],
  ["13%", "13%", "13%", "14%", "14%", "8%"],
]

export const WHAT = [
  ["15%", "15%", "15%", "15%", "15%", "15%"],
  ["22%", "22%", "22%", "23%", "30%", "45%"],
]

export const WHEN = [
  ["9%", "9%", "9%", "9%", "9%", "9%"],
  ["14%", "14%", "14%", "14%", "14%", "9%"],
]

export const SPENT = [
  ["5%", "5%", "5%", "5%", "5%", "5%"],
  ["6%", "6%", "6%", "6%", "5%", "5%"],
]

export const DEGREE = [
  ["4%", "4%", "4%", "4%", "3%", "3%"],
  ["5%", "5%", "5%", "5%", "3%", "3%"],
]

export const DEADLINE = [
  ["3%", "3%", "3%", "2%", "2%", "2%"],
  ["3%", "3%", "3%", "3%", "2%", "2%"],
]

export const FORECAST = [
  ["3%", "3%", "3%", "2%", "2%", "2%"],
  ["3%", "3%", "3%", "3%", "2%", "2%"],
]

export const QUALITY = [
  ["3%", "3%", "3%", "2%", "2%", "2%"],
  ["3%", "3%", "3%", "3%", "2%", "2%"],
]

export const COMMENT = [
  ["20%", "20%", "20%", "19%", "21%", "22%"],
  ["0%", "0%", "0%", "0%", "0%", "0%"],
]

export const PRECS = [
  ["4%", "4%", "6%", "6%", "6%", "6%"],
  ["0%", "0%", "0%", "0%", "0%", "0%"],
]

// const n = 2
// const slim = 1
// const widthTotal =
//   parseFloat(MARK[slim][n]) +
//   parseFloat(POSITION[slim][n]) +
//   parseFloat(WHO[slim][n]) +
//   parseFloat(WHAT[slim][n]) +
//   parseFloat(WHEN[slim][n]) +
//   parseFloat(WHO[slim][n]) +
//   parseFloat(DEGREE[slim][n]) +
//   parseFloat(DEADLINE[slim][n]) +
//   parseFloat(FORECAST[slim][n]) +
//   parseFloat(QUALITY[slim][n]) +
//   parseFloat(COMMENT[slim][n]) +
//   parseFloat(PRECS[slim][n])

// console.log(JSON.stringify({ widthTotal }))

// should be <100% otherwise there is nothing
// left for the <Spacer />
