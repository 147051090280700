import React from "react"
import { Box, Tooltip } from "@chakra-ui/react"
import { Span } from "../../componentsWithChakra"
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipChart,
  ResponsiveContainer,
} from "recharts"
import COLORS from "../../theme/colors"
import CustomizedTick from "./CustomizedTick"

const ProgressChartDOC = ({ chartsData, params, ...rest }) => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Box {...rest}>
      <Box textAlign="center" style={{ position: "relative", zIndex: 1 }}>
        <Tooltip
          label="Degree of Completion (DOC). The Time is linearized from the beginning 0% to the end of the project 100% and shown in blue. Dark pink are the Actual values. If the dark pink line is over (under) the blue line the project is ahead of (behind) time in terms of completion."
          placement="top-start"
          hasArrow
        >
          <span>
            Degree of Completion % (<Span color={COLORS.blue[800]}>Time</Span>
            {" & "}
            <Span color={COLORS.pink[500]}>Actual</Span>)
          </span>
        </Tooltip>
      </Box>
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <ComposedChart
          width={500}
          height={300}
          data={chartsData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 35,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="category"
            dataKey="dateMMdd"
            interval={"personsStartEnd"}
            tick={<CustomizedTick />}
          />
          <YAxis allowDecimals={false} />
          <TooltipChart />
          <Line
            type="linear"
            dataKey="docBudget"
            name="100% Time Linearized"
            stroke={COLORS.blue[700]}
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="linear"
            dataKey="docActual"
            name="Degree of Completion"
            stroke={COLORS.pink[400]}
            strokeWidth={2}
            dot={{ r: 0 }}
          />
          <Line
            type="linear"
            dataKey="docActualStatus"
            name="Degree of Completion"
            stroke={COLORS.pink[400]}
            strokeWidth={2}
            dot={{ r: 2 }}
            activeDot={{ r: 8 }}
          />
          <Bar dataKey="dummy" fill={COLORS.white} barSize={0} stackId="a" />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default ProgressChartDOC
