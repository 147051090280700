import { chakra } from "@chakra-ui/react"

const LabelCheckbox = chakra("label", {
  // attach style rest
  baseStyle: {
    ml: 2,
    color: "grey.600",
  },
})

export default LabelCheckbox
