/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Calculates linearized rounded values between a
 * start value and an end value over an array with length.
 * If dropNStart is true then the nStart will not be element 0.
 * This is good for concatenating linearized data.
 * @param {number} nStart
 * @param {number} nEnd
 * @param {int} length
 * @param {bool} dropNStart
 * @returns {array} linearized
 */
export function linearize(nStart, nEnd, length, dropNStart = false) {
  let linearized = [Math.round(nStart)]

  if (length > 1) {
    linearized = Array(length).fill(0)
    const diff = nEnd - nStart
    const step = diff / (length - 1)
    linearized = linearized.map((value, index) =>
      Math.round(nStart + index * step)
    )
  }

  if (dropNStart) {
    linearized.shift()
  }

  return linearized
}
