import addHours from "date-fns/addHours"
import addBusinessDays from "date-fns/addBusinessDays"
import subBusinessDays from "date-fns/subBusinessDays"
import isEqual from "date-fns/isEqual"
import getHours from "date-fns/getHours"
import setHours from "date-fns/setHours"
import parseISO from "date-fns/parseISO"
import { durStrToDur } from "./durations"

import { startOfBusinessDay, endOfBusinessDay } from "./bordersOfBusinessDay"
import { DAY_END, DAY_START } from "../../const/globals"

/**
 * v1.0.0: (c) Jasper Anders
 * v1.0.1: (c) Prof. Dr. Ulrich Anders
 *
 * Adds a duration with business days and hours to a given date.
 * Returns the new business date.
 * @param {Date} date
 * @param {number} hours (business) to add to the provided date
 * @returns {Date} businessDateAdded
 */
export function dateDurationAdd(
  date,
  { days, hours },
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  const businessDayAdded = addBusinessDays(date, days)
  let businessDateAdded = businessDayAdded

  if (getHours(businessDayAdded) + hours > businessDayEnd) {
    businessDateAdded = addBusinessDays(businessDateAdded, 1)
    businessDateAdded = setHours(businessDateAdded, businessDayStart)
    businessDateAdded = addHours(
      businessDateAdded,
      hours - (businessDayEnd - getHours(date))
    )
  } else if (getHours(businessDayAdded) + hours < businessDayStart) {
    businessDateAdded = setHours(businessDateAdded, businessDayStart)
    businessDateAdded = addHours(
      businessDateAdded,
      hours - (getHours(date) - businessDayStart)
    )
  } else {
    businessDateAdded = addHours(businessDayAdded, hours)
  }

  // if resulting date is start of day set to end of day from last business day.
  if (
    isEqual(
      businessDateAdded,
      startOfBusinessDay(businessDateAdded, businessDayStart)
    )
  ) {
    businessDateAdded = endOfBusinessDay(
      subBusinessDays(businessDateAdded, 1),
      businessDayEnd
    )
  }

  return businessDateAdded
}

export function dateISODurStrAddISO(
  dateISO,
  durStr,
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  const date = parseISO(dateISO)
  const duration = durStrToDur(durStr)

  const businessDateAdded = dateDurationAdd(
    date,
    duration,
    businessDayStart,
    businessDayEnd
  )

  return businessDateAdded.toISOString()
}
