import { ROOT } from "../../const/globals"
import cloneDeep from "lodash.clonedeep"

/**
 * v1.2.0: (c) Prof. Dr. Ulrich Anders
 *
 * Checks if there is a circular dependency.
 * Starting with a nId each precedent and precedent's precedent is
 * recursively put on a list of precedents.
 * If the latest precedent is already on the list
 * then there is a circular reference.
 * Example: List of precedents
 * ["abcd"] → ["abcd", "bcde"] →  ["abcd", "bcde", "cdef"]
 * →  ["abcd", "bcde", "cdef", "abcd"] => isCircular=true
 * @param {object} nodes
 * @param {string} nId
 * @param {array} nIdsSeenAlready
 * @param {array} nIdsPosSeenAlready
 * @returns {bool} isCircular
 */
export function nodesTreeDepsCircularCheck(
  nodes,
  nId = ROOT,
  nIdsSeenAlready = []
) {
  let isCircular = false

  if (nIdsSeenAlready.indexOf(nId) > -1) {
    return true
  } else {
    nIdsSeenAlready = nIdsSeenAlready.concat(nId)

    nodes[nId].precedents.forEach((precedent) => {
      // console.log({ nId, precedent })
      isCircular =
        isCircular ||
        nodesTreeDepsCircularCheck(nodes, precedent, nIdsSeenAlready)
    })
  }
  return isCircular
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Checks all precedents if there is a circular reference.
 * It is necessary to check all precedents, since every new precedent
 * is pushed down to all descendants and to all ancestors of a node.
 * Even though the precedent of the node does not cause a circular reference,
 * the new ancestors or descendants may cause a circular reference.
 * TODO: Test if this is very time consuming in larger trees.
 * @param {object} nodes
 * @param {array} rows
 * @returns {boolean} isCircular
 */
export function nodesTreeDepsCircularCheckAll(nodes, rows) {
  const nodesNew = cloneDeep(nodes)
  let precs = {}
  // 1. init
  Object.keys(nodesNew).forEach((nId) => {
    precs[nId] = nodesNew[nId].precedents
  })
  let isCircular = false

  rows.forEach((nId) => {
    // 2. if a node is dependent on a parent
    // it is also dependent on all the parents children
    precs[nId].forEach(
      (precId) =>
        (precs[nId] = precs[nId].concat(nodesDescendantsGet(nodes, precId)))
    )
    // clean duplicates
    precs[nId] = [...new Set(precs[nId])]
  })

  // 3.1. recursively push down all precedents from parent to children
  // 3.2. recursively pull up all precedents from children to parent
  precsFill(nodesNew, precs, ROOT)

  // https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
  // 4. clean duplicates
  for (let nId in precs) {
    precs[nId] = [...new Set(precs[nId])]
  }

  // 5. remove precs that are itself, i.e. self-reference
  for (let nId in precs) {
    let index = precs[nId].indexOf(nId)
    if (index > -1) {
      precs[nId].splice(index, 1)
    }
  }

  // 6. check all nIds if there is a circular reference in any of its precedents
  for (let nId in precs) {
    let isCircularEval = precs[nId].map((precId) =>
      nodesTreeDepsCircularCheck(nodes, precId, [nId])
    )
    // console.log({ nId, isCircularEval })
    isCircular = isCircular || isCircularEval.some((el) => el)
  }

  return isCircular
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Fills the precs object recursively by
 * 1. pushing down precedents from parent to children
 * 2. pulling up precedents from children to parent
 * Returns nothing.
 * @param {object} nodes
 * @param {object} precs
 * @param {string} nId
 */
const precsFill = (nodes, precs, nId = ROOT) => {
  // console.log("precsFill", { nodes, precs, nId })
  nodes[nId].children.forEach((chId) => {
    // console.log("precsFill", { nId, chId })
    // push down precedents
    precs[chId] = [...new Set(precs[chId].concat(precs[nId]))]
    precsFill(nodes, precs, chId)
  })
  // pull up precedents
  if (nId !== ROOT) {
    const paId = nodes[nId].paId
    if (paId !== ROOT) {
      precs[paId] = [...new Set(precs[paId].concat(precs[nId]))]
    }
  }
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Gets all descendants (children and their children) from a node
 * @param {object} nodes
 * @param {string} nId
 * @returns {array} nIds
 */
function nodesDescendantsGet(nodes, nId) {
  let children = nodes[nId].children

  nodes[nId].children.forEach((chId) => {
    children = children.concat(nodesDescendantsGet(nodes, chId))
  })
  return children
}
