import React, { useRef, useEffect } from "react"
import { useForm, useFormState } from "react-hook-form"
import {
  Flex,
  Box,
  Text,
  Input,
  Spacer,
  chakra,
  Tooltip,
  HStack,
} from "@chakra-ui/react"
import { ButtonIcon, ButtonText, Span } from "../../componentsWithChakra"
import {
  BiCaretRight,
  BiCaretDown,
  BiArrowToRight,
  BiArrowToBottom,
} from "react-icons/bi"
import { IoHourglassOutline } from "react-icons/io5"

import TrafficLight from "../TrafficLight"
import SwitchRound from "../SwitchRound"
import SelectPerson from "../SelectPerson"

import * as WIDTH from "../../const/widths"

import {
  nodeActiveUpdate,
  rowUpdate,
  rowFold,
  rowHighlight,
  rowForecast,
  rowQuality,
  rowMark,
  isByWhenPinnedUpdate,
  // isFromWhenPinnedUpdate,
} from "../../redux/actions/project"
import { levelColorSet } from "../../functions/helpers/levelColorSet"
import { durToDurStr } from "../../functions/timeHandler/durations"
import { dateISODurStrAddISO } from "../../functions/timeHandler/dateDurationAdd"
import {
  tpAbbrD,
  tpAbbrHS,
  tpAbbrHE,
} from "../../functions/timeHandler/timePointAbbreviate"
import locToISO from "../../functions/timeHandler/locToISO"
import {
  ABBR_D_MESSAGE,
  ABBR_D_REGEX,
  ABBR_H_MESSAGE,
  ABBR_H_REGEX,
  PERCENT_MESSAGE,
  PERCENT_REGEX,
  DURATION_MESSAGE,
  DURATION_REGEX,
  NUMBER_MESSAGE,
  NUMBER_REGEX,
  PRECEDENTS_MESSAGE,
  PRECEDENTS_REGEX,
} from "../../const/messages"
import objDeepTrim from "../../functions/utils/objDeepTrim"

const SheetRow = ({
  row,
  node,
  isBottom = false,
  statusDateCurrent,
  isStatusLocked,
  params,
  isResetForm = false,
  dispatch,
  isExamOn,
  isDebugOn,
}) => {
  //=================================================================

  const inputRef = useRef(null)

  const {
    register,
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ delayError: 500 })

  const { dirtyFields } = useFormState({ control })

  // console.log({ dirtyFields: JSON.stringify(dirtyFields) })

  const {
    nId,
    paId,
    children,
    position,
    positionStr,
    wip,
    wipLimit,
    who,
    deliversWhat,
    fromWhen,
    toProjectionISO,
    byWhen,
    isByWhenPinned,
    toWhom,
    deadline,
    isDeadlineDue,
    //
    degree,
    isDelivered,
    forecast,
    quality,
    comment,
    isCommentRequired,
    precedents,
    precedentsStr,
    precedentsStrMore,
    dependents,
    //
    span,
    projection,
    slack,
    spent,
    spentStr,
    projectionStr,
    spanStr,
    slackStr,
    fromSlackISO,
    //
    isAggregate,
    isIgnored,
    isUnresolved,
    isActive,
    isFolded,
    isHighlighted,
    levelMax,
  } = node

  const { isDetailsOn, isSlimOn, isKanbanOn, dayEnd } = params
  const isSlimBin = isSlimOn ? 1 : 0

  const hasPosition = position.length > 0
  const level = position.length

  const isLocked = node.isLocked || isStatusLocked
  const hasChildren = children.length > 0

  // ----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset, node])

  // ----------------------------------------------------------------
  const dataRemoveUnchanged = (data) => {
    // console.log("dataRemoveUnchanged: ", { data })
    data.byWhen =
      data.byWhenH !== undefined && data.byWhenH !== ""
        ? data.byWhenD + "|" + data.byWhenH
        : data.byWhenD
    delete data.byWhenD
    delete data.byWhenH
    if (data.byWhen === undefined) {
      delete data.byWhen
    }

    for (const key in data) {
      let valueBefore = node[key]
      // console.log("dataRemoveUnchanged: ", {
      //   key,
      //   value: data[key],
      //   valueBefore,
      // })
      // !!valueBefore === true → valueBefore !== undefined && valueBefore !== ""
      if (key === "byWhen" && !!valueBefore) {
        // console.log("dataRemoveUnchanged byWhen")

        if (data[key] === "") {
          delete data[key]
        }
        // data[key] is a durStr
        else if (data[key][0] === "+") {
          // remove "+" and potential hour information after the |
          data[key] = data[key].substring(1).split("|")[0]

          // transform data[key] from durStr to ISO date
          data[key] = dateISODurStrAddISO(fromWhen, data[key])
        } else {
          data[key] = locToISO(data[key], statusDateCurrent)
        }

        if (data[key] === valueBefore) {
          delete data[key]
        }
      } else if (key === "spent" && !!valueBefore) {
        // console.log("dataRemoveUnchanged spent")
        valueBefore = durToDurStr(valueBefore)
        if (data[key] === "" || data[key] === valueBefore) {
          delete data[key]
        }
      } else if (key === "degree") {
        // degree is internally an int
        data[key] = parseInt(data[key])

        if (data[key] === valueBefore) {
          delete data[key]
        }
      } else if (key === "wipLimit") {
        // wipLimit is internally an int
        if (data[key] === "") {
          data[key] = 0
        } else {
          data[key] = parseInt(data[key])
        }

        if (data[key] === valueBefore) {
          delete data[key]
        }
      } else if (data[key] === valueBefore) {
        // console.log("dataRemoveUnchanged else: ", {
        //   key,
        //   valueBefore,
        //   dataKey: data[key],
        // })
        delete data[key]
      }
    }

    return data
  }
  // ----------------------------------------------------------------
  const onSubmit = (data) => {
    // moves to hidden button. Submit is because this causes an onBlur.
    inputRef.current.focus()
  }

  const onBlur = (data) => {
    // console.log("SheetRow 1: ", { ...data })

    objDeepTrim(data)

    // merge data.precedentsStr and data.precedentsStrMore
    if (data.precedentsStrMore !== undefined) {
      if (data.precedentsStrMore !== "") {
        data.precedentsStr = data.precedentsStr + ", " + data.precedentsStrMore
      }
      delete data.precedentsStrMore
    }
    // console.log("SheetRow 2: ", { ...data })

    const payload = { nId, data: dataRemoveUnchanged(data) }
    // console.log("SheetRow 3: ", { ...data })
    dispatch(rowUpdate(payload))
  }

  const onClickFocus = (e) => {
    if (!isActive) {
      const payload = { nId }
      dispatch(nodeActiveUpdate(payload))
    }
  }

  // ----------------------------------------------------------------

  const onClickFold = (event) => {
    const payload = { nId }
    dispatch(rowFold(payload))
  }

  const onClickHighlight = (event) => {
    const payload = { nId }
    dispatch(rowHighlight(payload))
  }

  // ----------------------------------------------------------------
  const onClickMark = (e) => {
    const payload = { nId }

    if (!e.shiftKey) {
      if (isUnresolved) {
        payload.isUnresolved = false
        payload.isIgnored = true
      } else if (isIgnored) {
        payload.isUnresolved = false
        payload.isIgnored = false
      } else {
        payload.isUnresolved = true
        payload.isIgnored = false
      }
    }
    // shift key
    else {
      if (isUnresolved) {
        payload.isUnresolved = false
        payload.isIgnored = false
      } else if (isIgnored) {
        payload.isUnresolved = true
        payload.isIgnored = false
      } else {
        payload.isUnresolved = false
        payload.isIgnored = true
      }
    }
    dispatch(rowMark(payload))
  }

  // ----------------------------------------------------------------
  const onClickForecast = (e) => {
    const isForward = e.shiftKey ? false : true
    const payload = { nId, isForward }
    console.log({ payload })
    dispatch(rowForecast(payload))
  }

  // ----------------------------------------------------------------
  const onClickQuality = (e) => {
    const isForward = e.shiftKey ? false : true
    const payload = { nId, isForward }
    dispatch(rowQuality(payload))
  }

  // ----------------------------------------------------------------
  const onClickByWhenPin = () => {
    const payload = { nId }
    dispatch(isByWhenPinnedUpdate(payload))
  }

  // ----------------------------------------------------------------

  let colorProp = {
    ...levelColorSet({
      prop: "color",
      level,
      levelMax,
      isIgnored,
      isUnresolved,
    }),
  }

  // =================================================================
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Box
        {...colorProp}
        bg={
          !isLocked && isActive && isHighlighted
            ? "pink.70"
            : !isLocked && isActive
            ? "pink.50"
            : !isLocked && isHighlighted
            ? row % 2
              ? "blue.50"
              : "blue.80"
            : row % 2
            ? "white"
            : "grey.100"
        }
        _hover={{
          bg:
            isActive && isHighlighted
              ? "pink.80"
              : isActive
              ? "pink.60"
              : isHighlighted
              ? row % 2
                ? "blue.70"
                : "blue.90"
              : "grey.200",
        }}
        fontFamily="Fira Sans Condensed"
        onClick={isLocked ? null : onClickFocus}
        cursor={isLocked ? "default" : "pointer"}
      >
        <Flex>
          <Box w={WIDTH.MARK[isSlimBin]} textAlign="center">
            {!isAggregate ? (
              <SwitchRound
                colorCode={isIgnored ? 2 : isUnresolved ? 1 : 0}
                onClick={onClickMark}
                isDisabled={isLocked}
              />
            ) : (
              <Box>&nbsp;</Box>
            )}
            {isDetailsOn && isDebugOn && (
              <Text fontSize="sm" fontWeight="normal">
                {row}
              </Text>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box
            w={WIDTH.POSITION[isSlimBin]}
            px={1}
            fontFamily="Inconsolata"
            fontWeight="700"
          >
            <Flex align="flex-start">
              {isAggregate ? (
                <ButtonIcon
                  onClick={onClickFold}
                  color={hasChildren ? "inherit" : "grey.500"}
                >
                  {isFolded ? <BiCaretRight /> : <BiCaretDown />}
                </ButtonIcon>
              ) : (
                <ButtonIcon visibility="hidden">
                  <BiCaretRight />
                </ButtonIcon>
              )}
              {hasPosition ? (
                <Flex
                  w="100%"
                  justify="space-between"
                  fontWeight="700"
                  overflowX="hidden"
                  align="baseline"
                >
                  <ButtonText
                    onClick={onClickHighlight}
                    color={
                      !isAggregate &&
                      (slackStr[0] === "-" ||
                        spanStr[0] === "-" ||
                        spanStr[0] === "0")
                        ? "pink.500"
                        : "inherit"
                    }
                  >
                    {positionStr}
                  </ButtonText>
                  {!isAggregate && isKanbanOn && (
                    <Text
                      fontSize="sm"
                      fontWeight="normal"
                      ml="16px"
                      textAlign="right"
                      pr={1}
                    >
                      #{nId.substring(0, 3)}
                    </Text>
                  )}
                </Flex>
              ) : (
                <Box fontWeight="700">PROJECT</Box>
              )}
            </Flex>
            {isDetailsOn && (!isKanbanOn || (isKanbanOn && isAggregate)) && (
              <Text
                fontSize="sm"
                fontWeight="normal"
                ml="16px"
                textAlign="right"
                pr={1}
              >
                #{nId.substring(0, 3)}
              </Text>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box
            w={WIDTH.WIP[isSlimBin]}
            fontFamily="Inconsolata"
            fontWeight="700"
            textAlign="right"
            px={1}
            opacity="85%"
          >
            {isAggregate && (
              <Flex
                justify="space-between"
                color={
                  wipLimit !== 0 && wip > wipLimit ? "pink.400" : "inherit"
                }
                fontWeight="700"
              >
                <Text w="16px" textAlign="right">
                  {wip !== 0 ? wip : ""}
                </Text>
                <Text color="grey.600">/</Text>
                <Tooltip
                  isOpen={errors?.wipLimit}
                  label={errors?.wipLimit ? errors?.wipLimit.message : ""}
                  placement="top-start"
                  hasArrow
                  bg="red.500"
                >
                  <Input
                    type="text"
                    {...register("wipLimit", {
                      pattern: {
                        value: NUMBER_REGEX,
                        message: NUMBER_MESSAGE,
                      },
                    })}
                    onBlur={dirtyFields?.wipLimit ? handleSubmit(onBlur) : null}
                    defaultValue={wipLimit !== 0 ? wipLimit : ""}
                    isDisabled={isLocked}
                    w="16px"
                    textAlign="right"
                  />
                </Tooltip>
              </Flex>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.WHO[isSlimBin]} px={1}>
            <SelectPerson
              wId={nId}
              name="who"
              value={who}
              isIgnored={isIgnored || isUnresolved}
              isLocked={isLocked}
            />
            {isDetailsOn && (
              <Flex
                justify="flex-end"
                fontSize="sm"
                opacity="80%"
                fontWeight="normal"
              >
                <Tooltip
                  label={
                    level === 0
                      ? "FROM: Starting date of project."
                      : "FROM: Earliest starting date of DELIVERS WHAT depending on parents and precedents. There is at least 1h gap to a precedent."
                  }
                  placement="bottom-start"
                  hasArrow
                >
                  <Text ml={3} mr={1}>
                    From:
                  </Text>
                </Tooltip>
                <Text w="75%" textAlign="right">
                  {tpAbbrD(fromWhen, statusDateCurrent)}
                </Text>
                <Text w="20px" textAlign="right">
                  {tpAbbrHS(fromWhen) !== "" && "|"}
                  {tpAbbrHS(fromWhen)}
                </Text>
              </Flex>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.WHAT[isSlimBin]} px={1}>
            <Input
              type="text"
              {...register("deliversWhat")}
              onBlur={dirtyFields?.deliversWhat ? handleSubmit(onBlur) : null}
              defaultValue={deliversWhat}
              isDisabled={isLocked}
            />
            {isDetailsOn && (
              <Flex
                justify="flex-start"
                fontSize="sm"
                opacity="80%"
                fontWeight="normal"
              >
                <Tooltip
                  label={
                    isAggregate
                      ? "PROJECTION: Aggregated Projection. Projection = Σ Projections."
                      : "PROJECTION: Linear extrapolation of SPENT. Projection = Spent / Degree."
                  }
                  placement="bottom-start"
                  hasArrow
                >
                  <Text ml={3} mr={1}>
                    Proj:
                  </Text>
                </Tooltip>
                <Text w="35%">{projectionStr}</Text>
                {!children.length && (
                  <Flex
                    justify="flex-start"
                    fontSize="sm"
                    opacity="80%"
                    fontWeight="normal"
                  >
                    <Tooltip
                      label="SLACK: Duration that is currently not needed. Slack = Span − Projection."
                      placement="bottom-start"
                      hasArrow
                    >
                      <Text ml={3} mr={1}>
                        Slack:{" "}
                        <Span
                          color={
                            !isAggregate && slackStr[0] === "-"
                              ? "pink.500"
                              : "inherit"
                          }
                        >
                          {slackStr}
                        </Span>
                      </Text>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.WHEN[isSlimBin]} pr={2}>
            <Flex justify="space-between">
              <ButtonIcon onClick={onClickByWhenPin} mb={2}>
                {isByWhenPinned ? <BiArrowToBottom /> : <BiArrowToRight />}
              </ButtonIcon>
              {!isAggregate || isByWhenPinned ? (
                <>
                  <Tooltip
                    isOpen={errors?.byWhenD}
                    label={errors?.byWhenD ? errors?.byWhenD.message : ""}
                    placement="top"
                    hasArrow
                    bg="red.500"
                  >
                    <Input
                      type="text"
                      {...register("byWhenD", {
                        pattern: {
                          value: ABBR_D_REGEX,
                          message: ABBR_D_MESSAGE,
                        },
                      })}
                      defaultValue={tpAbbrD(byWhen, statusDateCurrent)}
                      isDisabled={isLocked}
                      onBlur={
                        dirtyFields?.byWhenD ? handleSubmit(onBlur) : null
                      }
                      textAlign="right"
                      fontFamily="Inconsolata"
                      fontWeight="700"
                    />
                  </Tooltip>
                  {tpAbbrHE(byWhen, dayEnd) === "" ? (
                    <Box
                      display="inline-block"
                      minW="8px"
                      fontFamily="Inconsolata"
                      fontWeight="700"
                    ></Box>
                  ) : (
                    "|"
                  )}
                  <Tooltip
                    isOpen={errors?.byWhenH}
                    label={errors?.byWhenH ? errors?.byWhenH.message : ""}
                    placement="top"
                    hasArrow
                    bg="red.500"
                  >
                    <Input
                      flex="0 0 auto"
                      w="18px"
                      type="text"
                      {...register("byWhenH", {
                        pattern: {
                          value: ABBR_H_REGEX,
                          message: ABBR_H_MESSAGE,
                        },
                      })}
                      defaultValue={tpAbbrHE(byWhen, dayEnd)}
                      onBlur={
                        dirtyFields?.byWhenH ? handleSubmit(onBlur) : null
                      }
                      textAlign="right"
                      isDisabled={isLocked}
                      fontFamily="Inconsolata"
                      fontWeight="700"
                    />
                  </Tooltip>
                </>
              ) : (
                <Flex fontFamily="Inconsolata" fontWeight="700">
                  <Text textAlign="right">
                    {tpAbbrD(byWhen, statusDateCurrent)}
                  </Text>
                  <Text flex="0 0 auto" w="26px" textAlign="right">
                    {tpAbbrHE(byWhen, dayEnd) === "" ? "" : "|"}
                    {tpAbbrHE(byWhen, dayEnd)}
                  </Text>
                </Flex>
              )}
            </Flex>
            {isDetailsOn && (
              <Flex
                justify="flex-start"
                fontSize="sm"
                opacity="80%"
                fontWeight="normal"
              >
                <Tooltip
                  label="SPAN: Duration between BY WHEN and FROM. Span = By When − From."
                  placement="bottom-start"
                  hasArrow
                >
                  <Text mx={6} mr={1}>
                    Span:{" "}
                    <Span
                      color={
                        spanStr[0] === "-" || spanStr[0] === "0"
                          ? "pink.500"
                          : "inherit"
                      }
                    >
                      {spanStr}
                    </Span>
                  </Text>
                </Tooltip>
              </Flex>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.WHO[isSlimBin]} px={1}>
            <SelectPerson
              wId={nId}
              name="toWhom"
              value={toWhom}
              isIgnored={isIgnored || isUnresolved}
              isLocked={isLocked}
            />
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.SPENT[isSlimBin]} px={1} textAlign="right">
            {isAggregate ? (
              <Text>{spentStr}</Text>
            ) : (
              <Tooltip
                isOpen={errors?.spent}
                label={errors?.spent ? errors?.spent.message : ""}
                placement="top"
                hasArrow
                bg="red.500"
              >
                <Input
                  type="text"
                  {...register("spent", {
                    pattern: {
                      value: DURATION_REGEX,
                      message: DURATION_MESSAGE,
                    },
                  })}
                  defaultValue={spentStr}
                  isDisabled={isLocked}
                  onBlur={dirtyFields?.spent ? handleSubmit(onBlur) : null}
                  textAlign="right"
                />
              </Tooltip>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box
            w={WIDTH.DEGREE[isSlimBin]}
            px={1}
            textAlign="right"
            fontFamily="Inconsolata"
            fontWeight="700"
          >
            {isAggregate ? (
              !isExamOn ? (
                <Text>{degree.toString()}</Text>
              ) : (
                <Text
                  h="24px"
                  w="100%"
                  border={isExamOn ? "2px solid goldenrod" : "none"}
                ></Text>
              )
            ) : (
              <Tooltip
                isOpen={errors?.degree}
                label={errors?.degree ? errors.degree.message : ""}
                placement="top"
                hasArrow
                bg="red.500"
              >
                <Input
                  type="number"
                  {...register("degree", {
                    min: { value: 0, message: "Must be >0." },
                    pattern: {
                      value: PERCENT_REGEX,
                      message: PERCENT_MESSAGE,
                    },
                  })}
                  defaultValue={degree.toString()}
                  isDisabled={isLocked}
                  onBlur={dirtyFields?.degree ? handleSubmit(onBlur) : null}
                  textAlign="right"
                />
              </Tooltip>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.DEADLINE[isSlimBin]} textAlign="center">
            {isAggregate ? (
              deadline !== 0 && (
                <TrafficLight
                  isExamOn={isExamOn}
                  colorCode={deadline}
                  isIgnored={isIgnored || isUnresolved}
                  isDisabled={isLocked}
                />
              )
            ) : isDeadlineDue || degree === 100 ? (
              <TrafficLight
                isExamOn={isExamOn}
                colorCode={deadline}
                isIgnored={isIgnored || isUnresolved}
                isDisabled={isLocked}
              />
            ) : (
              wip === 1 && (
                <Box
                  mx="auto"
                  position="relative"
                  top="-1px"
                  w="16px"
                  color="grey.500"
                >
                  <IoHourglassOutline />
                </Box>
              )
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.FORECAST[isSlimBin]} textAlign="center">
            {(!isAggregate && !isDeadlineDue && degree < 100) ||
            (isAggregate && forecast !== 0) ? (
              !isAggregate ? (
                <TrafficLight
                  isExamOn={isExamOn}
                  colorCode={forecast}
                  onClick={onClickForecast}
                  isIgnored={isIgnored || isUnresolved}
                  isDisabled={isLocked}
                />
              ) : (
                <TrafficLight
                  isExamOn={isExamOn}
                  colorCode={forecast}
                  isIgnored={isIgnored || isUnresolved}
                  isDisabled={isLocked}
                />
              )
            ) : null}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.QUALITY[isSlimBin]} textAlign="center">
            {(!isAggregate && isDelivered) || (isAggregate && quality !== 0) ? (
              !isAggregate ? (
                <TrafficLight
                  isExamOn={isExamOn}
                  colorCode={quality}
                  onClick={onClickQuality}
                  isIgnored={isIgnored || isUnresolved}
                  isDisabled={isLocked}
                />
              ) : (
                <TrafficLight
                  isExamOn={isExamOn}
                  colorCode={quality}
                  isIgnored={isIgnored || isUnresolved}
                  isDisabled={isLocked}
                />
              )
            ) : null}
          </Box>

          {!isSlimOn && <Spacer bg="transparent" />}

          {!isSlimOn && (
            <Box w={WIDTH.COMMENT[isSlimBin]} px={1}>
              <Tooltip
                isOpen={isCommentRequired || errors?.comment}
                label={
                  isCommentRequired & (comment.length < 3)
                    ? "Comment required for colors other than green."
                    : errors?.comment
                    ? errors?.comment.message
                    : ""
                }
                placement="top-start"
                hasArrow
                bg="red.500"
              >
                <Input
                  type="text"
                  {...register("comment", {
                    required:
                      isCommentRequired &&
                      "Comment required for colors other than green.",
                  })}
                  defaultValue={comment}
                  isDisabled={isLocked}
                  onBlur={dirtyFields?.comment ? handleSubmit(onBlur) : null}
                />
              </Tooltip>

              {isDetailsOn && level > 0 && (
                <Flex
                  justify="flex-end"
                  fontSize="sm"
                  opacity="80%"
                  fontWeight="normal"
                >
                  <Tooltip
                    label="MORE PRECEDENTS: Precedents that did not fit the line above."
                    placement="bottom-start"
                    hasArrow
                  >
                    <Text ml={3} mr={1} flex="0 0 auto">
                      {isKanbanOn ? "More Blocks:" : "More Precs:"}
                    </Text>
                  </Tooltip>

                  <Input
                    type="text"
                    {...register("precedentsStrMore", {
                      pattern: {
                        value: PRECEDENTS_REGEX,
                        message: PRECEDENTS_MESSAGE,
                      },
                    })}
                    defaultValue={precedentsStrMore}
                    onBlur={
                      dirtyFields?.precedentsStrMore
                        ? handleSubmit(onBlur)
                        : null
                    }
                    isDisabled={isLocked}
                  />
                </Flex>
              )}
            </Box>
          )}

          {!isSlimOn && <Spacer bg="transparent" />}

          {!isSlimOn && (
            <Box w={WIDTH.PRECS[isSlimBin]} px={1}>
              {level > 0 && (
                <Tooltip
                  isOpen={errors?.precedentsStr}
                  label={
                    errors?.precedentsStr ? errors.precedentsStr.message : ""
                  }
                  placement="top-end"
                  hasArrow
                  bg="red.500"
                >
                  <Input
                    type="text"
                    {...register("precedentsStr", {
                      pattern: {
                        value: PRECEDENTS_REGEX,
                        message: PRECEDENTS_MESSAGE,
                      },
                    })}
                    defaultValue={precedentsStr}
                    isDisabled={isLocked}
                    onBlur={handleSubmit(onBlur)}
                  />
                </Tooltip>
              )}
            </Box>
          )}

          {!isLocked && <chakra.button w="0" ref={inputRef}></chakra.button>}
        </Flex>
        {isSlimOn && (
          <Flex>
            <Flex color="grey.600" fontSize="sm" w="75%" mr={4} ml={12}>
              <Text textAlign="right" fontSize="sm" color="blue.600" mr={2}>
                COMMENT:
              </Text>
              <Input
                // textAlign="right"
                type="text"
                {...register("comment")}
                defaultValue={comment}
                onBlur={dirtyFields?.comment ? handleSubmit(onBlur) : null}
              />
            </Flex>
            <Flex color="grey.600" fontSize="sm" w="25%">
              <Text textAlign="right" fontSize="sm" color="blue.600" mr={2}>
                PRECS:
              </Text>
              <Tooltip
                isOpen={errors?.precedentsStr}
                label={
                  errors?.precedentsStr ? errors?.precedentsStr.message : ""
                }
                placement="top"
                hasArrow
                bg="red.500"
              >
                <Input
                  type="text"
                  {...register("precedentsStr", {
                    pattern: {
                      value: PRECEDENTS_REGEX,
                      message: PRECEDENTS_MESSAGE,
                    },
                  })}
                  defaultValue={precedentsStr}
                  isDisabled={isLocked}
                  onBlur={
                    dirtyFields?.precedentsStr ? handleSubmit(onBlur) : null
                  }
                />
              </Tooltip>
            </Flex>
          </Flex>
        )}
        {isDebugOn && (
          <HStack
            color="grey.600"
            fontWeight="normal"
            fontSize="sm"
            ml="184px"
            mr={0}
            mb={2}
            spacing={4}
          >
            <Text>fromWhen:</Text>
            <Text>{fromWhen.replace(":00:00.000Z", "")}</Text>
            <Text>byProj:</Text>
            <Text>{toProjectionISO.replace(":00:00.000Z", "")}</Text>
            <Text>fromSlack:</Text>
            <Text>{fromSlackISO.replace(":00:00.000Z", "")}</Text>
            <Text>byWhen: </Text>
            <Text>{byWhen.replace(":00:00.000Z", "")}</Text>
            <Text>span: </Text>
            <Text>{span.days + ", " + span.hours}</Text>
            <Text>proj: </Text>
            <Text>{projection.days + ", " + projection.hours}</Text>
            <Text>slack: </Text>
            <Text>{slack.days + ", " + slack.hours}</Text>
            <Text>spent: </Text>
            <Text>{spent.days + ", " + spent.hours}</Text>
            <Text>paId: </Text>
            <Text>{paId}</Text>
            <Text>children: </Text>
            <Text>{children.join(", ")}</Text>
            <Text>precedents: </Text>
            <Text>{precedents.join(", ")}</Text>
            <Text>dependents: </Text>
            <Text>{dependents.join(", ")}</Text>
          </HStack>
        )}
      </Box>
    </form>
  )
}

export default SheetRow
