import React from "react"
import { connect } from "react-redux"
import { Box } from "@chakra-ui/react"
import timeLineGen from "../../functions/timeHandler/timeLineGen"
import SimpleBar from "simplebar-react"
import getNodesExtraInfos from "../../redux/reselect/getNodesExtraInfos"
import { ROOT } from "../../const/globals"
import GanttTimeLine from "../GanttTimeLine"
import GanttBars from "../GanttBars"
import max from "date-fns/max"
import parseISO from "date-fns/parseISO"

const GanttChart = ({
  projectCurrent,
  nodesExtraInfos,
  settings,
  isResetForm = false,
  statusDatesList,
  statusDateCurrent,
  dispatch,
}) => {
  //=================================================================

  const { persons } = projectCurrent
  const { ganttDurationIndex, boxW } = settings
  const isViewWeek = ganttDurationIndex === 1

  const dateEndDate = max([
    parseISO(projectCurrent.nodes[ROOT].byWhen),
    parseISO(statusDatesList[0]),
  ])

  const dateStartISO = projectCurrent.nodes[ROOT].fromWhen
  const dateEndISO = dateEndDate.toISOString()

  //-----------------------------------------------------------------
  const { timeLine, weekStartISO } = timeLineGen(
    dateStartISO,
    dateEndISO,
    isViewWeek
  )

  //=================================================================
  return (
    <>
      <SimpleBar>
        <Box mb={4}>
          <GanttTimeLine
            timeLine={timeLine}
            statusDatesList={statusDatesList}
            statusDateCurrent={statusDateCurrent}
            dateStart={isViewWeek ? weekStartISO : dateStartISO}
            dateEnd={dateEndISO}
            ganttDurationIndex={ganttDurationIndex}
            boxW={boxW}
          />
          <GanttBars
            persons={persons}
            timeLine={timeLine}
            projectCurrent={projectCurrent}
            nodesExtraInfos={nodesExtraInfos}
            ganttDurationIndex={ganttDurationIndex}
            boxW={boxW}
            dispatch={dispatch}
          />
        </Box>
      </SimpleBar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    projectCurrent: state.project.statuses[state.project.statusDateCurrent],
    statusDatesList: state.project.statusDatesList,
    statusDateCurrent: state.project.statusDateCurrent,
    nodesExtraInfos: getNodesExtraInfos(state),
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(GanttChart)
