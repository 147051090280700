import React from "react"
import { Box, Tooltip } from "@chakra-ui/react"
import { Span } from "../../componentsWithChakra"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipChart,
  ResponsiveContainer,
} from "recharts"
import CustomizedTick from "./CustomizedTick"
import COLORS from "../../theme/colors"

const ProgressChartDeliverables = ({ chartsData, params, ...rest }) => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Box {...rest}>
      <Box textAlign="center" style={{ position: "relative", zIndex: 1 }}>
        <Tooltip
          label="Deliverables in pieces (Π). DONE deliverables are in dark blue, WIP (Work In Progress) deliverables in medium blue, and NYS (Not Yet Started) in light blue. Aggregated, unresolved or ignored deliverables are not considered and, therefore, not counted."
          placement="top-start"
          hasArrow
        >
          <span>
            Non-Aggregated Deliverables Π (
            <Span color={COLORS.blue[800]}>DONE</Span>
            {", "}
            <Span color={COLORS.blue[500]}>WIP</Span>
            {" & "}
            <Span color={COLORS.blue[200]}>NYS</Span>)
          </span>
        </Tooltip>
      </Box>
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <BarChart
          width={500}
          height={300}
          data={chartsData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 35,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="category"
            interval={"personsStartEnd"}
            dataKey="dateMMdd"
            tick={<CustomizedTick />}
          />
          <YAxis allowDecimals={false} />
          <TooltipChart />
          <Bar
            dataKey="deliverablesDone"
            name="Deliverables DONE"
            fill={COLORS.blue[800]}
            barSize={10}
            stackId="a"
          />
          <Bar
            dataKey="deliverablesWIP"
            name="Deliverables WIP"
            fill={COLORS.blue[500]}
            barSize={10}
            stackId="a"
          />
          <Bar
            dataKey="deliverablesNYS"
            name="Deliverables NYS"
            fill={COLORS.blue[200]}
            barSize={10}
            stackId="a"
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default ProgressChartDeliverables
