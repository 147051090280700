import React from "react"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"

const InlineBlock = ({ children, ...rest }) => (
  <chakra.span
    display="inline-block"
    flex="0 0 auto"
    whiteSpace="nowrap"
    {...rest}
  >
    {children}
  </chakra.span>
)

InlineBlock.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.object,
}

export default InlineBlock
