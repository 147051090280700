import React from "react"
import { Box, Tooltip } from "@chakra-ui/react"
import {
  ComposedChart,
  Bar,
  Line,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipChart,
  ResponsiveContainer,
} from "recharts"
import CustomizedTick from "./CustomizedTick"
import { colorHex } from "../../functions/eval/colorCodeCalc"
import COLORS from "../../theme/colors"

const ProgressChartTeamSatisfaction = ({ chartsData, params, ...rest }) => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Box {...rest}>
      <Box textAlign="center" style={{ position: "relative", zIndex: 1 }}>
        <Tooltip
          label="Left Bar: Customer Satisfaction in percent. Right Bar: Team Satisfaction in percent. Both bars are displayed in colors as defined on the Setup page. Maximum is 100%."
          placement="top-end"
          hasArrow
        >
          <span>Customer & Team Satisfaction %</span>
        </Tooltip>
      </Box>
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <ComposedChart
          width={500}
          height={300}
          data={chartsData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 35,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="category"
            dataKey="dateMMdd"
            interval={"personsStartEnd"}
            tick={<CustomizedTick />}
          />
          <YAxis allowDecimals={false} />
          <TooltipChart />
          <Line
            type="linear"
            dataKey="satisfactionBudget"
            name="Satisfaction Max"
            stroke={COLORS.blue[700]}
            strokeWidth={2}
            dot={false}
          />
          <Bar
            dataKey="custSatisfactionActualStatus"
            name="Customer Satisfaction Status"
            barSize={10}
          >
            {chartsData.map((entry, index) => (
              <Cell
                key={index}
                fill={colorHex(
                  entry.custSatisfactionActualStatus,
                  params.pSatisfactionCustomer
                )}
              />
            ))}
          </Bar>
          <Bar
            dataKey="teamSatisfactionActualStatus"
            name="Team Satisfaction Status"
            barSize={10}
          >
            {chartsData.map((entry, index) => (
              <Cell
                key={index}
                fill={colorHex(
                  entry.teamSatisfactionActualStatus,
                  params.pSatisfactionTeam
                )}
              />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default ProgressChartTeamSatisfaction
