import React from "react"
import { Box, Tooltip } from "@chakra-ui/react"
import { Span } from "../../componentsWithChakra"
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipChart,
  ResponsiveContainer,
} from "recharts"
import CustomizedTick from "./CustomizedTick"
import COLORS from "../../theme/colors"

const ProgressChartExpenses = ({ chartsData, params, ...rest }) => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Box {...rest}>
      <Box textAlign="center" style={{ position: "relative", zIndex: 1 }}>
        <Tooltip
          label="Expenses in T€. The expenses budget is linearized over time and shown in blue. The Actual values are in dark pink. If the dark pink line is over (under) the blue line the project is more expensive (cheaper) then budgeted."
          placement="top-start"
          hasArrow
        >
          <span>
            Expenses T€ (<Span color={COLORS.blue[800]}>Budget</Span>
            {" & "}
            <Span color={COLORS.pink[500]}>Actual</Span>)
          </span>
        </Tooltip>
      </Box>
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <ComposedChart
          width={500}
          height={300}
          data={chartsData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 35,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="category"
            dataKey="dateMMdd"
            interval={"preserveStartEnd"}
            tick={<CustomizedTick />}
          />
          <YAxis allowDecimals={false} />
          <TooltipChart />
          <Line
            type="linear"
            dataKey="expensesBudget"
            name="Expenses Budget"
            stroke={COLORS.blue[700]}
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="linear"
            dataKey="expensesActual"
            name="Expenses Actual"
            stroke={COLORS.pink[400]}
            strokeWidth={2}
            dot={{ r: 0 }}
          />
          <Line
            type="linear"
            dataKey="expensesActualStatus"
            name="Expenses Actual"
            stroke={COLORS.pink[400]}
            strokeWidth={2}
            dot={{ r: 2 }}
            activeDot={{ r: 8 }}
          />
          <Bar dataKey="dummy" fill={COLORS.white} barSize={0} stackId="a" />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default ProgressChartExpenses
