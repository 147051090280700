const translations = {
  questions: {
    "01": "Guiding Documents",
    "02": "Project Skills and HR Development",
    "03": "Project Selection",
    "04": "Project Embedding",
    "05": "Project Setup",
    "06": "Core Project Work",
    "07": "Project Completion",
    "08": "Project Tools",
    "09": "Project Control",
    10: "Project Context",
    "01.01":
      "Does your company have a guiding document stating what is expected as minimum output requirement of project management and project control?",
    "01.02":
      "Has your company defined with what general scheme projects are evaluated in terms of progress, deliverables and their quality?",
    "01.03":
      "Does your company have a general document that explains and defines project roles and responsibilities (incl. Project Manager/Owner, Project Sponsor, Product Owner, Agile Master, Team Lead, Steering Committee, Customer (Representative), Delegate for Change Mgt, Delegate for Risk Mgt, Delegate for Quality Mgt, Delegate for Knowledge Mgt)?",
    "01.04":
      "Does your company have a generally defined approach to embedding a new project in the organization that explains general rules and procedures and defines date plans for project control, reporting and progress monitoring?",
    "01.05":
      "Are the typical causes of project failure documented by your company and does your company structurally take action against them in order to promote project success?",
    "01.06":
      "Does your company have an identified owner of the guiding documents who updates them with the most recent procedures, knowledge and experiences and who performs maintenance and version control of the documents?",
    "02.01":
      "Does your company value successful project work in a comparable manner to successful work in a line function and does it prove this by comparable personnel developments, promotions or incentives?",
    "02.02":
      "Does your company have programs to systematically train project managers and project controllers so that methodological skills and practical experiences are available for challenging projects of the company?",
    "02.03":
      "Does your company offer your project team in the various roles coaching or mentoring where needs be?",
    "02.04":
      "Does your company provide structural possibilities for project team members to network between project and share experiences, best practices or solution approaches?",
    "02.05":
      "For persons involved in projects, does your company systematically offer: (a) external or internal training or workshop programs, (b) the certification of project team members for certain roles or (c) project team building events?",
    "03.01":
      "Does your company have a documented approach for project selection, that looks at the strategic advantage of projects, their necessity, their added value, their urgency, priority and feasibility?",
    "03.02":
      "Does your company perform an initial expense and time budget estimation based on the expected effort of the considered project?",
    "03.03":
      "Is your company properly assessing the investment, return on investment, payback time and risk of projects (e.g. with NPV, MIRR, ROI, etc)?",
    "03.04":
      "Does your company have a comprehensible and transparent mechanism by which projects are prioritized, selected or rejected (e.g. based on strategic fit, necessity value-added, urgency, priority, feasibility, risk, product lifetime)?",
    "03.05":
      "Does the senior and executive management have an overview report of the multi project portfolio of your company with status, progress, and resource usage so that they have a necessary basis to decide about new projects?",
    "04.01":
      "Does your company have a defined process to nominate the essential roles for each project, e.g. a Project Sponsor, Project Manager/Owner, a Project Controller, a Product Owner, or an Agile Master?",
    "04.02":
      "Does your company have an agreed procedure to identify the (line) managers with the largest stake in the project and to make them members of the steering committee in order to support the project or to remove obstacles?",
    "04.03":
      "Does your company generally give the project manager/owner the responsibility and trust to set up a project organization that is considered to be most promising for project success?",
    "04.04":
      "Does your company have a general practice to announce projects and their objectives as well as to communicate the nominated responsibles and persons involved?",
    "04.05":
      "Is there a mechanism in place that ensures that the project gets the needed priorities in comparison to the always urgent day-to-day work from the line functions?",
    "05.01":
      "Do the projects in your company typically begin with a detailed target  analysis and definition which is documented?",
    "05.02":
      "If the analysis shows that there are many open questions, does your company support (ideation, design thinking or similar types of) workshops for solution finding, specifications and the formulation of SMART objectives?",
    "05.03":
      "Are the SMART objectives then given to the Project Sponsor, Steering Committee or Senior Management for achieving proper and agreed project approvals and descriptions?",
    "05.04":
      "Does your company allow the project manager/owner to choose a project methodology, e.g. Waterfall, Scrum, Kanban, which is considered to be most successful for the given context?",
    "05.05":
      "Does your company have a comprehensible mechanism to staff the project with the necessary (internal and external) resources and does your company make sure that these resources are then dedicated to the project and not allocated to several project or line functions at the same time?",
    "05.06":
      "At the end of the definition, setup and resourcing phase, is there a systematic request from the project sponsor or the senior management that expense and time budgets must be refined in order to replace the initial estimates?",
    "06.01":
      "Does your company ensure that each project has a defined customer or customer representative and that the project has a defined focus on the customer in order to achieve customer satisfaction after project completion?",
    "06.02":
      "Does your company requests that the project has clear responsibilities for every deliverables and a defined ownership for each task?",
    "06.03":
      "Does your company ensure that every deliverable needs to have a recipient that also evaluates quality of the deliverable?",
    "06.04":
      "Does your company ensure that all plannings and re-plannings, agreements, commitments, done rules, milestones, deliverables and resources are properly documented and the latest version of the project plan is always accessible to the persons involved in the project?",
    "06.05":
      "Does your company ensure that the persons in the day-to-day project work are informed about the daily work status, e.g. by daily stand-ups or by finding information on a central information platform?",
    "07.01":
      "At the end of the project, does your company control that the provided project documentation is polished and that the necessary manuals exist in order to not lose the valuable knowledge acquired and generated during the project work phase?",
    "07.02":
      "At the end of a project the project manager or product owner hands over the project result to the new owner (typically in a line function). Does your company have a process to ensure that the new owner and recipient is defined before the project ends and actually has a chance to understand the necessary details of the project result before the project team dissolves?",
    "07.03":
      "If the project objective is to change a process, introduce a new systems, a new software, any other new resource or make any changes whatsoever to the organization, does your company have a defined process how to ensure that proper training and roll-out take place?",
    "07.04":
      "After the end of the project, does your company generally show its appreciation for the achievement and does it support some (adequate) form of celebration?",
    "07.05":
      "In order to not lose the valuable experience from the project implementation is there a systematic debriefing of the project participants after the end of the project to understand how the company project templates, procedures, methods, tools etc. could be improved ?",
    "07.06":
      "After the end of the project is there a department that takes over all the project documentation, control and progress reports for a later project review or understanding of accountabilities?",
    "08.01":
      "Does your company enforce a regular (e.g. bi-weekly) project control process in order to ensure that there is transparency about the project status. Is this project status distributed to all persons who are involved in the project and who need to know?",
    "08.02":
      "Does your company have a defined project control sheet (e.g. with a spreadsheet or within a software) that needs to be used as a standard by every project?",
    "08.03":
      "Does this project control sheet reveal the degree of completion of the project and does it compare the degree of completion to the degree of the expense budget spent and degree of time budget spent?",
    "08.04":
      "Because poor quality deliverables will later most certainly delay the project, does the project control sheet also reveal information on the quality of deliverables, e.g. in form of a sign-off from the recipient?",
    "08.05":
      "Has this control sheet been tested against a catalogue such as the 10 Commandments of Project control or any other list of criteria in order to ensure that it fulfils management and transparency needs and that it can trigger management action?",
    "08.06":
      "Does your company make sure that the control sheet lists all (internal and external) persons involved in the project with their roles?",
    "08.07":
      "In order to move a project forward, does the project control sheet address issues in the context of the project (such as Change Mgt, Quality Mgt, Risk Mgt or Knowledge Mgt) and does it contain necessary requests to Senior Management for support?",
    "08.08":
      "Does your company have a defined and common methodology to aggregate the status and progress of each project so that executive and senior management can review them and get an easy overview of all running projects?",
    "09.01":
      "Does your company provide state-of-the-art project management and planning tools to be used as standard and are these tools easily accessible for all persons in the project who need access?",
    "09.02":
      "Does your company provide a state-of-the-art project control tool to be used as a standard and is this tool easily accessible for all persons in the project who need access?",
    "09.03":
      "Does your company provide a state-of-the-art project communication tool as a standard and is this easily accessible for all persons in the project who need access?",
    "09.04":
      "Does your company provide databases, repositories or storages that help to manage project documentation and that allow for manageing access rights?",
    "09.05":
      "Does your company provide state-of-the-art collaboration tools that help to work on feasible ideas or problem solutions and are these tools easily accessible for all persons in the project who need access?",
    "09.06":
      "Does your company enforce versioning and version management for all types of content (software, hardware, firmware, documentation, manuals, images, concepts, etc.) generated in the project in order to have efficient content management and distribution?",
    "09.07":
      "Does your company have proper IT support for technical questions in conjunction with the before mentioned tools and databases?",
    "09.08":
      "Is there a an owner for each of these systems who ensures that shortages of above tools are remedied or that tools are replaced in order to increase project efficiency?",
    10.01:
      "Does your company ensure that the Steering Committee of a project is not only receiving reports but helping the project to overcome obstacles that result from its context.  of the project and transparently resolves conflict of objectives, priorities and resources?",
    10.02:
      "If projects are causing change to processes or the organization, does your company ensure that there is a particular focus on change management?",
    10.03:
      "Projects are often dependent on services, support or supply from outside the project. Does your company ensure that there is a sufficient emphasis on the quality that the project receives from its context?",
    10.04:
      "Projects may fail due to risks that result from the context of a project. Does your company ensure that significant risk issues from the context are remedied as best as possible from the management supporting the project?",
    10.05:
      "Does your company accompany the project with accountants knowledgeable of the project who make sure that the project does not get into foreseeable path dependency?",
  },
}

export default translations
