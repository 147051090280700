import { ROOT } from "../../const/globals"
import { createSelector } from "reselect"
import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import format from "date-fns/format"
import isBefore from "date-fns/isBefore"
import isAfter from "date-fns/isAfter"
import parseISO from "date-fns/parseISO"
import parseInt0 from "../../functions/utils/parseInt0"
import { linearize } from "../../functions/utils/linearize"
import { deliverablesCount } from "../../functions/utils/deliverablesCount"

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Gets project from state
 * @param {object} state
 * @returns {object} project
 */
const getProject = (state) => state.project

/**
 *
 * @param {*} project
 * @returns
 */
const getChartsData = createSelector([getProject], (project) => {
  const { statusDatesList, statusDateCurrent } = project

  // statusDatesLists has most recent status in [0]
  // so it must be reversed
  // Attention: reverse() mutates original array!!!
  const statusDatesListRev = [...statusDatesList].reverse()

  let chartsTravels = {}
  chartsTravels.length = 0

  chartsTravels.distStartsToStatuses = []
  chartsTravels.datesStatus = []
  chartsTravels.datesProjectStart = []
  chartsTravels.datesProjectEnd = []
  chartsTravels.daysStartsToEnds = []

  chartsTravels.docBudgets = []
  chartsTravels.docActuals = []

  chartsTravels.expensesBudgets = []
  chartsTravels.expensesActuals = []

  chartsTravels.countsDeliverablesLeaves = []
  chartsTravels.countsDeliverablesLeavesDone = []
  chartsTravels.countsDeliverablesLeavesQualityRed = []
  chartsTravels.countsDeliverablesLeavesQualityOrange = []
  chartsTravels.countsDeliverablesLeavesQualityYellow = []
  chartsTravels.countsDeliverablesLeavesNYS = []
  chartsTravels.countsDeliverablesLeavesWIP = []
  chartsTravels.countsDeliverablesLeavesUnresolved = []
  chartsTravels.countsDeliverablesLeavesIgnored = []

  chartsTravels.personsCore = []
  chartsTravels.personsContext = []

  chartsTravels.custSatisfactionActuals = []
  chartsTravels.teamSatisfactionActuals = []

  let status
  let statusDate

  // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
  // build arrays in chartsTravels up until statusDateCurrent
  do {
    // set for this iteration
    statusDate = statusDatesListRev[chartsTravels.length]
    status = project.statuses[statusDate]
    // count up iteration
    chartsTravels.length++

    // add to chartInfos arrays
    // --- dates
    chartsTravels.datesStatus.push(parseISO(statusDate))
    chartsTravels.datesProjectStart.push(
      parseISO(status.nodes[ROOT].fromWhenEarliest)
    )
    chartsTravels.datesProjectEnd.push(parseISO(status.nodes[ROOT].byWhen))

    // --- days

    chartsTravels.daysStartsToEnds.push(
      differenceInBusinessDays(
        chartsTravels.datesProjectEnd[chartsTravels.length - 1],
        chartsTravels.datesProjectStart[chartsTravels.length - 1]
      ) + 1
    )

    chartsTravels.distStartsToStatuses.push(
      differenceInBusinessDays(
        chartsTravels.datesStatus[chartsTravels.length - 1],
        chartsTravels.datesProjectStart[chartsTravels.length - 1]
      )
    )

    // --- degree of completion

    chartsTravels.docBudgets.push(100)
    chartsTravels.docActuals.push(status.nodes[ROOT].degree)

    // --- expenses
    chartsTravels.expensesBudgets.push(parseInt0(status.ratios.expensesBudget))
    chartsTravels.expensesActuals.push(parseInt0(status.ratios.expensesActual))

    // ---

    const {
      countDeliverablesLeaves,
      countDeliverablesLeavesWIP,
      countDeliverablesLeavesNYS,
      countDeliverablesLeavesDone,
      countDeliverablesLeavesQualityRed,
      countDeliverablesLeavesQualityOrange,
      countDeliverablesLeavesQualityYellow,
      countDeliverablesLeavesUnresolved,
      countDeliverablesLeavesIgnored,
      countDeliverablesPersonsInvolved,
    } = deliverablesCount(status)

    chartsTravels.countsDeliverablesLeaves.push(countDeliverablesLeaves)
    chartsTravels.countsDeliverablesLeavesWIP.push(countDeliverablesLeavesWIP)
    chartsTravels.countsDeliverablesLeavesNYS.push(countDeliverablesLeavesNYS)
    chartsTravels.countsDeliverablesLeavesDone.push(countDeliverablesLeavesDone)
    chartsTravels.countsDeliverablesLeavesQualityRed.push(
      countDeliverablesLeavesQualityRed
    )
    chartsTravels.countsDeliverablesLeavesQualityOrange.push(
      countDeliverablesLeavesQualityOrange
    )
    chartsTravels.countsDeliverablesLeavesQualityYellow.push(
      countDeliverablesLeavesQualityYellow
    )
    chartsTravels.countsDeliverablesLeavesUnresolved.push(
      countDeliverablesLeavesUnresolved
    )
    chartsTravels.countsDeliverablesLeavesIgnored.push(
      countDeliverablesLeavesIgnored
    )

    // --- persons
    const { persons } = status
    let personsAll = Object.keys(persons).filter(
      (person) =>
        persons[person].nameFirst !== "" || persons[person].nameLast !== ""
    )
    chartsTravels.personsCore.push(countDeliverablesPersonsInvolved)
    chartsTravels.personsContext.push(
      personsAll.length - countDeliverablesPersonsInvolved
    )

    // --- customer satisfaction
    chartsTravels.custSatisfactionActuals.push(
      parseInt0(status.ratios.satisfactionCustomer)
    )

    // --- team satisfaction
    chartsTravels.teamSatisfactionActuals.push(
      parseInt0(status.ratios.satisfactionTeam)
    )
  } while (statusDate !== statusDateCurrent)
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  const docBudget = linearize(
    0,
    chartsTravels.docBudgets[chartsTravels.length - 1],
    chartsTravels.daysStartsToEnds[chartsTravels.length - 1]
  )

  const expensesBudget = linearize(
    0,
    chartsTravels.expensesBudgets[chartsTravels.length - 1],
    chartsTravels.daysStartsToEnds[chartsTravels.length - 1]
  )

  // console.log({ chartsTravels })

  let chartsData = []
  for (let s = 0; s < chartsTravels.length; s++) {
    const i = chartsTravels.distStartsToStatuses[s]
    let dataPoint = {
      ix: i.toString(),
      dateMMdd: format(parseISO(statusDatesListRev[s]), "MM dd"),
      // degree of completion
      docBudget: docBudget[i],
      docActual: chartsTravels.docActuals[s],
      docActualStatus: chartsTravels.docActuals[s],
      // expenses
      expensesBudget: expensesBudget[i],
      expensesActual: chartsTravels.expensesActuals[s],
      expensesActualStatus: chartsTravels.expensesActuals[s],
      // deliverables
      deliverablesDone: chartsTravels.countsDeliverablesLeavesDone[s],
      deliverablesWIP: chartsTravels.countsDeliverablesLeavesWIP[s],
      deliverablesNYS: chartsTravels.countsDeliverablesLeavesNYS[s],
      deliverablesQualityRed:
        chartsTravels.countsDeliverablesLeavesQualityRed[s],
      deliverablesQualityOrange:
        chartsTravels.countsDeliverablesLeavesQualityOrange[s],
      deliverablesQualityYellow:
        chartsTravels.countsDeliverablesLeavesQualityYellow[s],
      deliverablesUnresolved:
        chartsTravels.countsDeliverablesLeavesUnresolved[s],
      // persons
      personsCore: chartsTravels.personsCore[s],
      personsContext: chartsTravels.personsContext[s],
      // customer satisfaction
      custSatisfactionBudget: 100,
      custSatisfactionActualStatus: chartsTravels.custSatisfactionActuals[s],
      // team satisfaction
      satisfactionBudget: 100,
      teamSatisfactionActualStatus: chartsTravels.teamSatisfactionActuals[s],
      // dummy
      dummy: undefined,
    }

    chartsData.push(dataPoint)
  }

  // add project start if before initial status date
  if (
    isBefore(
      chartsTravels.datesProjectStart[chartsTravels.length - 1],
      chartsTravels.datesStatus[0]
    )
  ) {
    let dataPoint = {
      ix: "0",
      dateMMdd: format(
        chartsTravels.datesProjectStart[chartsTravels.length - 1],
        "MM dd"
      ),
      // degree of completion
      docBudget: 0,
      docActual: 0,
      docActualStatus: undefined,
      // expenses
      expensesBudget: 0,
      expensesActual: 0,
      expensesActualStatus: undefined,
      // deliverables
      deliverablesDone: undefined,
      deliverablesWIP: undefined,
      deliverablesNYS: undefined,
      deliverablesQualityRed: undefined,
      deliverablesQualityOrange: undefined,
      deliverablesQualityYellow: undefined,
      deliverablesUnresolved: undefined,
      // persons
      personsCore: undefined,
      personsContext: undefined,
      // customer satisfaction
      custSatisfactionBudget: 100,
      custSatisfactionActualStatus: undefined,
      // team satisfaction
      satisfactionBudget: 100,
      teamSatisfactionActualStatus: undefined,
      // dummy
      dummy: undefined,
    }

    chartsData.unshift(dataPoint)
  }

  // add project end if after last status date
  if (
    isAfter(
      chartsTravels.datesProjectEnd[chartsTravels.length - 1],
      chartsTravels.datesStatus[chartsTravels.length - 1]
    )
  ) {
    let dataPoint = {
      ix: "0",
      dateMMdd: format(
        chartsTravels.datesProjectEnd[chartsTravels.length - 1],
        "MM dd"
      ),
      // degree of completion
      docBudget: 100,
      docActual: undefined,
      docActualStatus: undefined,
      // expenses
      expensesBudget: chartsTravels.expensesBudgets[chartsTravels.length - 1],
      expensesActual: undefined,
      expensesActualStatus: undefined,
      // deliverables
      deliverablesDone: undefined,
      deliverablesWIP: undefined,
      deliverablesNYS: undefined,
      deliverablesQualityRed: undefined,
      deliverablesQualityOrange: undefined,
      deliverablesQualityYellow: undefined,
      deliverablesUnresolved: undefined,
      // persons
      personsCore: undefined,
      personsContext: undefined,
      // customer satisfaction
      custSatisfactionBudget: 100,
      custSatisfactionActualStatus: undefined,
      // team satisfaction
      satisfactionBudget: 100,
      teamSatisfactionActualStatus: undefined,
      // dummy
      dummy: undefined,
    }

    chartsData.push(dataPoint)
  }

  // console.log({ chartsData })

  return chartsData
})

export default getChartsData
