import addBusinessDays from "date-fns/addBusinessDays"
import addHours from "date-fns/addHours"
import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import getHours from "date-fns/getHours"
import isBefore from "date-fns/isBefore"
import isWeekend from "date-fns/isWeekend"
import max from "date-fns/max"
import min from "date-fns/min"
import parseISO from "date-fns/parseISO"
import setHours from "date-fns/setHours"
import startOfDay from "date-fns/startOfDay"

import cloneDeep from "lodash.clonedeep"

import { dateDurationAdd } from "../timeHandler/dateDurationAdd"
import { durToHours, hoursToDur } from "../timeHandler/durations"

import { DAY_END, DAY_START, ROOT, HOURS_PER_DAY } from "../../const/globals"
import { endOfBusinessDayISO } from "../timeHandler/bordersOfBusinessDay"

// import { deepDiff } from "../utils/deepDiff"

// HELPERS =================================================================

/**
 * (c) Jasper Anders
 * (c) Prof. Dr. Ulrich Anders
 *
 * Adds one hour to a date, respecting DAY_START and DAY_END
 * @param {string} isoDate
 * @returns {string} isoDateAdded
 */
export function isoDateOneHourAdd(
  isoDate,
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  let dateAdded = addHours(parseISO(isoDate), 1)
  if (getHours(dateAdded) >= businessDayEnd || isWeekend(dateAdded)) {
    dateAdded = addBusinessDays(dateAdded, 1)
    dateAdded = startOfDay(dateAdded)
    dateAdded = setHours(dateAdded, businessDayStart)
  }
  return dateAdded.toISOString()
}

/**
 * (c) Jasper Anders
 * TEST: ok
 *
 * Returns the sum of durations that are in an array
 * @param {array of strings} durationsArray
 * @return {int} hoursPerDay
 * @return {int} sum
 */
export function durationsArraySumCalc(
  durationsArray,
  hoursPerDay = HOURS_PER_DAY
) {
  let sum = { days: 0, hours: 0 }
  durationsArray.forEach((element) => {
    sum.hours += element.hours
    sum.days += element.days + Math.floor(sum.hours / hoursPerDay)
    sum.hours = sum.hours % hoursPerDay
  })
  return sum
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Returns the minimum int from an array keeping only numbers > 0
 * @param {intArray} arr
 * @return {int} min
 */
export const arrFilteredMinGet = (arr) => {
  const arrFiltered = arr.filter((num) => num > 0)
  let min = 0
  if (arrFiltered.length > 0) {
    min = Math.min(...arrFiltered)
  }
  return min
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Determines the minimum of two isoDates strings
 * and returns it. Empty isoDates are ignored.
 * @param {string} isoDate1
 * @param {string} isoDate2
 * @returns {string} isoDateMin
 */
export function isoDateMinDet(isoDate1, isoDate2) {
  let isoDateMin = ""
  if (isoDate1 === "" && isoDate2 === "") {
    return isoDateMin
  } else if (isoDate1 === "" || isoDate2 === "") {
    return isoDate1 + isoDate2
  }

  const date1 = parseISO(isoDate1)
  const date2 = parseISO(isoDate2)

  isoDateMin = min([date1, date2]).toISOString()

  return isoDateMin
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Determines the maximum of two isoDates strings
 * and returns it. Empty isoDates are ignored.
 * @param {string} isoDate1
 * @param {string} isoDate2
 * @returns {string} isoDateMax
 */
export function isoDateMaxDet(isoDate1, isoDate2) {
  let isoDateMax = ""
  if (isoDate1 === "" && isoDate2 === "") {
    return isoDateMax
  } else if (isoDate1 === "" || isoDate2 === "") {
    return isoDate1 + isoDate2
  }

  const date1 = parseISO(isoDate1)
  const date2 = parseISO(isoDate2)

  isoDateMax = max([date1, date2]).toISOString()

  return isoDateMax
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Returns a projection duration object based on the
 * linear extrapolation of spent given degree
 * @param {object} spent
 * @param {int} degree
 * @return {object} projection
 */
export function projectionFromSpentExtrapolate(spent, degree) {
  const spentHours = durToHours(spent)
  let projection = { hours: 0, days: 0 }
  if (degree === 100) {
    projection = cloneDeep(spent)
  } else if (degree > 0) {
    projection = hoursToDur(Math.ceil((spentHours / degree) * 100))
  }

  return projection
}

// GOING DOWN & INHERIT STATE ====================================================

/*********************************************************************************
 * (c) Prof. Dr. Ulrich Anders
 * TODO: code may not be necessary. Computations are a little expensive.
 *
 * Cleans and mutates the nodes[nId].precedents, nodes[paId].dependents,
 * and nodes[nId].precedents[precedent].dependents
 * Removes:
 * - precedent that is the parent
 * - precedents that are already precedents in the parent
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
export function nodeDependenciesClean(nodes, nId) {
  const { paId } = nodes[nId]

  // if parent is a precedent
  if (
    nId !== ROOT &&
    nodes[nId].precedents.length > 0 &&
    nodes[nId].precedents.indexOf(paId) > -1
  ) {
    console.log(
      "TODO: nodeDependenciesClean — parent is a precedent. Does this ever happen?"
    )
    // filter out paId in node[nId].precedents
    nodes[nId].precedents = nodes[nId].precedents.filter(
      (precedent) => precedent !== paId
    )
    // filter out nId in node[paId].dependents
    nodes[paId].dependents = nodes[paId].dependents.filter(
      (dependent) => dependent !== nId
    )
  }

  // keep precedent only if not already present and found
  // in precedents of parent
  if (
    nId !== ROOT &&
    nodes[nId].precedents.length > 0 &&
    nodes[paId].precedents.length > 0
  ) {
    nodes[nId].precedents.forEach((precedent) => {
      if (nodes[paId].precedents.indexOf(precedent) > -1) {
        nodes[precedent].dependents = nodes[precedent].dependents.filter(
          (dependent) => dependent !== nId
        )
      }
    })

    nodes[nId].precedents = nodes[nId].precedents.filter(
      (precedent) => nodes[paId].precedents.indexOf(precedent) === -1
      // → true if not found
    )
  }

  return nodes[nId]
} ///////////////////////////////////////////////////////////////////////////

/****************************************************************************
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Recursively pulls and mutates fromWhenEarliest
 * from all precedents if it is later.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
export function nodeFromWhenEarliestPull(nodes, nId) {
  const { precedents } = nodes[nId]

  // received from precedents
  precedents.forEach((precedent) => {
    nodes = nodeFromWhenEarliestPull(nodes, precedent)
    // set only if it is later than current
    // fromWhenEarliest
    nodes[nId].fromWhenEarliest = isoDateMaxDet(
      isoDateOneHourAdd(nodes[precedent].byWhen),
      nodes[nId].fromWhenEarliest
    )
    // console.log(
    //   "nodeFromWhenEarliestPull: ",
    //   JSON.stringify(
    //     {
    //       nId,
    //       position: nodes[nId].position,
    //       precedent,
    //       fromWhenEarliest: nodes[nId].fromWhenEarliest,
    //     },
    //     null,
    //     2
    //   )
    // )
  })

  return nodes
}

/****************************************************************************
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Recursively pushes and mutates fromWhenEarliest
 * to all dependents if it is later.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
export function nodeFromWhenEarliestPush(nodes, nId) {
  const { dependents } = nodes[nId]

  // received from dependents
  dependents.forEach((dependent) => {
    nodes[dependent].fromWhenEarliest = isoDateMaxDet(
      isoDateOneHourAdd(nodes[nId].byWhen),
      nodes[dependent].fromWhenEarliest
    )
    console.log("nodeFromWhenEarliestPush: ", {
      nId,
      dependent,
      fromWhenEarliest: nodes[dependent].fromWhenEarliest,
    })
    nodes = nodeFromWhenEarliestPush(nodes, dependent)
  })

  return nodes
}

/****************************************************************************
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Recursively pulls and mutates byWhenLatest
 * from dependents if it is earlier.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
const nodeByWhenLatestPull = (nodes, nId) => {
  const { dependents } = nodes[nId]

  dependents.forEach((dependent) => {
    nodes = nodeByWhenLatestPull(nodes, dependent)
    nodes[nId].byWhenLatest = isoDateMinDet(
      nodes[dependent].byWhenLatest,
      nodes[nId].byWhenLatest
    )
    console.log("nodeByWhenLatestPull: ", {
      nId,
      dependent,
      byWhenLatest: nodes[nId].byWhenLatest,
    })
  })

  return nodes
}

/****************************************************************************
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Recursively pushes and mutates byWhenLatest
 * to all precedent if it is earlier.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
const nodeByWhenLatestPush = (nodes, nId) => {
  const { precedents } = nodes[nId]

  precedents.forEach((precedent) => {
    nodes[precedent].byWhenLatest = isoDateMinDet(
      nodes[nId].byWhenLatest,
      nodes[precedent].byWhenLatest
    )
    // console.log("nodeByWhenLatestPush: ", {
    //   nId,
    //   precedent,
    //   byWhenLatest: nodes[nId].byWhenLatest,
    // })

    nodes = nodeByWhenLatestPush(nodes, precedent)
  })

  return nodes
}

// SET STATE ===============================================================

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Mutates fromWhen and byWhen of a node and
 * re-calculates node.span if necessary.
 * Set fromWhen to:
 *   - fromWhenEarliest (inherited from the parent or because of precedents)
 * Set byWhen depending on:
 *   - byWhenLatest inherited from the parent
 *   - byWhenPinned from the node itself
 * @param {object} node
 * @returns {object} node
 */
export function nodeFromByWhenSet(node) {
  if (node.isIgnored || node.isUnresolved) {
    return node
  }
  const fromWhenPrevious = node.fromWhen
  const byWhenPrevious = node.byWhen
  const datesByWhen = []

  node.fromWhen = node.fromWhenEarliest

  // byWhen ---
  if (node.isByWhenPinned) {
    node.byWhenLatest = isoDateMinDet(node.byWhen, node.byWhenLatest)
  }

  if (node.byWhenLatest !== "") {
    datesByWhen.push(parseISO(node.byWhenLatest))
  }

  datesByWhen.push(dateDurationAdd(parseISO(node.fromWhen), node.span))

  node.byWhen = min(datesByWhen).toISOString()

  if (node.fromWhen !== fromWhenPrevious || node.byWhen !== byWhenPrevious) {
    node = nodeSpanSet(node)
  }

  return node
}

/**
 * v1.1.0: (c) Ulrich Anders
 *
 * Mutates the node and sets the span based on
 * byWhen and fromWhen.
 * It returns the node itself.
 * @param {object} node
 * @param {int} businessDayStart
 * @param {int} businessDayEnd
 * @param {object} node
 */
export function nodeSpanSet(
  node,
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  if (node.isIgnored || node.isUnresolved) {
    return node
  }

  const hoursPerDay = businessDayEnd - businessDayStart

  const { byWhen, fromWhen } = node
  const byWhenDate = parseISO(byWhen)
  const fromWhenDate = parseISO(fromWhen)

  let businessDays = differenceInBusinessDays(byWhenDate, fromWhenDate)

  const hoursDiff = getHours(byWhenDate) - getHours(fromWhenDate)
  let hours = hoursDiff

  if (getHours(fromWhenDate) + hoursDiff > businessDayEnd) {
    const hoursRest = hours - businessDayEnd
    businessDays += 1
    hours = hoursRest
  } else if (getHours(fromWhenDate) + hoursDiff <= businessDayStart) {
    const hoursRest = businessDayEnd - (businessDayStart - hours)
    businessDays -= 1
    hours = hoursRest
  }

  if (hours === hoursPerDay) {
    hours = 0
    businessDays++
  }

  node.span = {
    days: businessDays ? businessDays : 0,
    hours: hours ? hours : 0,
  }

  return node
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Mutates the node and sets projection and slack value.
 * Note that span and spent should already be set.
 * @param {object} node
 * @returns {object} node
 */
export const nodeProjectionSlackSet = (node) => {
  if (node.isIgnored || node.isUnresolved) {
    return node
  }
  node.projection = projectionFromSpentExtrapolate(node.spent, node.degree)
  // initially the projection is set to the span
  if (node.projection.days === 0 && node.projection.hours === 0) {
    node.projection = cloneDeep(node.span)
  }
  if (node.isIgnored) {
    node.projection = cloneDeep(node.spent)
  }

  const hoursSpan = durToHours(node.span)
  const hoursProjection = durToHours(node.projection)
  node.slack = hoursToDur(hoursSpan - hoursProjection)

  return node
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Mutates the node and sets the Deadline Traffic Light
 * and sets forecast and quality Traffic Lights accordingly
 * @param {object} node
 * @param {date} dId
 * @returns {object} node
 */
export const nodeTrafficLightsSet = (node, dId) => {
  const { byWhen, degree } = node
  const isByWhenBeforeStatus = isBefore(parseISO(byWhen), parseISO(dId))

  if (degree >= 100) {
    node.deadline = 4 // green
    node.forecast = 0
  }
  // < 100%
  else {
    if (isByWhenBeforeStatus) {
      node.deadline = 1 // red
      node.forecast = 0
      node.quality = 0
    } else {
      node.deadline = 0
      node.quality = 0
    }
  }
  return node
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Mutates the node and sets wip
 * @param {object} node
 * @returns {object} node
 */
export const nodeWIPSet = (node) => {
  node.wip = 0
  if (
    (node.spent.hours > 0 || node.spent.days > 0) &&
    node.degree < 100 &&
    !node.isIgnored &&
    !node.isUnresolved
  ) {
    node.wip = 1
  }

  return node
}

// AGGregat STATE ===============================================================

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Mutates the nodes[nId] and aggregates byWhen and fromWhen.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes[nId]
 */
export function nodeFromByWhenAgg(nodes, nId) {
  const children = nodes[nId].children
  const childrenFiltered = children.filter(
    (child) => !nodes[child].isUnresolved && !nodes[child].isIgnored
  )

  if (childrenFiltered.length > 0) {
    let fromWhenDates = childrenFiltered.map((child) =>
      parseISO(nodes[child].fromWhen)
    )
    let byWhenDates = childrenFiltered.map((child) =>
      parseISO(nodes[child].byWhen)
    )

    nodes[nId].fromWhen = min(fromWhenDates).toISOString()
    // only aggregate byWhen if not pinned
    if (!nodes[nId].isByWhenPinned) {
      nodes[nId].byWhen = max(byWhenDates).toISOString()
    }
  } else {
    nodes[nId].fromWhen = nodes[nId].fromWhenEarliest
    nodes[nId].byWhen = endOfBusinessDayISO(parseISO(nodes[nId].fromWhen))
  }

  return nodes[nId]
}

/**
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Mutates the nodes[nId] and aggregates projection and spent.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes[nId]
 */
export function nodeProjectionSpentDegreeAgg(nodes, nId) {
  // defaults that may be overrode
  nodes[nId].spent = { days: 0, hours: 0 }
  nodes[nId].spentExtra = 0
  nodes[nId].projection = { days: 0, hours: 0 }
  nodes[nId].degree = 0

  const children = nodes[nId].children

  let spentAgg = 0
  let spentExtra = 0
  let projectionAgg = 0

  children.forEach((child) => {
    spentExtra += nodes[child].spentExtra
    if (!nodes[child].isUnresolved) {
      if (!nodes[child].isIgnored) {
        if (nodes[child].degree > 0) {
          spentAgg += durToHours(nodes[child].spent)
        }
        projectionAgg += durToHours(nodes[child].projection)
      }
      // if isIgnored === true
      // spentExtra is to add spent hours for ignored deliverables
      // which are otherwise not aggregated
      else {
        spentExtra += durToHours(nodes[child].spent)
      }
    }
  })
  nodes[nId].spent = hoursToDur(spentAgg)
  nodes[nId].spentExtra = spentExtra
  nodes[nId].projection = hoursToDur(projectionAgg)

  nodes[nId].degree =
    projectionAgg === 0 ? 0 : Math.round((spentAgg / projectionAgg) * 100)

  return nodes[nId]
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Mutates the nodes[nId] and aggregates projection and spent.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes[nId]
 */
export function nodeTrafficLightsAgg(nodes, nId) {
  // default values may be overrode
  nodes[nId].deadline = 0
  nodes[nId].forecast = 0
  nodes[nId].quality = 0

  const children = nodes[nId].children

  const childrenFiltered = children.filter(
    (child) => !nodes[child].isUnresolved && !nodes[child].isIgnored
  )

  if (childrenFiltered.length > 0) {
    const deadlines = childrenFiltered.map((child) => nodes[child].deadline)
    const forecasts = childrenFiltered.map((child) => nodes[child].forecast)
    const qualities = childrenFiltered.map((child) => nodes[child].quality)

    nodes[nId].deadline = arrFilteredMinGet(deadlines)
    nodes[nId].forecast = arrFilteredMinGet(forecasts)
    nodes[nId].quality = arrFilteredMinGet(qualities)
  }

  return nodes[nId]
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Mutates the nodes[nId] and aggregates the wip.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes[nId]
 */
export function nodeWIPAgg(nodes, nId) {
  nodes[nId].wip = 0
  nodes[nId].children.forEach((child) => {
    nodes[nId].wip += nodes[child].wip
  })

  return nodes[nId]
}

// =========================================================================
// CORE: RECURSION TO GO DOWN FOR SETTING & INHERITANCE AND
// COME BACK UP FOR AGGREGATION

/***************************************************************************
 * v2.0.0: (c) Prof. Dr. Ulrich Anders
 * WIP: go down from nId go back up to ROOT
 *
 * A function that generically runs through the node tree starting at nId
 * and performs all state actions going down
 * and carrying out all aggregations coming back up again.
 * In fact, nodesTreeDownUp needs to run through the tree twice:
 * nodesTreeDownUp1: makes all the cleanups and inheritances
 * nodesTreeDownUp2:
 * @param {object} nodes
 * @param {string} nId
 * @param {string} dId -- status date ID
 * @param {object} options
 * @returns {object} nodesMutated
 */
export function nodesTreeDownUp(
  nodes,
  nId = ROOT,
  dId,
  options = {
    doPositions: true,
    doDependenciesClean: true,
    doFromByWhens: true,
    doTrafficLights: true,
    doEarliestLatest: true,
    doWIP: true,
  }
) {
  let nodesMutated = nodesTreeDownUp1(nodes, nId, dId, options)
  nodesMutated = nodesTreeDownUp2(nodes, nId, dId, options)
  return nodesMutated
}

/****************************************************************************
 * v2.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Helper function to nodesTreeDownUp
 * Mutate when going down with cleaning up, carrying out inheritance
 * and resetting aggregated nodes that are childless
 * @param {object} nodes
 * @param {string} nId
 * @param {object} options
 * @returns {object} nodesMutated
 */
function nodesTreeDownUp1(
  nodes,
  nId = ROOT,
  dId,
  options = {
    doPositions: true,
    doDependenciesClean: true,
    doEarliestLatest: true,
  }
) {
  const { doPositions, doDependenciesClean, doEarliestLatest } = options

  let nodesMutated = nodes

  if (doDependenciesClean) {
    nodesMutated[nId] = nodeDependenciesClean(nodesMutated, nId)
  }

  if (nodesMutated[nId].isAggregate) {
    // consider if aggregated node has precs
    nodesMutated = nodeFromWhenEarliestPull(nodesMutated, nId)
    nodesMutated[nId] = nodeFromByWhenSet(nodesMutated[nId])

    // check if node has children
    if (nodesMutated[nId].children.length > 0) {
      // GO DOWN
      nodesMutated[nId].children.forEach((child, index) => {
        if (doPositions) {
          nodesMutated[child].position = [
            ...nodesMutated[nId].position,
            index + 1,
          ]
        }

        if (doEarliestLatest) {
          // INHERITANCE from parents
          nodesMutated[child].fromWhenEarliest =
            nodesMutated[nId].fromWhenEarliest

          // set byWhenLatest of child from parent
          nodesMutated[child].byWhenLatest = nodesMutated[nId].byWhenLatest

          // potentially override byWhenLatest if child is pinned
          if (nodesMutated[child].isByWhenPinned) {
            nodesMutated[child].byWhenLatest = isoDateMinDet(
              nodesMutated[child].byWhen,
              nodesMutated[child].byWhenLatest
            )
          }
        }

        nodesMutated = nodeFromWhenEarliestPush(nodesMutated, child)
        nodesMutated = nodeByWhenLatestPush(nodesMutated, child)

        // RECURSION: trigger stateCalc in each child
        nodesMutated = nodesTreeDownUp1(nodesMutated, child, dId, options)
      }) // end forEach

      return nodesMutated
    } // end if
    else {
      // reset since aggregated node does not have children
      nodesMutated[nId].wip = 0
      nodesMutated[nId].spent.days = 0
      nodesMutated[nId].spent.hours = 0
      nodesMutated[nId].degree = 0
      nodesMutated[nId].deadline = 0
      nodesMutated[nId].quality = 0
      nodesMutated[nId].forecast = 0
      nodesMutated[nId].byWhen = endOfBusinessDayISO(
        parseISO(nodesMutated[nId].fromWhen)
      )
    }
  }

  return nodesMutated
}

/****************************************************************************
 * v2.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Helper function to nodesTreeDownUp
 * Mutate nodes when going down and aggregate when coming back up.
 * @param {object} nodes
 * @param {string} nId
 * @param {object} options
 * @returns {object} nodesMutated
 */
function nodesTreeDownUp2(
  nodes,
  nId = ROOT,
  dId,
  options = {
    doFromByWhens: true,
    doTrafficLights: true,
    doEarliestLatest: true,
    doWIP: true,
  }
) {
  const { doFromByWhens, doTrafficLights, doEarliestLatest, doWIP } = options

  let nodesMutated = nodes

  if (nodesMutated[nId].isAggregate) {
    // check if node has children
    if (nodesMutated[nId].children.length > 0) {
      // GO DOWN
      nodesMutated[nId].children.forEach((child, index) => {
        if (doEarliestLatest) {
          // consider fromWhenEarliest pulled from precedents
          nodesMutated = nodeFromWhenEarliestPull(nodesMutated, child)
          // consider byWhenLatest pulled from precedents
          nodesMutated = nodeByWhenLatestPull(nodesMutated, child)
          // set byWhen dependent on byWhenLatest
          nodesMutated[child].byWhen = isoDateMinDet(
            nodesMutated[child].byWhen,
            nodesMutated[child].byWhenLatest
          )
          // set to the earliest fromWhen date
          nodesMutated[child].fromWhen = isoDateMinDet(
            nodesMutated[child].fromWhen,
            nodesMutated[child].fromWhenEarliest
          )
        }

        // RECURSION: trigger stateCalc in each child
        nodesMutated = nodesTreeDownUp2(nodesMutated, child, dId, options)
      }) // end forEach

      // COME BACK UP
      // AGGREGATE over children

      if (doFromByWhens || doEarliestLatest) {
        nodesMutated[nId] = nodeFromByWhenAgg(nodesMutated, nId)
        nodesMutated[nId] = nodeSpanSet(nodesMutated[nId])
        nodesMutated[nId] = nodeProjectionSpentDegreeAgg(nodesMutated, nId)
        // slack is never aggregated and therefore set to { days: 0, hours: 0 }
        nodesMutated[nId].slack = { days: 0, hours: 0 }
      }

      if (doTrafficLights) {
        nodesMutated[nId] = nodeTrafficLightsAgg(nodesMutated, nId)
      }

      if (doWIP) {
        nodesMutated[nId] = nodeWIPAgg(nodesMutated, nId)
      }

      return nodesMutated
    } // end if
  }
  // STATE SETTINGS: carry out at leaf level
  if (doFromByWhens || doEarliestLatest) {
    nodesMutated[nId] = nodeSpanSet(nodesMutated[nId])
    nodesMutated[nId] = nodeFromByWhenSet(nodesMutated[nId])
    nodesMutated[nId] = nodeProjectionSlackSet(nodesMutated[nId])
  }
  if (doTrafficLights) {
    nodesMutated[nId] = nodeTrafficLightsSet(nodesMutated[nId], dId)
  }
  if (doWIP) {
    nodesMutated[nId] = nodeWIPSet(nodesMutated[nId])
  }

  return nodesMutated
}
