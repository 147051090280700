import { nodesRowsGen } from "../nodes/nodesRowsGen"
import { ROOT } from "../../const/globals"

/**
 *
 * @param {*} status
 */
export function deliverablesCount(status) {
  const { nodes } = status

  const isFoldedIgnore = true
  const rows = nodesRowsGen(nodes, ROOT, isFoldedIgnore)
  let countDeliverablesLeaves = 0
  let countDeliverablesLeavesUnresolved = 0
  let countDeliverablesLeavesIgnored = 0
  let countDeliverablesLeavesDone = 0
  let countDeliverablesLeavesNYS = 0
  let countDeliverablesLeavesWIP = 0
  let countDeliverablesLeavesQualityRed = 0
  let countDeliverablesLeavesQualityOrange = 0
  let countDeliverablesLeavesQualityYellow = 0
  let deliverablesPersonsInvolved = []
  let countDeliverablesPersonsInvolved = 0

  for (let i = 0; i < rows.length; i++) {
    if (nodes[rows[i]].children.length === 0) {
      if (!nodes[rows[i]].isIgnored) {
        countDeliverablesLeaves++
        if (nodes[rows[i]].isUnresolved) {
          countDeliverablesLeavesUnresolved++
        } else if (nodes[rows[i]].degree === 100) {
          countDeliverablesLeavesDone++
          // quality === 1 → red
          if (nodes[rows[i]].quality === 1) {
            countDeliverablesLeavesQualityRed++
          }
          // quality === 2 → orange
          else if (nodes[rows[i]].quality === 2) {
            countDeliverablesLeavesQualityOrange++
          }
          // quality === 3 → yellow
          else if (nodes[rows[i]].quality === 3) {
            countDeliverablesLeavesQualityYellow++
          }
        } else if (nodes[rows[i]].wip > 0) {
          countDeliverablesLeavesWIP++
        } else {
          countDeliverablesLeavesNYS++
        }
      } else {
        countDeliverablesLeavesIgnored++
      }
    }
    deliverablesPersonsInvolved.push(nodes[rows[i]].who)
    deliverablesPersonsInvolved.push(nodes[rows[i]].toWhom)
  }

  deliverablesPersonsInvolved = [...new Set(deliverablesPersonsInvolved)]
  countDeliverablesPersonsInvolved = deliverablesPersonsInvolved.length - 1

  return {
    countDeliverablesLeaves,
    countDeliverablesLeavesWIP,
    countDeliverablesLeavesNYS,
    countDeliverablesLeavesDone,
    countDeliverablesLeavesQualityRed,
    countDeliverablesLeavesQualityOrange,
    countDeliverablesLeavesQualityYellow,
    countDeliverablesLeavesUnresolved,
    countDeliverablesLeavesIgnored,
    countDeliverablesPersonsInvolved,
  }
}
