import React, { useRef, useEffect } from "react"
import { useForm } from "react-hook-form"
import { chakra, Input, Flex, Box, Tooltip } from "@chakra-ui/react"
import { LabelTop } from "../../componentsWithChakra"
import { formParamsUpdate } from "../../redux/actions/project"

import {
  PARAMS_BORDERS_MESSAGE,
  PARAMS_BORDERS_REGEX,
} from "../../const/messages"

import objDeepTrim from "../../functions/utils/objDeepTrim"

const FormParams = ({ project, settings, isResetForm = false, dispatch }) => {
  //=================================================================
  const { statusDatesList, statusDateCurrent } = project

  const isStatusDateInitial =
    statusDatesList.indexOf(statusDateCurrent) === statusDatesList.length - 1

  const { isStatusLocked } = project.statuses[statusDateCurrent].setup

  const { currencySymbol } = settings

  const inputRef = useRef(null)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ delayError: 500 })

  const {
    pUnresolvedIssues,
    pDOCThroughDOXS,
    pDOCThroughDOTS,
    pSatisfactionCustomer,
    pSatisfactionTeam,
  } = project.params

  //-----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset])

  //-----------------------------------------------------------------
  const onSubmit = (data) => {
    inputRef.current.focus()
    objDeepTrim(data)
    const payload = { data }
    dispatch(formParamsUpdate(payload))
  }

  //=================================================================
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Flex>
        <Flex w="50%" justify="flex-end"></Flex>
        <Flex w="50%" justify="flex-end" wrap="wrap">
          <Box w={["50%", "50%"]} pl={[0, 0, 0, 0]} pr={[1, 1, 1, 1]}>
            <Tooltip
              label="Change the currency symbol. This parameters can only be changed when initially setting up the project and not at later status dates."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Currency Symbol</LabelTop>
            </Tooltip>
            <Input
              {...register("currencySymbol")}
              defaultValue={currencySymbol}
              isDisabled={!isStatusDateInitial || isStatusLocked}
            />
          </Box>
          <Box w={["50%", "50%"]} pl={[1, 1, 1, 1]} pr={[0, 0, 0, 0]}>
            <Tooltip
              label="Determine the borders when the color for Unresolved Issues (Π = pieces) turn: green, amber, orange, red. These parameters can only be changed when initially setting up the project and not at later status dates."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Unresolved Issues Π</LabelTop>
            </Tooltip>
            <Tooltip
              isOpen={errors.pUnresolvedIssues}
              label={
                errors.pUnresolvedIssues ? errors.pUnresolvedIssues.message : ""
              }
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("pUnresolvedIssues", {
                  required: "Invalid: Please enter the traffic light borders.",
                  pattern: {
                    value: PARAMS_BORDERS_REGEX,
                    message: PARAMS_BORDERS_MESSAGE,
                  },
                })}
                defaultValue={pUnresolvedIssues
                  .map((el) => el.toString())
                  .join(", ")}
                isDisabled={!isStatusDateInitial || isStatusLocked}
              />
            </Tooltip>
          </Box>
          <Box w={["50%", "50%"]} pl={[0, 0, 0, 0]} pr={[1, 1, 1, 1]}>
            <Tooltip
              label="Determine the borders when the color for DOC / DOXS turns: red, orange, amber, green. These parameters can only be changed when initially setting up the project and not at later status dates."
              placement="top-start"
              hasArrow
            >
              <LabelTop>DOC / DOXS %-Borders</LabelTop>
            </Tooltip>
            <Tooltip
              isOpen={errors.pDOCThroughDOXS}
              label={
                errors.pDOCThroughDOXS ? errors.pDOCThroughDOXS.message : ""
              }
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("pDOCThroughDOXS", {
                  required: "Invalid: Please enter the DOC/DOXS borders.",
                  pattern: {
                    value: PARAMS_BORDERS_REGEX,
                    message: PARAMS_BORDERS_MESSAGE,
                  },
                })}
                defaultValue={pDOCThroughDOXS
                  .map((el) => el.toString())
                  .join(", ")}
                isDisabled={!isStatusDateInitial || isStatusLocked}
              />
            </Tooltip>
          </Box>
          <Box w={["50%", "50%"]} pl={[1, 1, 1, 1]} pr={[0, 0, 0, 0]}>
            <Tooltip
              label="Determine the borders when the color for DOC / DOTS turns: red, orange, amber, green. These parameters can only be changed when initially setting up the project and not at later status dates."
              placement="top-start"
              hasArrow
            >
              <LabelTop>DOC / DOTS %-Borders</LabelTop>
            </Tooltip>
            <Tooltip
              isOpen={errors.pDOCThroughDOTS}
              label={
                errors.pDOCThroughDOTS ? errors.pDOCThroughDOTS.message : ""
              }
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("pDOCThroughDOTS", {
                  required: "Invalid: Please enter the DOC/DOTS borders.",
                  pattern: {
                    value: PARAMS_BORDERS_REGEX,
                    message: PARAMS_BORDERS_MESSAGE,
                  },
                })}
                defaultValue={pDOCThroughDOTS
                  .map((el) => el.toString())
                  .join(", ")}
                isDisabled={!isStatusDateInitial || isStatusLocked}
              />
            </Tooltip>
          </Box>
          <Box w={["50%", "50%"]} pl={[0, 0, 0, 0]} pr={[1, 1, 1, 1]}>
            <Tooltip
              label="Determine the borders when the color for Customer Satisfaction turns: red, orange, amber, green. These parameters can only be changed when initially setting up the project and not at later status dates."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Customer Customer %-Borders</LabelTop>
            </Tooltip>
            <Tooltip
              isOpen={errors.pSatisfactionCustomer}
              label={
                errors.pSatisfactionCustomer
                  ? errors.pSatisfactionCustomer.message
                  : ""
              }
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("pSatisfactionCustomer", {
                  required: "Invalid: Please enter the traffic light borders.",
                  pattern: {
                    value: PARAMS_BORDERS_REGEX,
                    message: PARAMS_BORDERS_MESSAGE,
                  },
                })}
                defaultValue={pSatisfactionCustomer
                  .map((el) => el.toString())
                  .join(", ")}
                isDisabled={!isStatusDateInitial || isStatusLocked}
              />
            </Tooltip>
          </Box>
          <Box w={["50%", "50%"]} pl={[0, 0, 1, 1]} pr={[1, 1, 1, 1]}>
            <Tooltip
              label="Determine the borders when the color for Team Satisfaction turns: red, orange, amber, green. These parameters can only be changed when initially setting up the project and not at later status dates."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Team Satisfaction %-Borders</LabelTop>
            </Tooltip>
            <Tooltip
              isOpen={errors.pSatisfactionTeam}
              label={
                errors.pSatisfactionTeam ? errors.pSatisfactionTeam.message : ""
              }
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("pSatisfactionTeam", {
                  required: "Invalid: Please enter the traffic light borders.",
                  pattern: {
                    value: PARAMS_BORDERS_REGEX,
                    message: PARAMS_BORDERS_MESSAGE,
                  },
                })}
                defaultValue={pSatisfactionTeam
                  .map((el) => el.toString())
                  .join(", ")}
                isDisabled={!isStatusDateInitial || isStatusLocked}
              />
            </Tooltip>
          </Box>
          {!isStatusLocked && isStatusDateInitial && (
            <Box w="100%" order={[10]} mb={4}>
              <chakra.button w="0" ref={inputRef}></chakra.button>
            </Box>
          )}
        </Flex>
      </Flex>
    </form>
  )
}

export default FormParams
