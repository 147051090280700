import React from "react"

import { Box } from "@chakra-ui/react"
import GanttBar from "../GanttBar"

const GanttBars = ({
  persons,
  timeLine,
  projectCurrent,
  nodesExtraInfos,
  ganttDurationIndex,
  boxW,
  dispatch,
}) => {
  //=================================================================
  const { nodes, rows } = projectCurrent
  const { isStatusLocked } = projectCurrent.setup

  //=================================================================
  const renderGanttBars = () => {
    let renderedGanttBars = rows.map((nId, index) => {
      // merge nodeInfos from re-select into node
      const node = { ...nodes[nId], ...nodesExtraInfos[nId] }
      const initials = persons?.[node.who]?.nameInitials
      return (
        <GanttBar
          key={nId}
          row={index}
          initials={initials}
          node={node}
          boxW={boxW}
          timeLine={timeLine}
          ganttDurationIndex={ganttDurationIndex}
          isStatusLocked={isStatusLocked}
          dispatch={dispatch}
        />
      )
    })

    return renderedGanttBars
  }

  return <Box>{renderGanttBars()}</Box>
}

export default GanttBars
