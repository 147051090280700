import React from "react"
import { Box, Tooltip } from "@chakra-ui/react"
import { Span } from "../../componentsWithChakra"
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipChart,
  ResponsiveContainer,
} from "recharts"
import COLORS from "../../theme/colors"
import CustomizedTick from "./CustomizedTick"

const ProgressChartPersons = ({ chartsData, params, ...rest }) => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Box {...rest}>
      <Box textAlign="center" style={{ position: "relative", zIndex: 1 }}>
        <Tooltip
          label="Number Π of persons involved in the project. Persons counted to the core of the project are assigned to at least one deliverable as WHO or TO WHOM. All other persons are considered to belong to the context of the project."
          placement="top-start"
          hasArrow
        >
          <span>
            Persons Involved Π (<Span color={COLORS.blue[500]}>Core</Span>
            {" & "}
            <Span color={COLORS.pink[500]}>Context</Span>)
          </span>
        </Tooltip>
      </Box>
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <ComposedChart
          width={500}
          height={300}
          data={chartsData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 35,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="category"
            dataKey="dateMMdd"
            interval={"personsStartEnd"}
            tick={<CustomizedTick />}
          />
          <YAxis allowDecimals={false} />
          <TooltipChart />
          <Bar
            dataKey="personsCore"
            name="Persons Involved Core"
            barSize={10}
            fill={COLORS.blue[500]}
            stackId="a"
          ></Bar>
          <Bar
            dataKey="personsContext"
            name="Persons Involved Context"
            barSize={10}
            fill={COLORS.pink[500]}
            stackId="a"
          ></Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default ProgressChartPersons
