import React, { useRef, useEffect } from "react"
import { useForm, useFormState } from "react-hook-form"
import {
  chakra,
  Input,
  Text,
  Flex,
  Box,
  Select,
  Tooltip,
} from "@chakra-ui/react"
import {
  formDatesUpdate,
  selectStatusUpdate,
} from "../../redux/actions/project"

import { LabelTop } from "../../componentsWithChakra"
import ISOToLoc from "../../functions/timeHandler/ISOToLoc"
import objDeepTrim from "../../functions/utils/objDeepTrim"

import {
  DATE_MESSAGE,
  DATE_REGEX,
  // DAY_START_REGEX,
  // DAY_START_MESSAGE,
  // DAY_END_REGEX,
  // DAY_END_MESSAGE,
} from "../../const/messages"
import { ROOT } from "../../const/globals"

const FormDates = ({ project, isResetForm = false, dispatch }) => {
  //=================================================================
  const {
    statusDatesList,
    statusDateCurrent,
    statusDateNext,
    statusDateNextPlanned,
  } = project

  const isStatusDateInitial =
    statusDatesList.indexOf(statusDateCurrent) === statusDatesList.length - 1

  const { statusCompleted } = project.statuses[statusDateCurrent].setup
  const isStatusCompleted = statusCompleted === "Is Completed"

  const dateStart = project.statuses[statusDateCurrent].nodes[ROOT].fromWhen

  const { dayStart, dayEnd, dateFormat } = project.params
  //-----------------------------------------------------------------

  const inputRef = useRef(null)

  const {
    register,
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ delayError: 500 })

  const { dirtyFields } = useFormState({ control })

  //-----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset, project])

  //-----------------------------------------------------------------
  const onSubmit = (data) => {
    // moves to hidden button. This causes an onBlur.
    inputRef.current.focus()
  }

  const onBlur = (data) => {
    if (data.statusDateNext === undefined) {
      data.statusDateNext = ""
    }
    if (data.statusDateNextPlanned === undefined) {
      data.statusDateNextPlanned = ""
    }
    objDeepTrim(data)
    let payload = { data }
    dispatch(formDatesUpdate(payload))
  }

  const onChange = (event) => {
    const payload = { statusCompleted: event.target.value }

    dispatch(selectStatusUpdate(payload))
  }

  //=================================================================
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Flex wrap="wrap" mb={4}>
        <Flex w={["100%", "50%"]} wrap="wrap">
          <Box w={["50%"]} pl={[0, 0, 0, 0]} pr={[1, 1, 1, 1]} order={[1]}>
            <LabelTop>Current Status Date</LabelTop>
            <Text>{ISOToLoc(statusDateCurrent, "yyyy-MM-dd")}</Text>
          </Box>

          <Box w={["50%"]} pl={[0, 0, 0, 0]} pr={[1, 1, 1, 1]} order={[3]}>
            <LabelTop>Current Status</LabelTop>
            <Select
              name="statusCompleted"
              value={statusCompleted}
              onChange={onChange}
              h="25px"
            >
              <option value="In Progress">In Progress</option>
              <option value="Is Completed">Is Completed</option>
            </Select>
          </Box>

          <Box w={["50%"]} pl={[1, 1, 1, 1]} pr={[1, 1, 1, 1]} order={[2]}>
            <LabelTop>Planned Next Status Date</LabelTop>
            <Tooltip
              isOpen={errors.statusDateNextPlanned}
              label={
                errors.statusDateNextPlanned
                  ? errors.statusDateNextPlanned.message
                  : ""
              }
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("statusDateNextPlanned", {
                  pattern: {
                    value: DATE_REGEX,
                    message: DATE_MESSAGE,
                  },
                })}
                defaultValue={ISOToLoc(statusDateNextPlanned, "yyyy-MM-dd")}
                onBlur={
                  dirtyFields?.statusDateNextPlanned
                    ? handleSubmit(onBlur)
                    : null
                }
              />
            </Tooltip>
            <Text></Text>
          </Box>

          <Box w={["50%"]} pl={[1, 1, 1, 1]} pr={[1, 1, 1, 1]} order={[4]}>
            <Tooltip
              label="Before you can set a Confirmed Next Status Date you will have to set the Current Status from 'In Progress' to 'Is Completed'. Be aware that no changes can be made to a status once it is completed."
              fontSize="sm"
              placement="top-start"
              hasArrow
            >
              <LabelTop
                color={!isStatusCompleted ? "deepOrange.200" : "deepOrange.500"}
              >
                Confirmed Next Status Date
              </LabelTop>
            </Tooltip>

            <Tooltip
              isOpen={errors.statusDateNext}
              label={errors.statusDateNext ? errors.statusDateNext.message : ""}
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("statusDateNext", {
                  pattern: {
                    value: DATE_REGEX,
                    message: DATE_MESSAGE,
                  },
                })}
                defaultValue={ISOToLoc(statusDateNext, "yyyy-MM-dd")}
                onBlur={
                  dirtyFields?.statusDateNext ? handleSubmit(onBlur) : null
                }
                isDisabled={statusCompleted === "In Progress"}
              />
            </Tooltip>
          </Box>
        </Flex>

        <Flex w={["100%", "50%"]} wrap="wrap">
          <Box w={["50%"]} pl={[1, 1, 1, 1]} pr={[1, 1, 1, 1]} order={[1]}>
            <Tooltip
              label="START DATE: the start date of your project. It can only be changed when initially setting up the project and not at later status dates."
              fontSize="sm"
              placement="top-start"
              hasArrow
            >
              <LabelTop>Start Date</LabelTop>
            </Tooltip>
            <Tooltip
              isOpen={errors.dateStart}
              label={errors.dateStart ? errors.dateStart.message : ""}
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("dateStart", {
                  required: "Invalid: Please enter a date.",
                  pattern: {
                    value: DATE_REGEX,
                    message: DATE_MESSAGE,
                  },
                })}
                defaultValue={ISOToLoc(dateStart, "yyyy-MM-dd")}
                onBlur={dirtyFields?.dateStart ? handleSubmit(onBlur) : null}
                isDisabled={!isStatusDateInitial}
              />
            </Tooltip>
          </Box>

          <Box w={["50%"]} pl={[1]} pr={[1]} order={[3]}>
            <LabelTop flex="none">Date Format</LabelTop>
            <Text>{dateFormat}</Text>
            {/* <Input name="dateFormat" defaultValue={dateFormat} ref={register} /> */}
          </Box>

          <Box w={["50%"]} pl={[1, 1, 1, 1]} pr={[0, 0, 0, 0]} order={[2]}>
            <LabelTop flex="none">Day Start h</LabelTop>
            <Text>{dayStart}</Text>
            {/* <Tooltip
              isOpen={errors.dayStart}
              label={errors.dayStart ? errors.dayStart.message : ""}
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("dayStart", {
                  required: "Invalid: Please enter a date.",
                  pattern: {
                    value: DAY_START_REGEX,
                    message: DAY_START_MESSAGE,
                  },
                })}
                defaultValue={dayStart}
                onBlur={dirtyFields?.dayStart ? handleSubmit(onBlur) : null}
                isDisabled={!isStatusDateInitial}
              />
            </Tooltip> */}
          </Box>

          <Box w={["50%"]} pl={[1, 1, 1, 1]} pr={[0]} order={[4]}>
            <LabelTop flex="none">Day End h</LabelTop>
            <Text>{dayEnd}</Text>
            {/* <Tooltip
              isOpen={errors.dayEnd}
              label={errors.dayEnd ? errors.dayEnd.message : ""}
              placement="top"
              hasArrow
              bg="red.700"
            >
              <Input
                {...register("dayEnd", {
                  required: "Invalid: Please enter a date.",
                  pattern: {
                    value: DAY_END_REGEX,
                    message: DAY_END_MESSAGE,
                  },
                })}
                defaultValue={dayEnd}
                onBlur={dirtyFields?.dayEnd ? handleSubmit(onBlur) : null}
                isDisabled={!isStatusDateInitial}
              />
            </Tooltip> */}
          </Box>
        </Flex>
      </Flex>

      <Box w="100%">
        <chakra.button w="0" ref={inputRef}></chakra.button>
      </Box>
    </form>
  )
}

export default FormDates
