import React from "react"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"

const Span = ({ children, ...rest }) => (
  <chakra.span whiteSpace="nowrap" {...rest}>
    {children}
  </chakra.span>
)

Span.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.object,
}

export default Span
