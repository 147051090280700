import cloneDeep from "lodash.clonedeep"
import { ROOT } from "../../const/globals"

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Generates an array of nIds that are sorted by
 * position (default), who, or toWhom
 * @param {object} nodes - nodes tree
 * @param {string} nId - node Id entry
 * @param {boolean} isFoldedIgnore - boolean that ignores the fold property
 * @return {array} list of nodes
 */
export function rowsGenSorted(status, params) {
  const statusNew = cloneDeep(status)

  statusNew.rows = nodesRowsGen(statusNew.nodes)
  if (params.isWhoSortedByName) {
    statusNew.rows = rowsSortAt(statusNew, "who")
  } else if (params.isToWhomSortedByName) {
    statusNew.rows = rowsSortAt(statusNew, "toWhom")
  }

  return statusNew.rows
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.0.1: (c) Prof. Dr. Ulrich Anders
 *
 * Generates an array of nIds considering whether nodes are folded.
 * Starts at a given nId.
 * @param {object} nodes - nodes tree
 * @param {string} nId - node Id entry
 * @param {boolean} isFoldedIgnore - boolean that ignores the fold property
 * @return {array} array of nIds
 */
export function nodesRowsGen(nodes, nId = ROOT, isFoldedIgnore = false) {
  // Extract properties of a node
  const { children, isFolded } = nodes[nId]
  // check if node is either folded or has no children
  if (!!children.length && (!isFolded || isFoldedIgnore)) {
    return [
      nId,
      // for each child recursively call nodesRowsGen
      ...children.map((chId) => nodesRowsGen(nodes, chId)),
    ].flat() // make array flat. E.g: [a, [b, c]] -> [a, b, c]
  }
  // if folded or no children present return nId
  return [nId]
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Sorts the rows alphabetically at a key.
 * If the nameDisplayShort are the same
 * @param {object} status
 * @returns {array} rows
 */
export function rowsSortAt(status, key = "who") {
  const { persons, nodes, rows } = status
  let rowsNew = [...rows]

  rowsNew = rowsNew.slice().sort((nIdA, nIdB) => {
    const personA = persons[nodes[nIdA][key]]?.nameDisplayShort
    const personB = persons[nodes[nIdB][key]]?.nameDisplayShort
    const posA = nodes[nIdA].position
    const posB = nodes[nIdB].position

    const comparison =
      !personA || personA > personB
        ? 1
        : !personB || personB > personA
        ? -1
        : posIsAfter(posA, posB)
        ? 1
        : -1

    return comparison
  })

  return rowsNew
}

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Evaluates if pos1 is after pos2.
 * @param {array} pos1
 * @param {array} pos2
 * @returns {bool} isAfter
 */
export function posIsAfter(pos1, pos2) {
  if (pos1.length === 0) {
    return false
  }
  if (pos2.length === 0) {
    return true
  }
  const pos1Int = parseInt(
    pos1.map((el) => el.toString().padStart(3, "0")).join("")
  )
  const pos2Int = parseInt(
    pos2.map((el) => el.toString().padStart(3, "0")).join("")
  )
  const pos1Reziproke = 1000000 / pos1Int
  const pos2Reziproke = 1000000 / pos2Int
  const isAfter = pos1Reziproke < pos2Reziproke

  return isAfter
}

/**
 * v1.0.0: (c) Jasper Anders
 *
 * recalculation of the whole tree doesn't make sense
 * if only a single node was expanded. Instead keep the
 * existing listNodesFolded and expand the changed Node. The node must
 * be isFolded===false first.
 * @param {object} nodes
 * @param {string} nId
 * @param {array} listNodesFolded
 * @return {array} listNodesFoldedNew
 */
export function nodesUnfold(nodes, nId, listNodesFolded) {
  // find index of the node that should be opened
  const indexUnfold = listNodesFolded.indexOf(nId)
  // create copy of listNodesFolded
  let listNodesFoldedNew = [...listNodesFolded]
  // insert nodesRowsGen
  listNodesFoldedNew.splice(indexUnfold, 1, nodesRowsGen(nodes, nId))
  // flatten array. E.g: [a, [b, c]] -> [a, b, c]
  return listNodesFoldedNew.flat()
}
