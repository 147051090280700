import React, { useRef } from "react"
import { connect } from "react-redux"
import { Tooltip } from "@chakra-ui/react"

import {
  useDisclosure,
  Flex,
  Box,
  Switch,
  FormControl,
  FormLabel,
  Button,
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react"

import {
  resetProject,
  resetSheet,
  isSlimOnUpdate,
  isDetailsOnUpdate,
  isKanbanOnUpdate,
} from "../../redux/actions/project"

const SwitchDetails = ({ params, dispatch }) => {
  //=================================================================
  const {
    isOpen: isOpenProject,
    onOpen: onOpenProject,
    onClose: onCloseProject,
  } = useDisclosure()

  const {
    isOpen: isOpenSheet,
    onOpen: onOpenSheet,
    onClose: onCloseSheet,
  } = useDisclosure()

  const cancelRefProject = useRef()
  const cancelRefSheet = useRef()

  const { isSlimOn, isDetailsOn, isKanbanOn } = params

  //-----------------------------------------------------------------

  const onClickResetProject = () => {
    dispatch(resetProject())
    onCloseProject()
  }

  const onClickResetSheet = () => {
    dispatch(resetSheet())
    onCloseSheet()
  }

  const onChangeSlim = (e) => {
    dispatch(isSlimOnUpdate())
  }

  const onChangeDetails = (e) => {
    dispatch(isDetailsOnUpdate())
  }

  const onChangeKanban = (e) => {
    dispatch(isKanbanOnUpdate())
  }

  //=================================================================
  return (
    <>
      <Flex>
        <Tooltip
          label="Resets the whole project including the Setup, the Persons, and the Status Sheet."
          placement="top-end"
          hasArrow
        >
          <Button
            onClick={onOpenProject}
            color="white"
            bg="blue.800"
            borderRadius="5px"
            mb={2}
          >
            Reset Project
          </Button>
        </Tooltip>
        <Tooltip
          label="Keeps the project including the Setup and the Persons but resets the Status Sheet."
          placement="top-end"
          hasArrow
        >
          <Button
            onClick={onOpenSheet}
            color="white"
            bg="blue.800"
            borderRadius="5px"
            ml={1}
            mb={2}
          >
            Reset Sheet
          </Button>
        </Tooltip>
        <Box mt="1px" display="none" mx={2}>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="isSlimOn" color="blue.900" mb="0">
              Slim
            </FormLabel>
            <Switch
              id="isSlimOn"
              onChange={onChangeSlim}
              isChecked={isSlimOn}
              colorScheme="blue800"
            />
          </FormControl>
        </Box>
        <Box mt="1px" mx={2}>
          <FormControl display="flex" alignItems="center">
            <Tooltip
              label="KANBAN mode: If switched on, the Status Sheet is modified to become a VERTICAL KANBAN BOARD. It changes some headlines: DELIVERS WHAT → LISTS & CARDS, PRECS → BLOCKS. In Kanban you move CARDS (individual deliverables) between LISTS (categories & sub-categories). With moving a card to a different category you indicate a different status. Therefore, card IDs are shown that help you better identify the cards. In the KANBAN project management methodology it is especially important to observe WIP-Limits."
              placement="top-end"
              hasArrow
            >
              <FormLabel htmlFor="isKanbanOn" color="blue.900" mb="0">
                KANBAN
              </FormLabel>
            </Tooltip>
            <Switch
              id="isKanbanOn"
              onChange={onChangeKanban}
              isChecked={isKanbanOn}
              colorScheme="blue800"
            />
          </FormControl>
        </Box>
        <Box mt="1px">
          <FormControl display="flex" alignItems="center">
            <Tooltip
              label="Details: this shows additional but not essential information, such as FROM when work on a deliverable could start at the earliest, what the available time SPAN is and what time PROJECTION for completion is expected. It also shows an ID and MORE PRECS should they not fit into the line above."
              placement="top-end"
              hasArrow
            >
              <FormLabel htmlFor="isDetailsOn" color="blue.900" mb="0">
                Details
              </FormLabel>
            </Tooltip>
            <Switch
              id="isDetailsOn"
              onChange={onChangeDetails}
              isChecked={isDetailsOn}
              colorScheme="blue800"
            />
          </FormControl>
        </Box>
      </Flex>

      <AlertDialog
        leastDestructiveRef={cancelRefProject}
        onClose={onCloseProject}
        isOpen={isOpenProject}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Confirm Reset Project?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to reset your whole project? All your
            deliverables and all persons will be deleted.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRefProject} onClick={onCloseProject}>
              Cancel
            </Button>
            <Button color="white" bg="red" ml={3} onClick={onClickResetProject}>
              Reset
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        leastDestructiveRef={cancelRefSheet}
        onClose={onCloseSheet}
        isOpen={isOpenSheet}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Confirm Reset Sheet?</AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to reset your sheet? Persons and general
            project parameters will be kept, but all your deliverables will be
            deleted.
          </AlertDialogBody>
          <AlertDialogCloseButton />

          <AlertDialogFooter>
            <Button ref={cancelRefSheet} onClick={onCloseSheet}>
              Cancel
            </Button>
            <Button color="white" bg="red" ml={3} onClick={onClickResetSheet}>
              Reset
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    params: state.project.params,
  }
}

export default connect(mapStateToProps)(SwitchDetails)
