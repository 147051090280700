import React from "react"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"

const ButtonText = ({ children, ...rest }) => (
  <chakra.button type="button" textAlign="center" outline="none" {...rest}>
    {children}
  </chakra.button>
)

ButtonText.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.object,
}

export default ButtonText
