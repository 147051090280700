import produce from "immer"
import cloneDeep from "lodash.clonedeep"
// import { deepDiff } from "../../functions/utils/deepDiff"

import merge from "lodash.merge"
import isWeekend from "date-fns/isWeekend"
import addBusinessDays from "date-fns/addBusinessDays"
import startOfDay from "date-fns/startOfDay"
import parseISO from "date-fns/parseISO"
import isAfter from "date-fns/isAfter"
import isBefore from "date-fns/isBefore"
import isSameDay from "date-fns/isSameDay"
import format from "date-fns/format"

import {
  nodeActiveUpdate,
  nodeNotActiveUpdate,
  projectUpload,
  resetProject,
  resetSheet,
  selectStatusUpdate,
  statusCurrentUpdate,
  statusLatestDelete,
  personAdd,
  personRemove,
  personUpdate,
  selectPersonUpdate,
  selectPersonsUpdate,
  SelectRequestsUpdate,
  selectRolesUpdate,
  rowCreate,
  rowCreateAsChild,
  rowDelete,
  rowToggleAgg,
  rowMoveIn,
  rowMoveOut,
  rowMoveUpDown,
  rowFold,
  rowHighlight,
  rowMark,
  rowUpdate,
  rowForecast,
  rowQuality,
  isByWhenPinnedUpdate,
  isSlimOnUpdate,
  isDetailsOnUpdate,
  isKanbanOnUpdate,
  isNameFirstPreferredUpdate,
  formRatiosUpdate,
  issueCheckUpdate,
  issueTLUpdate,
  formDatesUpdate,
  formSetupUpdate,
  formParamsUpdate,
  messageFromProjectDelete,
  isWhoSortedByNameUpdate,
  isToWhomSortedByNameUpdate,
} from "../actions/project"

import {
  nodesDependenciesSet,
  positionsArrToPrecedentsStr,
  precedentsStrToPositionsArr,
  nodesDependenciesDelete,
} from "../../functions/nodes/nodesDependencies"

import { projectInitialStateSet } from "../../redux/states/projectInitialState"

import nodeCreate from "../../functions/nodes/nodeCreate"
import { nodesTreeDepsCircularCheckAll } from "../../functions/nodes/nodesTreeDepsClean"
import {
  nodeNewAddAfter,
  nodeNewAddAsChild,
} from "../../functions/nodes/nodeNewAdd"
import nodesDelete from "../../functions/nodes/nodesDelete"
import { nodesRowsGen, rowsGenSorted } from "../../functions/nodes/nodesRowsGen"
import { moveIn, moveOut, moveUpDown } from "../../functions/nodes/nodeMoves"
import { nodesTreeDownUp } from "../../functions/nodes/nodesAggregations"

import personCreate from "../../functions/persons/personCreate"
import {
  personRemoveFromNodes,
  personRemoveFromSetup,
} from "../../functions/persons/personRemoveFromX"
import {
  namesGen,
  nameDisplayShortGen,
  nameDisplayLongGen,
  personsDisplayNameGen,
} from "../../functions/persons/namesGen"
import {
  rolesToPersonsSet_Setup,
  rolesToPersonsSet_Person,
  rolesToPersonsSet_Persons,
} from "../../functions/persons/rolesToPersonsSet"

import {
  startOfBusinessDay,
  endOfBusinessDay,
  startOfBusinessDayISO,
  endOfBusinessDayISO,
} from "../../functions/timeHandler/bordersOfBusinessDay"

import { durStrToDur } from "../../functions/timeHandler/durations"

import locToISO from "../../functions/timeHandler/locToISO"

import nanoId from "../../functions/utils/nanoid"

import { ROOT } from "../../const/globals"

const project = (slice = [], action) => {
  //=================================================================
  let sliceNext = slice

  const { payload } = action

  let statusDateCurrent = sliceNext && sliceNext.statusDateCurrent

  if (sliceNext && sliceNext.isResetForm) {
    sliceNext = produce(sliceNext, (sliceDraft) => {
      sliceDraft.isResetForm = false
    })
  }

  if (
    sliceNext &&
    sliceNext.messageFromProject &&
    sliceNext.messageFromProject !== ""
  ) {
    sliceNext = produce(sliceNext, (sliceDraft) => {
      sliceDraft.messageFromProject = ""
    })
  }

  //================================================================
  switch (action.type) {
    // UPLOAD --------------------------------------------------------
    case projectUpload.type:
      if (payload.warning === "lowerAPI") {
        sliceNext = produce(sliceNext, (sliceDraft) => {
          sliceDraft = merge(sliceDraft, payload.project)
        })
      } else {
        // TODO: do not understand, why this cannot go via produce()???
        // It seems that you need to keep the proxy values somehow.
        sliceNext = payload.project
      }

      return sliceNext
    // RESET --------------------------------------------------------
    case resetProject.type:
      sliceNext = projectInitialStateSet()
      sliceNext.isResetForm = true
      return sliceNext

    case resetSheet.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let theDay = isWeekend(new Date(Date.now()))
          ? addBusinessDays(new Date(Date.now()), 1)
          : new Date(Date.now())

        let _node1 = nodeCreate({
          paId: ROOT,
          fromWhen: startOfBusinessDayISO(theDay, "9"),
          byWhen: endOfBusinessDayISO(theDay, "17"),
        })
        _node1.fromWhenEarliest = _node1.fromWhen
        _node1.isAggregate = true

        let _root_ = nodeCreate({
          nId: ROOT,
          position: [],
          children: [_node1.nId],
          fromWhen: _node1.fromWhen,
          byWhen: _node1.byWhen,
        })
        _root_.fromWhenEarliest = _node1.fromWhen
        _root_.isAggregate = true

        let _node1_1 = nodeCreate({
          paId: _node1.nId,
          position: [1, 1],
          fromWhen: _node1.fromWhen,
          byWhen: _node1.byWhen,
        })
        _node1_1.fromWhenEarliest = _node1.fromWhen
        _node1.children = [_node1_1.nId]

        sliceDraft.statuses[statusDateCurrent].nodes = {}
        sliceDraft.statuses[statusDateCurrent].nodes = {
          _root_,
          [_node1.nId]: _node1,
          [_node1_1.nId]: _node1_1,
        }
        sliceDraft.statuses[statusDateCurrent].nodeActive = ""
        sliceDraft.statuses[statusDateCurrent].rows = [
          ROOT,
          _node1.nId,
          _node1_1.nId,
        ]

        // initial span, projection, slack
        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )

        sliceDraft.isResetForm = true
      })

      return sliceNext
    // DATES --------------------------------------------------------
    case statusCurrentUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statusDateCurrent = payload.statusDateCurrent
        sliceDraft.isResetForm = true
      })
      return sliceNext

    case statusLatestDelete.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const dateStatusLast = sliceDraft.statusDatesList[0]
        sliceDraft.statusDatesList.shift()
        sliceDraft.statusDateCurrent = sliceDraft.statusDatesList[0]
        delete sliceDraft.statuses[dateStatusLast]
        sliceDraft.isResetForm = true
      })
      return sliceNext

    // PERSONS --------------------------------------------------------
    case personAdd.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const peId = nanoId()
        sliceDraft.statuses[statusDateCurrent].persons[peId] =
          personCreate(peId)
      })
      return sliceNext

    case personRemove.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        delete sliceDraft.statuses[statusDateCurrent].persons[payload.peId]
        sliceDraft.statuses[statusDateCurrent].nodes = personRemoveFromNodes(
          sliceDraft.statuses[statusDateCurrent].nodes,
          payload.peId
        )
        sliceDraft.statuses[statusDateCurrent].setup = personRemoveFromSetup(
          sliceDraft.statuses[statusDateCurrent].setup,
          payload.peId
        )

        sliceDraft.messageFromProject =
          "WARNING: The person you removed might have been responsible for certain roles or deliverables. Please check for not assigned spaces."

        sliceDraft.isResetForm = true
      })
      return sliceNext

    case personUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].persons[payload.peId] = {
          ...sliceDraft.statuses[statusDateCurrent].persons[payload.peId],
          ...payload.data,
        }

        sliceDraft.statuses[statusDateCurrent].persons[
          payload.peId
        ].nameDisplayShort = nameDisplayShortGen(
          payload.data.nameFirst,
          payload.data.nameLast,
          sliceDraft.params.isNameFirstPreferred
        )
        sliceDraft.statuses[statusDateCurrent].persons[
          payload.peId
        ].nameDisplayLong = nameDisplayLongGen(
          payload.data.nameFirst,
          payload.data.nameLast,
          payload.data.academicTitle
        )
        sliceDraft.statuses[statusDateCurrent].persons[
          payload.peId
        ].nameInitials = (
          payload.data.nameFirst[0] + payload.data.nameLast[0]
        ).toUpperCase()

        sliceDraft.isResetForm = true
      })
      return sliceNext

    case selectPersonUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        if (payload.isNew) {
          // create a new person
          const peId = nanoId()
          sliceDraft.statuses[statusDateCurrent].persons[peId] =
            personCreate(peId)
          const names = namesGen(
            payload.personName,
            sliceDraft.params.isNameFirstPreferred
          )

          sliceDraft.statuses[statusDateCurrent].persons[peId].nameFirst =
            names.nameFirst
          sliceDraft.statuses[statusDateCurrent].persons[peId].nameLast =
            names.nameLast
          sliceDraft.statuses[statusDateCurrent].persons[
            peId
          ].nameDisplayShort = names.nameDisplayShort
          sliceDraft.statuses[statusDateCurrent].persons[peId].nameDisplayLong =
            names.nameDisplayLong
          sliceDraft.statuses[statusDateCurrent].persons[peId].nameInitials =
            names.nameInitials

          // add label
          payload.value = peId
        }

        // wId is either "_setup" or it contains the nId
        if (payload.wId === "_setup") {
          sliceDraft.statuses[statusDateCurrent].setup[payload.name] =
            payload.value

          sliceDraft.statuses[statusDateCurrent] = rolesToPersonsSet_Setup(
            sliceDraft.rolesStandard,
            sliceDraft.statuses[statusDateCurrent],
            payload.name
          )
        } else {
          sliceDraft.statuses[statusDateCurrent].nodes[payload.wId][
            payload.name
          ] = payload.value
        }
      })
      return sliceNext

    case selectPersonsUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let selected = payload.selected.map((person) => {
          if (person.__isNew__) {
            const peId = nanoId()
            sliceDraft.statuses[statusDateCurrent].persons[peId] =
              personCreate(peId)
            const names = namesGen(
              person.label.trim(),
              sliceDraft.params.isNameFirstPreferred
            )

            sliceDraft.statuses[statusDateCurrent].persons[peId].nameFirst =
              names.nameFirst
            sliceDraft.statuses[statusDateCurrent].persons[peId].nameLast =
              names.nameLast
            sliceDraft.statuses[statusDateCurrent].persons[
              peId
            ].nameDisplayShort = names.nameDisplayShort
            sliceDraft.statuses[statusDateCurrent].persons[
              peId
            ].nameDisplayLong = names.nameDisplayLong
            sliceDraft.statuses[statusDateCurrent].persons[peId].nameInitials =
              names.nameInitials
            return { value: peId, label: names.nameDisplayShort }
          }
          return person
        })

        sliceDraft.statuses[statusDateCurrent].setup[payload.name] = selected

        let roId =
          payload.name === "membersTeam"
            ? "memberTeam"
            : payload.name === "membersStC"
            ? "memberStC"
            : ""

        let selectedMulti = selected.map((el) => ({
          roId,
          peId: el.value,
        }))

        sliceDraft.statuses[statusDateCurrent] = rolesToPersonsSet_Persons(
          sliceDraft.rolesStandard,
          sliceDraft.statuses[statusDateCurrent],
          selectedMulti,
          roId
        )
      })
      return sliceNext

    case selectRolesUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let selected = payload.selected.map((role) => {
          if (role.__isNew__) {
            const roId = nanoId()
            const todoNew = { value: roId, label: role.label.trim() }
            if (
              sliceDraft.statuses[statusDateCurrent].rolesAdded === undefined
            ) {
              sliceDraft.statuses[statusDateCurrent].rolesAdded = []
            }

            sliceDraft.statuses[statusDateCurrent].rolesAdded.push(todoNew)
            return todoNew
          }
          return role
        })
        let peId = payload.wId
        sliceDraft.statuses[statusDateCurrent].persons[peId].roles = selected

        let rolesToPersonsSelected = selected.map((el) => ({
          roId: el.value,
          peId,
        }))

        sliceDraft.statuses[statusDateCurrent] = rolesToPersonsSet_Person(
          sliceDraft.rolesStandard,
          sliceDraft.statuses[statusDateCurrent],
          rolesToPersonsSelected,
          peId
        )
      })
      return sliceNext

    case SelectRequestsUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let selected = payload.selected.map((todo) => {
          if (todo.__isNew__) {
            const tId = nanoId()
            const todoNew = { value: tId, label: todo.label.trim() }
            sliceDraft.statuses[statusDateCurrent].requestsAdded.push(todoNew)
            return todoNew
          }
          return todo
        })
        sliceDraft.statuses[statusDateCurrent].ratios.requests = selected
      })
      return sliceNext

    // NODE_ACTIVE--------------------------------------------------
    case nodeActiveUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        if (sliceDraft.statuses[statusDateCurrent].nodeActive !== "") {
          sliceDraft.statuses[statusDateCurrent].nodes[
            sliceDraft.statuses[statusDateCurrent].nodeActive
          ].isActive = false
        }
        sliceDraft.statuses[statusDateCurrent].nodeActive = payload.nId
        sliceDraft.statuses[statusDateCurrent].nodes[
          payload.nId
        ].isActive = true
      })
      return sliceNext

    case nodeNotActiveUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        if (sliceDraft.statuses[statusDateCurrent].nodeActive !== "") {
          sliceDraft.statuses[statusDateCurrent].nodes[
            sliceDraft.statuses[statusDateCurrent].nodeActive
          ].isActive = false
          sliceDraft.statuses[statusDateCurrent].nodeActive = ""
        }
      })
      return sliceNext

    // ROWS --------------------------------------------------------
    case rowCreate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const { nodesNew, nIdNew } = nodeNewAddAfter(
          sliceDraft.statuses[statusDateCurrent].nodes,
          payload.nIdBefore
        )

        sliceDraft.statuses[statusDateCurrent].nodes = nodesNew

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )

        sliceDraft.statuses[statusDateCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusDateCurrent].nodes
        )

        sliceDraft.statuses[statusDateCurrent].nodes[
          payload.nIdBefore
        ].isActive = false

        sliceDraft.statuses[statusDateCurrent].nodes[nIdNew].isActive = true
        sliceDraft.statuses[statusDateCurrent].nodeActive = nIdNew
      })
      return sliceNext

    case rowCreateAsChild.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const { nodesNew, nIdNew } = nodeNewAddAsChild(
          sliceDraft.statuses[statusDateCurrent].nodes,
          payload.paId
        )

        sliceDraft.statuses[statusDateCurrent].nodes = nodesNew

        sliceDraft.statuses[statusDateCurrent].nodes[
          payload.paId
        ].isFolded = false

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )

        sliceDraft.statuses[statusDateCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusDateCurrent].nodes
        )

        sliceDraft.statuses[statusDateCurrent].nodes[
          payload.paId
        ].isActive = false

        sliceDraft.statuses[statusDateCurrent].nodes[nIdNew].isActive = true

        sliceDraft.statuses[statusDateCurrent].nodeActive = nIdNew
      })
      return sliceNext

    case rowDelete.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const indexNId = sliceDraft.statuses[statusDateCurrent].rows.indexOf(
          payload.nId
        )
        const neighborTopId =
          sliceDraft.statuses[statusDateCurrent].rows[indexNId - 1]
        sliceDraft.statuses[statusDateCurrent].nodes[
          neighborTopId
        ].isActive = true

        sliceDraft.statuses[statusDateCurrent].nodeActive = neighborTopId

        sliceDraft.statuses[statusDateCurrent].nodes = nodesDelete(
          sliceDraft.statuses[statusDateCurrent].nodes,
          payload.nId,
          true
        )

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )

        sliceDraft.statuses[statusDateCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusDateCurrent].nodes
        )
      })
      return sliceNext

    case rowUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].nodes[payload.nId] = {
          ...sliceDraft.statuses[statusDateCurrent].nodes[payload.nId],
          ...payload.data,
        }

        // accumulate spent only if change in spent
        if (payload.data.wipLimit) {
          sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].wipLimit =
            payload.data.wipLimit
        }

        // update by-when if not before project dateStart
        if (payload.data.byWhen) {
          let byWhenDate = parseISO(payload.data.byWhen)
          if (isWeekend(byWhenDate)) {
            byWhenDate = addBusinessDays(byWhenDate, 1)
          }
          // project dateStart
          const dateStartDate = parseISO(
            sliceDraft.statuses[statusDateCurrent].nodes[ROOT].fromWhen
          )

          // byWhen cannot be before the project dateStart
          if (isBefore(byWhenDate, dateStartDate)) {
            sliceDraft.messageFromProject =
              "ERROR: BY WHEN dates cannot be before the project Start Date. You can change the project Start Date on the Setup page."
            const dateStartDayEndDate = endOfBusinessDay(
              parseISO(
                sliceDraft.statuses[statusDateCurrent].nodes[ROOT].fromWhen
              )
            )
            sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].byWhen =
              dateStartDayEndDate.toISOString()
          }
          // byWhen is correctly after project start
          else {
            sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].byWhen =
              byWhenDate.toISOString()
            // if isByWhenPinned set byWhen also to byWhenLatest
            if (
              sliceDraft.statuses[statusDateCurrent].nodes[payload.nId]
                .isByWhenPinned
            ) {
              sliceDraft.statuses[statusDateCurrent].nodes[
                payload.nId
              ].byWhenLatest =
                sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].byWhen
            }
          }
        }

        // accumulate spent only if change in spent
        if (payload.data.spent) {
          sliceDraft.statuses[statusDateCurrent].nodes[payload.nId] = {
            ...sliceDraft.statuses[statusDateCurrent].nodes[payload.nId],
            spent: durStrToDur(payload.data.spent),
          }
        }

        // Update Precedents
        // typeof because empty strings are also an information
        if (typeof payload.data.precedentsStr !== "undefined") {
          // save in case of circular dependencies
          const nodesTemp = sliceDraft.statuses[statusDateCurrent].nodes

          // bring into standard form for comparison
          const payloadPrecedentsStr = positionsArrToPrecedentsStr(
            precedentsStrToPositionsArr(payload.data.precedentsStr)
          )

          // set new precedents and dependents
          // does not allow ancestors or descendants to be precedents
          // .precedentsStr is newly generated
          sliceDraft.statuses[statusDateCurrent].nodes = nodesDependenciesSet(
            sliceDraft.statuses[statusDateCurrent].nodes,
            payload.nId,
            payloadPrecedentsStr
          )

          if (
            nodesTreeDepsCircularCheckAll(
              sliceDraft.statuses[statusDateCurrent].nodes,
              sliceDraft.statuses[statusDateCurrent].rows
            )
          ) {
            sliceDraft.messageFromProject =
              "WARNING: You have caused a circular reference or duplicate. This is not allowed. Your input was not accepted and not taken over."
            sliceDraft.statuses[statusDateCurrent].nodes = nodesTemp
          } else {
            if (
              sliceDraft.statuses[statusDateCurrent].nodes[payload.nId]
                .precedentsStr !== payloadPrecedentsStr
            ) {
              sliceDraft.messageFromProject =
                "WARNING: You have used ancestors, descendants, self-referencing or non-existing positions to be precedents. Such dependencies are not possible and have been removed."
            }
          }
        }

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )

        sliceDraft.isResetForm = true
      })
      return sliceNext

    case rowMark.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        if (sliceDraft.statuses[statusDateCurrent].nodeActive !== "") {
          sliceDraft.statuses[statusDateCurrent].nodes[
            sliceDraft.statuses[statusDateCurrent].nodeActive
          ].isActive = false
        }
        sliceDraft.statuses[statusDateCurrent].nodeActive = payload.nId
        sliceDraft.statuses[statusDateCurrent].nodes[
          payload.nId
        ].isActive = true

        sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].isUnresolved =
          payload.isUnresolved
        sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].isIgnored =
          payload.isIgnored

        if (
          sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].isUnresolved
        ) {
          sliceDraft.statuses[statusDateCurrent].ratios.unresolvedIssues += 1
        } else if (
          sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].isIgnored
        ) {
          sliceDraft.statuses[statusDateCurrent].ratios.unresolvedIssues -= 1
        }

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )
      })
      return sliceNext

    case rowToggleAgg.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].isAggregate =
          !sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].isAggregate
      })
      return sliceNext

    case rowMoveIn.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].nodes = moveIn(
          sliceDraft.statuses[statusDateCurrent].nodes,
          payload.nId,
          sliceDraft.statuses[statusDateCurrent].rows
        )

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )

        sliceDraft.statuses[statusDateCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusDateCurrent].nodes
        )

        sliceDraft.formReset = true
      })
      return sliceNext

    case rowMoveOut.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].nodes = moveOut(
          sliceDraft.statuses[statusDateCurrent].nodes,
          payload.nId
        )

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )

        sliceDraft.statuses[statusDateCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusDateCurrent].nodes
        )

        sliceDraft.formReset = true
      })
      return sliceNext

    case rowMoveUpDown.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].nodes = moveUpDown(
          sliceDraft.statuses[statusDateCurrent].nodes,
          payload.nId,
          sliceDraft.statuses[statusDateCurrent].rows,
          payload.isMoveUp
        )

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )

        if (
          nodesTreeDepsCircularCheckAll(
            sliceDraft.statuses[statusDateCurrent].nodes,
            sliceDraft.statuses[statusDateCurrent].rows
          )
        ) {
          // TODO: check for circular dependencies. Remove when necessary.!!!
          // Test if this code still has a bug???
          console.log(
            "circular reference in node ",
            sliceDraft.statuses[statusDateCurrent].nodeActive
          )
          sliceDraft.statuses[statusDateCurrent].nodes =
            nodesDependenciesDelete(
              sliceDraft.statuses[statusDateCurrent].nodes,
              sliceDraft.statuses[statusDateCurrent].nodeActive
            )

          sliceDraft.messageFromProject =
            "WARNING: You have caused a circular reference. This is not possible and you will likely see odd behavior. Please update your precedents."
        }

        sliceDraft.statuses[statusDateCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusDateCurrent].nodes
        )

        sliceDraft.formReset = true
      })
      return sliceNext

    case rowFold.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].isFolded =
          !sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].isFolded

        sliceDraft.statuses[statusDateCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusDateCurrent].nodes
        )

        sliceDraft.formReset = true
      })
      return sliceNext

    case rowHighlight.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        if (
          sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].isActive
        ) {
          sliceDraft.statuses[statusDateCurrent].nodes[
            payload.nId
          ].isHighlighted =
            !sliceDraft.statuses[statusDateCurrent].nodes[payload.nId]
              .isHighlighted
        }
      })
      return sliceNext

    case rowForecast.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        if (payload.isForward) {
          sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].forecast =
            (sliceDraft.statuses[statusDateCurrent].nodes[payload.nId]
              .forecast +
              1) %
            5
        } else {
          sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].forecast =
            (sliceDraft.statuses[statusDateCurrent].nodes[payload.nId]
              .forecast -
              1) %
            5
        }

        if (sliceDraft.statuses[statusDateCurrent].nodeActive !== "") {
          sliceDraft.statuses[statusDateCurrent].nodes[
            sliceDraft.statuses[statusDateCurrent].nodeActive
          ].isActive = false
        }
        sliceDraft.statuses[statusDateCurrent].nodeActive = payload.nId
        sliceDraft.statuses[statusDateCurrent].nodes[
          payload.nId
        ].isActive = true

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )
      })
      return sliceNext

    case rowQuality.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        if (payload.isForward) {
          sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].quality =
            (sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].quality +
              1) %
            5
        } else {
          sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].quality =
            (sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].quality -
              1) %
            5
        }

        if (sliceDraft.statuses[statusDateCurrent].nodeActive !== "") {
          sliceDraft.statuses[statusDateCurrent].nodes[
            sliceDraft.statuses[statusDateCurrent].nodeActive
          ].isActive = false
        }
        sliceDraft.statuses[statusDateCurrent].nodeActive = payload.nId
        sliceDraft.statuses[statusDateCurrent].nodes[
          payload.nId
        ].isActive = true

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )
      })
      return sliceNext

    // PIN -------------------------------------------------------
    case isByWhenPinnedUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].nodes[
          payload.nId
        ].isByWhenPinned =
          !sliceDraft.statuses[statusDateCurrent].nodes[payload.nId]
            .isByWhenPinned

        if (
          sliceDraft.statuses[statusDateCurrent].nodes[payload.nId]
            .isByWhenPinned
        ) {
          sliceDraft.statuses[statusDateCurrent].nodes[
            payload.nId
          ].byWhenLatest =
            sliceDraft.statuses[statusDateCurrent].nodes[payload.nId].byWhen
        } else {
          sliceDraft.statuses[statusDateCurrent].nodes[
            payload.nId
          ].byWhenLatest = ""
        }

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )

        sliceDraft.isResetForm = true
      })

      return sliceNext

    // SETTINGS -------------------------------------------------------

    case isWhoSortedByNameUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.isWhoSortedByName =
          !sliceDraft.params.isWhoSortedByName
        sliceDraft.params.isToWhomSortedByName = false

        sliceDraft.statuses[statusDateCurrent].rows = rowsGenSorted(
          sliceDraft.statuses[statusDateCurrent],
          sliceDraft.params
        )
      })
      return sliceNext

    case isToWhomSortedByNameUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.isToWhomSortedByName =
          !sliceDraft.params.isToWhomSortedByName
        sliceDraft.params.isWhoSortedByName = false

        sliceDraft.statuses[statusDateCurrent].rows = rowsGenSorted(
          sliceDraft.statuses[statusDateCurrent],
          sliceDraft.params
        )
      })
      return sliceNext

    case isSlimOnUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.isSlimOn = !sliceDraft.params.isSlimOn
      })
      return sliceNext

    case isDetailsOnUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.isDetailsOn = !sliceDraft.params.isDetailsOn
      })
      return sliceNext

    case isKanbanOnUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.isKanbanOn = !sliceDraft.params.isKanbanOn
      })
      return sliceNext

    case isNameFirstPreferredUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.isNameFirstPreferred =
          !sliceDraft.params.isNameFirstPreferred
        Object.keys(sliceDraft.statuses).forEach(
          (dId) =>
            (sliceDraft.statuses[dId].persons = personsDisplayNameGen(
              sliceDraft.statuses[dId].persons,
              sliceDraft.params.isNameFirstPreferred
            ))
        )
      })
      return sliceNext

    // FORMS -------------------------------------------------------
    case formSetupUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].setup = {
          ...sliceDraft.statuses[statusDateCurrent].setup,
          ...payload.data,
        }
      })

      return sliceNext

    case selectStatusUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].setup.statusCompleted =
          payload.statusCompleted
      })
      return sliceNext

    case formDatesUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        // one can only set a new statusDateNext when the statusDateCurrent is set isCompleted
        if (
          sliceDraft.statuses[statusDateCurrent].setup.statusCompleted ===
            "Is Completed" &&
          payload.data.statusDateNext !== ""
        ) {
          let dateStatusNextDate = startOfBusinessDay(
            parseISO(locToISO(payload.data.statusDateNext, statusDateCurrent))
          )

          if (isWeekend(dateStatusNextDate)) {
            dateStatusNextDate = addBusinessDays(dateStatusNextDate, 1)
          }

          // must be at least one day after last status day
          let dateStatusCurrentPlus1 = addBusinessDays(
            parseISO(statusDateCurrent),
            1
          )

          if (
            !isSameDay(dateStatusNextDate, dateStatusCurrentPlus1) &&
            !isAfter(dateStatusNextDate, dateStatusCurrentPlus1)
          ) {
            sliceDraft.messageFromProject =
              "ERROR: Next status date must be at least one day after previous one."
          }
          // new Status Date accepted
          else {
            payload.data.statusDateNext = dateStatusNextDate.toISOString()

            sliceDraft.statuses[payload.data.statusDateNext] = cloneDeep(
              sliceDraft.statuses[statusDateCurrent]
            )

            sliceDraft.statuses[statusDateCurrent].setup.isStatusLocked = true
            sliceDraft.statusDatesList.unshift(payload.data.statusDateNext)

            sliceDraft.statuses[payload.data.statusDateNext].dId =
              payload.data.statusDateNext

            // from Current to Next
            sliceDraft.statusDateCurrent = payload.data.statusDateNext
            statusDateCurrent = payload.data.statusDateNext
            sliceDraft.statuses[statusDateCurrent].setup.isStatusLocked = false

            sliceDraft.statusDateNext = ""
            sliceDraft.statuses[statusDateCurrent].setup.statusCompleted =
              "In Progress"

            sliceDraft.messageFromProject = `Success: Current Status Date was changed to ${format(
              parseISO(statusDateCurrent),
              "yyyy-MM-dd"
            )}.`

            // re-calc
            sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
              sliceDraft.statuses[statusDateCurrent].nodes,
              ROOT,
              statusDateCurrent
            )
          }
        }

        // the remaining input fields in the form
        const isStatusDateInitial =
          sliceDraft.statusDatesList.indexOf(statusDateCurrent) ===
          sliceDraft.statusDatesList.length - 1

        if (isStatusDateInitial) {
          // check if dateStart is on a weekend
          let dateStartDate = parseISO(
            locToISO(
              payload.data.dateStart,
              statusDateCurrent,
              true // ← startOfBusinessDay
            )
          )

          if (isWeekend(dateStartDate)) {
            dateStartDate = addBusinessDays(dateStartDate, 1)
          }

          sliceDraft.statuses[statusDateCurrent].nodes[ROOT].fromWhenEarliest =
            dateStartDate.toISOString()

          sliceDraft.statuses[statusDateCurrent].nodes[ROOT].fromWhen =
            dateStartDate.toISOString()
        }

        if (payload.data.statusDateNextPlanned !== "") {
          // check if statusDateNextPlanned is on a weekend
          let dateStatusNextPlannedDate = startOfDay(
            parseISO(
              locToISO(payload.data.statusDateNextPlanned, statusDateCurrent)
            )
          )
          if (isWeekend(dateStatusNextPlannedDate)) {
            dateStatusNextPlannedDate = addBusinessDays(
              dateStatusNextPlannedDate,
              1
            )
          }

          sliceDraft.statuses[statusDateCurrent].statusDateNextPlanned =
            dateStatusNextPlannedDate.toISOString()
        }

        sliceDraft.statuses[statusDateCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusDateCurrent].nodes,
          ROOT,
          statusDateCurrent
        )

        // sliceDraft.params.dayStart = payload.data.dayStart
        // sliceDraft.params.dayEnd = payload.data.dayEnd
        sliceDraft.isResetForm = true
      })

      return sliceNext

    case formRatiosUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].ratios = {
          ...sliceDraft.statuses[statusDateCurrent].ratios,
          ...payload.data,
        }
      })
      return sliceNext

    case issueCheckUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].ratios[payload.name] =
          !sliceDraft.statuses[statusDateCurrent].ratios[payload.name]
      })
      return sliceNext

    case issueTLUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusDateCurrent].ratios[payload.name] =
          (sliceDraft.statuses[statusDateCurrent].ratios[payload.name] + 1) % 5
      })
      return sliceNext

    case formParamsUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.pUnresolvedIssues = payload.data.pUnresolvedIssues
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pDOCThroughDOXS = payload.data.pDOCThroughDOXS
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pDOCThroughDOTS = payload.data.pDOCThroughDOTS
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pSatisfactionCustomer =
          payload.data.pSatisfactionCustomer
            .split(",")
            .map((el) => parseInt(el))
        sliceDraft.params.pSatisfactionTeam = payload.data.pSatisfactionTeam
          .split(",")
          .map((el) => parseInt(el))
      })
      return sliceNext

    // MESSAGE -------------------------------------------------------
    case messageFromProjectDelete.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.messageFromProjectDelete = ""
        sliceDraft.isResetForm = true
      })
      return sliceNext

    // DEFAULT -------------------------------------------------------
    default:
      return sliceNext
  }
}

export default project
