import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import isEqual from "date-fns/isEqual"
import isAfter from "date-fns/isAfter"
import isBefore from "date-fns/isBefore"
import { startOfBusinessDay, endOfBusinessDay } from "./bordersOfBusinessDay"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Makes an abbreviation from dateString taking only the Hours Start
 * @param {string} dateString
 * @param {string} statusDateCurrent
 * @returns {string} tpAbbrStr - time point abbreviation with the Hours Start
 */
export function tpAbbrHS(timePointStr, dayStart = "9") {
  let tpAbbrStr
  const tp = parseISO(timePointStr)

  if (
    isBefore(tp, startOfBusinessDay(tp, dayStart)) ||
    isEqual(tp, startOfBusinessDay(tp, dayStart))
  ) {
    tpAbbrStr = ""
  } else {
    tpAbbrStr = format(tp, "HH")
  }

  return tpAbbrStr
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Makes an abbreviation from dateString taking only the Hours End
 * @param {string} dateString
 * @param {string} statusDateCurrent
 * @returns {string} tpAbbrStr - time point abbreviation with the Hours End
 */
export function tpAbbrHE(timePointStr, dayEnd = "17") {
  let tpAbbrStr
  const tp = parseISO(timePointStr)

  if (
    isEqual(tp, endOfBusinessDay(tp, dayEnd)) ||
    isAfter(tp, endOfBusinessDay(tp, dayEnd))
  ) {
    tpAbbrStr = ""
  } else {
    tpAbbrStr = format(tp, "HH")
  }

  return tpAbbrStr
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Makes an abbreviation from a dateString without the hours
 * @param {string} dateString
 * @param {string} statusDateCurrent
 * @returns {string} tpAbbrStr - time point abbreviation string with the date only
 */
export function tpAbbrD(
  timePointStr,
  statusDateCurrent
  // TODO: use
  // formatString = "yyyy-MM-dd|HH"
) {
  if (timePointStr === "" || timePointStr === undefined) {
    timePointStr = statusDateCurrent
  }

  let tpAbbrStr
  const tp = parseISO(timePointStr)
  const tpYear = tp.getUTCFullYear()
  const tpMonth = tp.getUTCMonth() + 1 //months from 1-12

  const status = parseISO(statusDateCurrent)
  const statusYear = status.getUTCFullYear()
  const statusMonth = status.getUTCMonth() + 1 //months from 1-12

  if (tpYear === statusYear) {
    if (tpMonth === statusMonth) {
      tpAbbrStr = format(tp, "dd")
    } else {
      tpAbbrStr = format(tp, "MM-dd")
    }
  } else {
    tpAbbrStr = format(tp, "yyyy-MM-dd")
  }

  return tpAbbrStr
}
