import React, { useState, useLayoutEffect, useRef } from "react"
import { connect } from "react-redux"
import { Box } from "@chakra-ui/react"
import SheetRow from "../SheetRow"
import getNodesExtraInfos from "../../redux/reselect/getNodesExtraInfos"
import SimpleBar from "simplebar-react"

const SheetRows = ({
  projectCurrent,
  nodesExtraInfos,
  statusDateCurrent,
  params,
  isResetForm = false,
  isExamOn,
  isDebugOn,
  dispatch,
}) => {
  //=================================================================
  const { nodes, rows } = projectCurrent
  const { isStatusLocked } = projectCurrent.setup

  // let result = {}
  // Object.keys(nodes).forEach((nId) => {
  //   if (nodes[nId].precedents.length > 0 || nodes[nId].dependents.length > 0) {
  //     result[nId] = {
  //       pos: nodes[nId].position,
  //       prec: nodes[nId].precedents,
  //       deps: nodes[nId].dependents,
  //     }
  //   }
  // })
  // console.log(JSON.stringify({ result }, undefined, 2))

  //-----------------------------------------------------------------
  const [sheetHeight, setSheetHeight] = useState(12000)
  const boxRef = useRef(null)

  useLayoutEffect(() => {
    setSheetHeight(boxRef.current.clientHeight)
  }, [sheetHeight, projectCurrent])

  //=================================================================
  return (
    //
    <SimpleBar
      style={{
        minHeight: 300,
        maxHeight: sheetHeight,
      }}
    >
      <Box>
        <Box mb={16} mr={2} ref={boxRef}>
          {rows.map((nId, index) => {
            const node = { ...nodes[nId], ...nodesExtraInfos[nId] }

            return (
              <SheetRow
                key={nId}
                row={index}
                node={node}
                isStatusLocked={isStatusLocked}
                statusDateCurrent={statusDateCurrent}
                params={params}
                dispatch={dispatch}
                isResetForm={isResetForm}
                isExamOn={isExamOn}
                isDebugOn={isDebugOn}
              />
            )
          })}
        </Box>
        <Box h={sheetHeight}></Box>
      </Box>
    </SimpleBar>
  )
}

const mapStateToProps = (state) => {
  return {
    projectCurrent: state.project.statuses[state.project.statusDateCurrent],
    statusDateCurrent: state.project.statusDateCurrent,
    nodesExtraInfos: getNodesExtraInfos(state),
    params: state.project.params,
    isResetForm: state.project.isResetForm,
    isExamOn: state.app.isExamOn,
    isDebugOn: state.app.isDebugOn,
  }
}

export default connect(mapStateToProps)(SheetRows)
