import React from "react"
import { connect } from "react-redux"
import { Flex } from "@chakra-ui/react"
import getChartsData from "../../redux/reselect/getChartsData"
import ProgressChartDOC from "./ProgressChartDOC"
import ProgressChartExpenses from "./ProgressChartExpenses"
import ProgressChartDeliverables from "./ProgressChartDeliverables"
import ProgressChartIssues from "./ProgressChartIssues"
import ProgressChartPersons from "./ProgressChartPersons"
import ProgressChartSatisfaction from "./ProgressChartSatisfaction"

const ProgressCharts = ({ params, chartsData }) => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Flex flexWrap="wrap">
      <ProgressChartDOC w={["50%"]} h={["220px"]} chartsData={chartsData} />
      <ProgressChartExpenses
        w={["50%"]}
        h={["220px"]}
        chartsData={chartsData}
        params={params}
      />
      <ProgressChartDeliverables
        w={["50%"]}
        h={["220px"]}
        chartsData={chartsData}
        params={params}
      />
      <ProgressChartIssues
        w={["50%"]}
        h={["220px"]}
        chartsData={chartsData}
        params={params}
      />
      <ProgressChartPersons
        w={["50%"]}
        h={["220px"]}
        chartsData={chartsData}
        params={params}
      />
      <ProgressChartSatisfaction
        w={["50%"]}
        h={["220px"]}
        chartsData={chartsData}
        params={params}
      />
    </Flex>
  )
}

const mapStateToProps = (state) => {
  return {
    params: state.project.params,
    chartsData: getChartsData(state),
  }
}

export default connect(mapStateToProps)(ProgressCharts)
