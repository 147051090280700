import React from "react"
import { Box, Tooltip } from "@chakra-ui/react"
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipChart,
  ResponsiveContainer,
} from "recharts"
import CustomizedTick from "./CustomizedTick"
import COLORS from "../../theme/colors"
import { colorHex } from "../../functions/eval/colorCodeCalc"

const ProgressChartIssues = ({ chartsData, params, ...rest }) => {
  //=================================================================

  const isInverse = true

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Box {...rest}>
      <Box textAlign="center" style={{ position: "relative", zIndex: 1 }}>
        <Tooltip
          label="Left Bar: Poor Quality Deliverables in pieces (Π) are deliverables that are 100% completed but that show a quality of red, orange or yellow. Aggregated, unresolved or ignored deliverables are not considered and, therefore, not counted. Right Bar: Unresolved Issues in pieces (Π) are all deliverables marked as unresolved. Their bars are displayed in colors as defined on the Setup page."
          placement="top-end"
          hasArrow
        >
          <span>Poor Quality Deliverables & Unresolved Issues Π</span>
        </Tooltip>
      </Box>
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <BarChart
          width={500}
          height={300}
          data={chartsData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 35,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="category"
            dataKey="dateMMdd"
            interval={"personsStartEnd"}
            tick={<CustomizedTick />}
          />
          <YAxis allowDecimals={false} />
          <TooltipChart />
          <Bar
            dataKey="deliverablesQualityRed"
            name="Deliverables Quality Red"
            fill={COLORS.red[500]}
            barSize={10}
            stackId="a"
          />
          <Bar
            dataKey="deliverablesQualityOrange"
            name="Deliverables Quality Orange"
            fill={COLORS.orange[500]}
            barSize={10}
            stackId="a"
          />
          <Bar
            dataKey="deliverablesQualityYellow"
            name="Deliverables Quality Yellow"
            fill={COLORS.yellow[500]}
            barSize={10}
            stackId="a"
          />
          <Bar
            dataKey="deliverablesUnresolved"
            name="Deliverables Unresolved"
            barSize={10}
          >
            {chartsData.map((entry, index) => (
              <Cell
                key={index}
                fill={colorHex(
                  entry.deliverablesUnresolved,
                  params.pUnresolvedIssues,
                  isInverse
                )}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default ProgressChartIssues
