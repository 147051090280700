import React from "react"
import { Flex, Spacer } from "@chakra-ui/react"
import { ButtonText } from "../../componentsWithChakra"
import {
  BiPlus,
  BiMinus,
  BiLeftArrowAlt,
  BiUpArrowAlt,
  BiRightArrowAlt,
  BiDownArrowAlt,
  BiCaretRight,
} from "react-icons/bi"
import { MdSubdirectoryArrowRight } from "react-icons/md"

import { MdClose } from "react-icons/md"
import {
  nodeNotActiveUpdate,
  rowCreate,
  rowCreateAsChild,
  rowDelete,
  rowMoveIn,
  rowMoveOut,
  rowMoveUpDown,
  rowToggleAgg,
} from "../../redux/actions/project"
import { ROOT } from "../../const/globals"

const SheetRowMove = ({
  node,
  canMoveUp = false,
  canMoveDown = false,
  isAggToggleable = false,
  dispatch,
}) => {
  //=================================================================

  const { nId, position, isAggregate } = node

  const isMovedIn1 = position.length > 1
  const isMovedIn2 = position.length > 2

  const isLocked = node.isLocked

  //-----------------------------------------------------------------

  // onBlur prop must be after {...register()}
  const onClickNotActive = () => {
    dispatch(nodeNotActiveUpdate())
  }

  const onClickAgg = (event) => {
    const payload = { nId }
    dispatch(rowToggleAgg(payload))
  }

  const onClickIn = (event) => {
    const payload = { nId }
    dispatch(rowMoveIn(payload))
  }

  const onClickOut = (event) => {
    const payload = { nId }
    dispatch(rowMoveOut(payload))
  }

  const onClickUp = (event) => {
    const payload = { nId, isMoveUp: true }
    dispatch(rowMoveUpDown(payload))
  }

  const onClickDown = (event) => {
    const payload = { nId, isMoveUp: false }
    dispatch(rowMoveUpDown(payload))
  }

  // ----------------------------------------------------------------
  const onClickAddAsChild = () => {
    const payload = { paId: nId }
    dispatch(rowCreateAsChild(payload))
  }

  const onClickPlus = () => {
    const payload = { nIdBefore: nId }
    dispatch(rowCreate(payload))
  }

  const onClickMinus = () => {
    const payload = { nId }
    dispatch(rowDelete(payload))
  }

  //=================================================================
  return (
    <Flex>
      <Flex
        h="24px"
        justify="flex-start"
        mb={2}
        bg="pink.50"
        borderRadius="5px 0px 0px 5px"
        border="1px solid #1565c0"
      >
        <Spacer minW={1} maxW={1} />
        <Flex justify="space-between" align="flex-start" mt="-2px">
          {!isLocked && isAggToggleable ? (
            <ButtonText
              onClick={onClickAgg}
              px={1}
              color={isAggregate ? "inherit" : "grey.500"}
            >
              <BiCaretRight />
            </ButtonText>
          ) : (
            <ButtonText visibility="hidden" px={1}>
              <BiCaretRight />
            </ButtonText>
          )}

          {!isLocked &&
          ((isAggregate && isMovedIn1) || (!isAggregate && isMovedIn2)) ? (
            <ButtonText onClick={onClickOut} px={1}>
              <BiLeftArrowAlt />
            </ButtonText>
          ) : (
            <ButtonText visibility="hidden" px={1}>
              <BiLeftArrowAlt />
            </ButtonText>
          )}

          {nId !== ROOT && !isLocked ? (
            <ButtonText onClick={onClickIn} px={1}>
              <BiRightArrowAlt />
            </ButtonText>
          ) : (
            <ButtonText visibility="hidden" px={1}>
              <BiRightArrowAlt />
            </ButtonText>
          )}

          {!isLocked && canMoveDown ? (
            <ButtonText onClick={onClickDown} px={1}>
              <BiDownArrowAlt />
            </ButtonText>
          ) : (
            <ButtonText visibility="hidden" px={1}>
              <BiDownArrowAlt />
            </ButtonText>
          )}
          {!isLocked && canMoveUp ? (
            <ButtonText onClick={onClickUp} px={1}>
              <BiUpArrowAlt />
            </ButtonText>
          ) : (
            <ButtonText visibility="hidden" px={1}>
              <BiUpArrowAlt />
            </ButtonText>
          )}
          {!isLocked && isAggregate ? (
            <ButtonText onClick={onClickAddAsChild} px={1}>
              <MdSubdirectoryArrowRight />
            </ButtonText>
          ) : (
            <ButtonText visibility="hidden" px={1}>
              <MdSubdirectoryArrowRight />
            </ButtonText>
          )}
          {!isLocked && (
            <ButtonText onClick={onClickPlus} px={1}>
              <BiPlus />
            </ButtonText>
          )}
          {nId !== ROOT && !isLocked ? (
            <ButtonText onClick={onClickMinus} px={1}>
              <BiMinus />
            </ButtonText>
          ) : (
            <ButtonText visibility="hidden" px={1}>
              <BiMinus />
            </ButtonText>
          )}
        </Flex>
        <Spacer minW={1} maxW={1} />
      </Flex>
      <Flex
        justify="space-between"
        align="flex-start"
        color="white"
        bg="blue.800"
        h="24px"
        borderRadius="0px 5px 5px 0px"
      >
        <ButtonText onClick={onClickNotActive} px={1} mt="-1px">
          <MdClose />
        </ButtonText>
      </Flex>
    </Flex>
  )
}

export default SheetRowMove
